import React, { useEffect, useState, useRef } from 'react'
//styles
import { StyledActividades } from '../themes/StyledActividades'
import { StyledBitacoraSoporte } from '../themes/StyledBitacoraSoporte';
//hooks
import { useAuth } from '../Context/Context'
import { useSeguimiento } from '../hooks/useSeguimiento'
import { useParams } from 'react-router-dom'
//img
import Buscarimg from '../assets/svg/buscar.svg'
import Add from '../assets/svg/add.svg'
import Editar from '../assets/svg/EditarNew.svg';
import Eliminar from '../assets/svg/eliminarNew.svg'
import Activar from '../assets/svg/activar.svg'
//componentes
import InputText from '../Componentes/InputText'
import InputPKT1 from '../Componentes/InputPKT1'
import InputPKT2 from '../Componentes/InputPKT2'
import BotonesAcciones from '../Componentes/BotonesAcciones'
import Agregar from '../Componentes/Agregar'
import ItemSubMenu from '../Componentes/ItemSubMenu';
import TablaPaginador from '../Componentes/TablaPaginador';
import Modal from '../Componentes/Modal'
import Loader from '../Componentes/Loader'
//libreria
import Swal from 'sweetalert2'

const Seguimiento = ({ props }) => {
    const Tablapaginador = useRef();
    const { user } = useAuth();
    const { id } = useParams();
    const [busquedaLocal, setBusquedaLocal] = useState('');
    const [username, setUserName] = useState(user.nombreusuario);
    const {
        ChangeRegs,
        handlePrimeraPg,
        handlePreviaPg,
        handleNexPg,
        handleUltimaPg,
        Pg, MaxPg, setRegsPg, RegsPg,
        openModal,
        ObtenerOrigenes222,
        ObtenerOrigenes33,
        closeModal, showModal, modoEdicion,
        handleDescripcionChange,
        descripcion,
        handlerGuardarSeguimiento,
        ObtenerSeguimientos,
        HabilitarOrigen,
        loading,
        ObtenerSeguimientos2,
        ClientesPaginador,
        fechaSeguimiento,
        setFechaSeguimiento,
        ObtenerSeguimientosBuscador,
        origenSeleccionado
    } = useSeguimiento();


    useEffect(() => {
        ObtenerSeguimientos()

    }, []);


    useEffect(() => {
        
        if (ClientesPaginador === undefined) {
            ObtenerSeguimientos2()
        }
    }, [ClientesPaginador, ObtenerSeguimientos2])



    const RenderID = ({ id }) => {
        return <span>{id}</span>;
    };

    const RenderNombre = ({ nombre }) => {
        return <span>{nombre}</span>;
    };


    const Buscador2 = (e) => {
        const busqueda = e.target.value;
        setBusquedaLocal(busqueda);
        clearTimeout(window.searchTimeout);
        window.searchTimeout = setTimeout(() => {
            if (busqueda.trim() === '') {
                ObtenerSeguimientos2();
            } else {
                ObtenerSeguimientosBuscador(busqueda)
            }
        }, 200);
    };


    const handleOptionClick = (label, detalle) => {
        if (label === 'Editar Seguimiento') {
            console.log("Seleccionaste Editar para:", detalle); 
            openModal(detalle); 
        } else if (label === 'Deshabilitar') {
            Swal.fire({
                title: '¿Estás seguro que deseas deshabilitar este Seguimiento?',
                text: `Seguimiento ID: ${detalle.id}`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3BAF2A',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Sí, deshabilitar',
                cancelButtonText: 'Cancelar',
            }).then((result) => {
                if (result.isConfirmed) {
                    HabilitarOrigen(detalle.id, user.idusuario, false);
                    ObtenerSeguimientos2()
                }
            });
        } else if (label === 'Habilitar') {
            Swal.fire({
                title: '¿Estás seguro que deseas habilitar este Seguimiento?',
                text: `Seguimiento ID: ${detalle.id}`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3BAF2A',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Sí, habilitar',
                cancelButtonText: 'Cancelar',
            }).then((result) => {
                if (result.isConfirmed) {
                    HabilitarOrigen(detalle.id, user.idusuario, true);
                    ObtenerSeguimientos2()
                }
            });
        }
    };

    const renderItemCabeceraTablaPendientes = () => (
        <>
            <div className='ItemHeader-TablaPaginador' style={{ flex: 1 }}>
                <p>ID</p>
            </div>
            <div className='ItemHeader-TablaPaginador' style={{ flex: 1 }}>
                <p>Fecha de registro</p>
            </div>
            <div className='ItemHeader-TablaPaginador' style={{ flex: 1 }}>
                <p>Fecha de seguimiento</p>
            </div>
            <div className='ItemHeader-TablaPaginador' style={{ flex: 5 }}>
                <p>Descripción</p>
            </div>
            <div className='ItemHeader-TablaPaginador' style={{ flex: 2 }}>
                <p>Estatus</p>
            </div>
        </>
    );


    const formatearFecha = (fecha) => {
        if (!fecha) return ''; // Si la fecha es nula o no está definida
        return fecha.split('T')[0]; // Toma solo la parte de la fecha antes de la 'T'
    };
    
    const renderItemCuerpoTablaPendientes = () => (
        <>
            {ClientesPaginador?.map((item) => {
    
                const submenuOptions = item.estatus === 'A'
                    ? [
                        { label: 'Editar Seguimiento', image: Editar },
                        { label: 'Deshabilitar', image: Eliminar }
                    ]
                    : [
                        { label: 'Habilitar', image: Activar }
                    ];
    
                return (
                    <div className="cuerpoPendientes" key={item.id}>
                        <div className="Cuerpo-TablaSinPaginador" style={{ height: '50px', position: 'relative' }}>
                            <div className='ItemCuerpo-Tablapaginador' style={{ flex: 1 }}>
                                <p>{item.id}</p>
                            </div>
                            <div className='ItemCuerpo-Tablapaginador' style={{ flex: 1 }}>
                                <p>{formatearFecha(item.fechaRegistro)}</p>
                            </div>
                            <div className='ItemCuerpo-Tablapaginador' style={{ flex: 1 }}>
                                <p>{formatearFecha(item.fechaSeguimiento)}</p>
                            </div>
                            <div className='ItemCuerpo-Tablapaginador' style={{ flex: 5 }}>
                                <p>{item.descripcion}</p>
                            </div>
                            <div className='ItemHeader-TablaPaginador' style={{ flex: 2, textAlign: 'center', position: 'relative' }}>
                                <p
                                    style={
                                        item.estatus === 'A'
                                            ? {
                                                color: "#ffff",
                                                backgroundColor: '#3baf2a',
                                                borderRadius: '20px',
                                                width: '45%',
                                                margin: 'auto',
                                                padding: '8px 0px'
                                            }
                                            : {
                                                color: "white",
                                                backgroundColor: '#ec1c1c',
                                                borderRadius: '20px',
                                                width: '45%',
                                                margin: 'auto',
                                                padding: '8px 0px'
                                            }
                                    }
                                >
                                    {item.estatus === 'A' ? 'Activo' : 'Inactivo'}
                                </p>
                                <div className='contentainer'>
                                    <ItemSubMenu
                                        options={submenuOptions}
                                        onOptionClick={(label) => handleOptionClick(label, item)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                );
            })}
        </>
    );
    

    return (
        <StyledBitacoraSoporte>
            <div className='contenedor-principal'>
                <div className='tabla-catalogo'>
                    {loading ? (
                        <div className='PositionLoader'>
                            <Loader />
                        </div>
                    ) : (
                        <TablaPaginador
                            Cabezera={renderItemCabeceraTablaPendientes}
                            Cuerpo={renderItemCuerpoTablaPendientes}
                            identificador={Tablapaginador}
                            ProPrevPg={handlePrimeraPg}
                            PrevPg={handlePreviaPg}
                            NextPg={handleNexPg}
                            ProNextPg={handleUltimaPg}
                            Pg={Pg}
                            MaxPg={MaxPg}
                            RegsPg={RegsPg}
                            setRegsPg={setRegsPg}
                            ChangeRegs={ChangeRegs}
                            InfoTab={ClientesPaginador}
                        />
                    )}
                </div>

                <StyledActividades>
                    <div className='acomodo'>
                        
                        <div className='ContenedorFiltros'>
                            <InputPKT1
                                label={"Buscar"}
                                placeholder={"Seguimiento"}
                                type={"search"}
                                mostrar={true}
                                background={"rgb(58, 147, 56)"}
                                BtnImg={Buscarimg}
                                Accion={Buscador2}
                                value={busquedaLocal}
                            />
                        </div>
                        <div className='ContenedorAgregar'>
                            <Agregar IconoAgregar={Add} Accion={() => openModal()} />
                        </div>
                    </div>
                </StyledActividades>
            </div>
            <Modal
                estado={showModal}
                titulo={modoEdicion ? "EDITAR SEGUIMIENTO" : "NUEVO SEGUIMIENTO"}
                cambiarEstado={closeModal}
            >
                <div className='inputOrigen'>

                <div className='identificador'>
                        {origenSeleccionado && (
                            <div style={{marginLeft:'-30px'}}>
                                <p>Editando el Seguimiento: <RenderID id={origenSeleccionado.id} /> <RenderNombre nombre={origenSeleccionado.nombre}/>   </p>
                            </div>
                        )}
                    </div>

                    <div className='BotonesFecha'>
                        <InputPKT2
                            label={"Fecha"}
                            type={"date"}
                            mostrar={"none"}
                            placeholder={"DD/MM/YYYY"}
                            OverrideStyles={{ flex: '2', padding: '0px', paddingLeft: '0px' }}
                            value={fechaSeguimiento} 
                            Accion={(e) => {
                                setFechaSeguimiento(e.target.value); 
                                console.log("Fecha seleccionada:", e.target.value); 
                            }}
                        />
                        <div style={{ display: 'flex', flexDirection: 'column', gap: '2px', marginLeft: '70px' }}>
                            <InputPKT1
                                label={"Usuario"}
                                type={"text"}
                                mostrar={"none"}
                                OverrideStyles={{ flex: '2', padding: '0px', paddingLeft: '0px' }}
                                value={username}
                                desactivar={true}
                            />
                        </div>
                    </div>
                    <InputText
                        label={"Descripción"}
                        type={"textarea"}
                        placeholder={"Descripción del seguimiento"}
                        size={100}
                        mostrar={"none"}
                        desactivar={false}
                        Accion={(e) => handleDescripcionChange(e.target.value)}
                        value={descripcion} // Carga la descripción existente
                    />
                    <div className='botonAccion' style={{marginLeft: '-35px'}}>
                        <BotonesAcciones
                            accion={modoEdicion ? "Guardar cambios" : "Guardar y enviar"}
                            colorFondo={"#2D8520"}
                            colorLetra={"#FFF"}
                            clickBoton={() => handlerGuardarSeguimiento(fechaSeguimiento, descripcion, id)}
                        />
                    </div>
                </div>
            </Modal>
        </StyledBitacoraSoporte>
    );
}
export default Seguimiento