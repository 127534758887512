import axios from 'axios'
import moment from 'moment/moment';
import React, { useState } from 'react'

export const useCalendario = () => {

  const [SemanaLaborable, setSemanaLaborable] = useState([]);
    

    const MostrarCalendario = async (url) =>{

      const startOfYear = moment().startOf('year');
      const endOfYear = moment().endOf('year');
      const weeks = [];
      let currentWeek = [];

      // Iterar sobre cada día del año
      for (let date = startOfYear; date.isBefore(endOfYear); date.add(1, 'days')) {
        if (date.day() !== 0) { // Excluir domingos (0 representa el domingo)
          currentWeek.push(date.clone().format('YYYY-MM-DD')); // Añadir la fecha al arreglo de la semana
    
          if (date.day() === 6) { // Sábado es el último día de la semana
            weeks.push(currentWeek); // Añadir la semana al arreglo de semanas
            currentWeek = []; // Reiniciar el arreglo de la semana
          }
        }
      }

      setSemanaLaborable(weeks);


        // const meses = moment.months();

        // const diasSemana = moment.weekdays();

        // let numeroDias;

        // const dias = [];


        // let contadorMeses = [];

        // const Year = moment().format("YYYY");

        // let FechasYear = [];

        // let SemanasYear = [];

        // let ContadorSemanas = 0;

        // const SemanasLaborables = [];

        
        
        

        // meses.forEach((mes, index) => {
        //      contadorMeses.push(index + 1);
        //     //  console.log(contadorMeses);
        //      const mesIndex = moment().month(mes).month();
        //      const diasMes = moment().month(mesIndex).daysInMonth();

        //     //  console.log(diasMes);


        //      for(let i = 1; i<= diasMes; i++){
        //       dias.push(i);
        //       // console.log(dias[i])
        //      }

        //     })

        //     for(let i = 0;i<contadorMeses.length;i++){
        //         for(let j = 0;j<dias.length;j++){
        //           if(dias[j + 1 ] == 1){
        //             // console.log(dias[j+1]);
        //             break;
        //           }else{
        //             console.log(dias[j], contadorMeses[i]);

        //           }
        //         // console.log(contadorMeses[i],dias[j],"cicloFechas");
        //       }
        //     }

        //     // console.log(contadorMeses[contadorMeses-1]);
             
        //     // for(let i = 0;i<2;i++){
        //      dias.forEach((dias, index) => {
        //             // console.log(dias);

        //       //     meses.forEach((mes, index) => {
        //       //         ObtenerDiasDelMes(index)
        //       //    })
        //         // console.log(moment(`${dias}/${NumeroMes}/${Year}`).format("MM/DD/YYYY"));
        //         // console.log(`${dias}/${contadorMeses}/${Year}`);

        //         // for(let i = 0;i<contadorMeses.length;i++){
        //         //   if(dias == 1){
        //         //     // console.log(dias[j+1]);
        //         //     break;
        //         //   }else{
        //         //   console.log(contadorMeses[i],dias,"cicloFechas");
        //         //   }
        //         // }
                
        //         let formatoFecha = moment(`${Year}-${contadorMeses}-${dias}`).format("YYYY-MM-DD")
        //         // console.log(contadorMeses)

        //         // FechasYear.push(formatoFecha)
      
        //         // if(NumeroMes  == NumeroMes){
        //         //   prueba.push(`${dias}/${NumeroMes}/${Year}`)
        //         // }
        //         //  setFechasYear([`${dias}/${NumeroMes}/${Year}`]);
                
        //       })
        //     // }


        //     FechasYear.forEach((fechas)=> {
        //       const diasYear = moment(fechas).format("dddd");
        //       // console.log(fechas);
        //       if(diasYear !== "Sunday"){
        //         // console.log(diasYear, fechas, "SemanaLaborable")
        //         if(diasYear == "Saturday"){
        //           ContadorSemanas++;
        //           // console.log(ContadorSemanas, fechas);
        //         }
        //         // console.log(diasYear, fechas);
        //         // SemanasYear.push(diasYear);
        //       }
        //       // console.log(numeroDias)
        //         // console.log(dias)

        //         // console.log(diasYear)
        //         // diasSemana.forEach((dia, index) =>{
        //         //   numeroDias = {
        //         //     id: index + 1,
        //         //     descripcion: dia
        //         //   }
        //           // console.log(numeroDias)
        //           // console.log(diasYear)
        //         // })
        //     })

            // console.log(SemanasYear)



        

        // console.log(mesIndex)

        // console.log(dias)

        

    }

  return {
    MostrarCalendario,
    SemanaLaborable
  }
}
