import axios from "axios"
import { useState } from "react"
import { usePaginadorNuevo } from './usePaginadorNuevo';
import Swal from 'sweetalert2';
import { useParams } from 'react-router-dom'
import { useAuth } from '../Context/Context';

export const useSeguimiento = () => {
    const { user } = useAuth();
    const [showModal, setShowModal] = useState(false);
    const [modoEdicion, setModoEdicion] = useState(false);
    const [origenSeleccionado, setOrigenSeleccionado] = useState(null);
    const [descripcion, setDescripcion] = useState('');
    const [fechaRegistro, setFechaRegistro] = useState('');
    const [loading, setLoading] = useState(true);
    const { Pg, MaxPg, RegsPg, InfoTab, GetPages, ProPrevPg, PrevPg, NextPg, ProNextPg, setRegsPg } = usePaginadorNuevo();
    const [Buscar, setBuscar] = useState();
    const { id } = useParams();
    const  [ClientesPaginador, setClientesPaginador]= useState()
    const [seguimientoPag, setseguimientoPag] = useState();

    const ChangeRegs = (regis) => {
        GetPages(`${process.env.REACT_APP_API_SOPORTE}Detalles/Paginador?buscar=${Buscar === undefined ? '' : Buscar}&pagina=${1}&registrosPorPagina=${regis} `);
    }
    const handlePrimeraPg = () => {
        ProPrevPg(`${process.env.REACT_APP_API_SOPORTE}Detalles/Paginador?buscar=${Buscar === undefined ? '' : Buscar}&pagina=${1}&registrosPorPagina=${RegsPg === 0 ? 10 : RegsPg} `);
    }

    const handlePreviaPg = () => {
        PrevPg(`${process.env.REACT_APP_API_SOPORTE}Detalles/Paginador?buscar=${Buscar === undefined ? '' : Buscar}&pagina=${Pg - 1}&registrosPorPagina=${RegsPg === 0 ? 10 : RegsPg} `)
    }
    const handleNexPg = () => {
        NextPg(`${process.env.REACT_APP_API_SOPORTE}Detalles/Paginador?buscar=${Buscar === undefined ? '' : Buscar}&pagina=${Pg + 1}&registrosPorPagina=${RegsPg === 0 ? 10 : RegsPg} `)
    }
    const handleUltimaPg = () => {
        ProNextPg(`${process.env.REACT_APP_API_SOPORTE}Detalles/Paginador?buscar=${Buscar === undefined ? '' : Buscar}&pagina=${MaxPg}&registrosPorPagina=${RegsPg === 0 ? 10 : RegsPg} `)
    }

    const ObtenerOrigenes222 = (busqueda) => {
        const busquedaParam = busqueda.trim() !== '' ? busqueda : '';
        GetPages(`${process.env.REACT_APP_API_SOPORTE}Detalles/Paginador?buscar=${busquedaParam}&pagina=${Pg}&registrosPorPagina=${RegsPg}`);
    alert("tengo hambreeee")
    };
    const ObtenerOrigenes33 = () => {
        GetPages(`${process.env.REACT_APP_API_SOPORTE}Detalles/Paginador?buscar=&pagina=${Pg}&registrosPorPagina=${RegsPg}`);
    };

    const handleDescripcionChange = (data) => {
        setDescripcion(data);
    };

    const handleFechaChange = (data) => {
        setFechaRegistro(data);
    };

    const [fechaSeguimiento, setFechaSeguimiento] = useState(() => {
        const hoy = new Date();
        return hoy.toISOString().split('T')[0]; // Formato "YYYY-MM-DD"
    });

    const openModal = (detalle) => {
        console.log("Abriendo modal con origen:", detalle); 
        setShowModal(true);
    
        if (detalle) {
            // Modo edición
            console.log("Fecha seguimiento recibida:", detalle.fechaSeguimiento);
            setModoEdicion(true);
            handleDescripcionChange(detalle.descripcion);
            const fechaFormateada = detalle.fechaSeguimiento
                ? new Date(detalle.fechaSeguimiento).toISOString().split('T')[0]
                : new Date().toISOString().split('T')[0];
            console.log("Fecha formateada para edición:", fechaFormateada);
            setFechaSeguimiento(fechaFormateada); 
            setOrigenSeleccionado(detalle);
        } else {
            // Modo agregar nuevo
            setModoEdicion(false);
            const fechaActual = new Date().toISOString().split('T')[0]; 
            setFechaSeguimiento(fechaActual);
        }
    };
    
    
    const closeModal = () => {
        setShowModal(false);
        setOrigenSeleccionado(null);
        setModoEdicion(false);
        setFechaSeguimiento(new Date().toISOString().split('T')[0]); 
        setDescripcion(""); 
    };
    
    //Detalles/Paginador/13?pagina=1&registrosPorPagina=10
    const ObtenerSeguimientos = () => {
        GetPages(`${process.env.REACT_APP_API_SOPORTE}Detalles/Paginador/${id}?pagina=${Pg === 0 ? 1 : Pg}&registrosPorPagina=${RegsPg === 0 ? 10 : RegsPg} `);
    }

    const ObtenerSeguimientos2 = async() => {
        let isMounted = true;
       const source = axios.CancelToken.source();
        const URL_CLIENTES = `${process.env.REACT_APP_API_SOPORTE}Detalles/Paginador/${id}?pagina=${Pg === 0 ? 1 : Pg}&registrosPorPagina=${RegsPg === 0 ? 10 : RegsPg} `
        try {
            const ResponseDetalles = await axios.get(URL_CLIENTES, {
                cancelToken: source.token
              });
              if (isMounted) {
                setClientesPaginador(ResponseDetalles.data.resultado)
              }
        } catch (error) {
            console.error(error)
        }
    }

//Detalles/Paginador/13?buscar=prueba&pagina=1&registrosPorPagina=10
    const ObtenerSeguimientosBuscador = async(busqueda) => {
        let isMounted = true;
       const source = axios.CancelToken.source();
        const URL_CLIENTES = `${process.env.REACT_APP_API_SOPORTE}Detalles/Paginador/${id}?buscar=${busqueda}&pagina=${Pg === 0 ? 1 : Pg}&registrosPorPagina=${RegsPg === 0 ? 10 : RegsPg} `
        try {
            const ResponseDetalles = await axios.get(URL_CLIENTES, {
                cancelToken: source.token
              });
              if (isMounted) {
                setClientesPaginador(ResponseDetalles.data.resultado)
              }
        } catch (error) {
            console.error(error)
        }
    }


    const CrearSeguimiento = async (request, usuario) => {
        setLoading(true);
        try {
            await axios.post(
                `${process.env.REACT_APP_API_SOPORTE}Detalles/Agregar/${usuario}`,
                request,
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );
            ObtenerSeguimientos2();
            closeModal();
        } catch (error) {
            console.log('Error al agregar nuevo seguimiento:', error);
            alert('Error al agregar seguimiento. Intenta de nuevo.');
        } finally {
            setLoading(false);
        }
    };

    const EliminarOrigen = async (idDetalle, idUsuario) => {
        setLoading(true);
        try {
            await axios.put(`${process.env.REACT_APP_API_SOPORTE}Detalles/Actualizar/${idUsuario}/${idDetalle}`, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            ObtenerSeguimientos();
        } catch (error) {
            console.error('Error al dar de baja el Seguimiento: ', error);
        } finally {
            setLoading(false);
        }
    };

    const HabilitarOrigen = async (idDetalle, idUsuario, habilitar = true) => {
        setLoading(true);
        try {

            const data = {
                estatus: habilitar ? "A" : "I"  // "A" para habilitar, "I" para inhabilitar
            };

            await axios.put(
                `${process.env.REACT_APP_API_SOPORTE}Detalles/Actualizar/${idUsuario}/${idDetalle}`,
                data,
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );
            await ObtenerSeguimientos2();
        } catch (error) {
            console.error('Error al modificar el estatus del Seguimiento: ', error);
            Swal.fire({
                title: 'Error',
                text: 'No se pudo modificar el estatus del Seguimiento',
                icon: 'error'
            });
        } finally {
            setLoading(false);
        }
    };


    const ModificarSeguimiento = async (idDetalle, idUsuario, datosModificados) => {
        setLoading(true);
        try {
            await axios.put(
                `${process.env.REACT_APP_API_SOPORTE}Detalles/Actualizar/${idUsuario}/${idDetalle}`,
                datosModificados,
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );
            ObtenerSeguimientos2();
            closeModal();
        } catch (error) {
            console.error("Error al modificar el seguimiento:", error);
            alert("Error al modificar el seguimiento. Intenta nuevamente.");
        } finally {
            setLoading(false);
        }
    };
    
    const handlerGuardarSeguimiento = (fechaSeguimiento, descripcion, idTicket) => {
        if (modoEdicion && origenSeleccionado) {
            const datosModificados = {};
    
            // Comparar descripción
            if (descripcion.trim() !== origenSeleccionado.descripcion) {
                datosModificados.descripcion = descripcion.trim();
            }
    
            // Comparar fecha
            const fechaOriginal = origenSeleccionado.fechaSeguimiento.split('T')[0]; // Tomar solo la parte de la fecha
            if (fechaSeguimiento !== fechaOriginal) {
                datosModificados.fechaSeguimiento = fechaSeguimiento;
            }
    
            // Si no hay cambios, no proceder
            if (Object.keys(datosModificados).length === 0) {
                Swal.fire('Atención', 'No se detectaron cambios en los campos.', 'info');
                return;
            }
    
            // Llamar al método para modificar el seguimiento
            ModificarSeguimiento(origenSeleccionado.id, user.idusuario, datosModificados)
                .then(() => {
                    Swal.fire('Éxito', 'El seguimiento ha sido modificado correctamente.', 'success');
                    console.log('Payload enviado:', datosModificados);
                })
                .catch(error => {
                    Swal.fire('Error', 'No se pudo modificar el seguimiento. Intenta de nuevo.', 'error');
                    console.error('Error al modificar seguimiento:', error);
                });
        } else {
            const nuevoSeguimiento = {
                idTicket: idTicket,
                descripcion: descripcion.trim(),
                fechaSeguimiento: fechaSeguimiento,
                idUsuarioRegistra: user.idusuario,
            };
            CrearSeguimiento(nuevoSeguimiento, user.idusuario)
                .then(() => {
                    Swal.fire('Éxito', 'El nuevo seguimiento ha sido creado correctamente.', 'success');
                    console.log('Creando nuevo seguimiento:', nuevoSeguimiento);
                })
                .catch(error => {
                    Swal.fire('Error', 'No se pudo crear el seguimiento. Intenta de nuevo.', 'error');
                    console.error('Error al crear seguimiento:', error);
                });
        }
    };
    
    
    
    

    return {
        ChangeRegs,
        handlePrimeraPg,
        handlePreviaPg,
        handleNexPg,
        handleUltimaPg,
        Buscar,
        Pg, MaxPg, setRegsPg, RegsPg, InfoTab,
        openModal,
        ObtenerOrigenes222,
        ObtenerOrigenes33, showModal, modoEdicion,
        closeModal,
        setFechaRegistro,
        fechaRegistro,
        setDescripcion,
        handleDescripcionChange,
        descripcion,
        handlerGuardarSeguimiento,
        EliminarOrigen,
        ObtenerSeguimientos,
        HabilitarOrigen,
        origenSeleccionado,
        handleFechaChange,
        ObtenerSeguimientos2,
        ClientesPaginador,
        fechaSeguimiento,
        setFechaSeguimiento,
        ObtenerSeguimientosBuscador
        
        
    }
}