import React, { useRef, useState, useEffect } from 'react';
import { StyledBitacoraSoporte } from '../themes/StyledBitacoraSoporte';
import { StyledActividades } from '../themes/StyledActividades';
import Modal from '../Componentes/Modal';
import InputPKT1 from '../Componentes/InputPKT1';
import BotonesAcciones from '../Componentes/BotonesAcciones';
import TablaPaginador from '../Componentes/TablaPaginador';
import useDepartamentos from '../hooks/useDepartamentos';
import useDetailsDepartamentos from '../hooks/useDetailsDepartamentos'
import { useGuardarDepartamentos } from '../hooks/useGuardarDepartamentos'
import { useAlert } from '../hooks/useAlert'
import { StyledAgregarAct } from '../themes/StyledAgregarAct'
import ItemSubMenu from '../Componentes/ItemSubMenu';
import Editar from '../assets/svg/EditarNew.svg';
import Eliminar from '../assets/svg/eliminarNew.svg'
import Loader from '../Componentes/Loader';
import TextAreaPKT1 from '../Componentes/TextAreaPKT1'
import Activar from '../assets/svg/activar.svg'
import Agregar from '../Componentes/Agregar'
import add from '../assets/svg/add.svg'
import Buscarimg from '../assets/svg/buscar.svg'
import axios from 'axios';
import useEliminarDepartamento from '../hooks/useEliminarDepartamento'
import Swal from 'sweetalert2'
import Opciones from '../assets/svg/opciones.svg'

const CatalagoDepartamentos = (props) => {

    const Tablapaginador = useRef();
    const {
        showDetailsProyect,
        DetallesDepartamento,
        setDetallesDepartamento,
        nombreDepartamento,
        setNombreDepartamento,
        observacionesDepartamento,
        setObservacionesDepartamento,
        IdUsuaruos,
        setIdUsuaruos
    } = useDetailsDepartamentos();

    const [buscar, setBuscar] = useState("");
    const [ids, setIds] = useState(0);
    const {
        EliminarDepartamento,
        DepartamentoEliminar,
        setDepartamentoEliminar
    } = useEliminarDepartamento();

    const { ChangeNombre, nuevoDepartamento, CrearDepartamento, CleanNombre, respPaginador, PaginadorDepartamento,
        ModificarDepartamento, handlerGuardarDepartamento, openModal, closeModal, showModal, modoEdicion, user, loading, page,
        totalPage,
        Buscar,
        ObtenerDepartamento,
        ObtenerDepartamentos,
        handlePrimeraPg,
        handlePreviaPg,
        handleNexPg,
        handleUltimaPg,
        InfoTab,
        RegsPg,
        ChangeRegs,
        Pg, MaxPg, setRegsPg,
        ObtenerOrigenes222,
        ObtenerOrigenes33
    } = useDepartamentos();

    useEffect(() => {
        ObtenerDepartamento()


    }, []);

    const handleOptionClick = (label, origen) => {
        if (label === 'Editar') {
            openModal(origen);
        } else if (label === 'Deshabilitar' || label === 'Habilitar') {
            EliminarDepartamento(origen.id, user.idusuario, label === 'Habilitar');
            ObtenerDepartamento();
        }
    };


    const [ValidarCampos, setValidarCampos] = useState({
        nombre: false,
        observaciones: false,
        IdUsuaruos: false
    });

    const { guardarDepartamento, guardarCambiosDepartamento, showModalAgregar, setShowModalAgregar } = useGuardarDepartamentos();
    const { alertError } = useAlert();

    const handelSubmit = async () => {

        let DatosDepartamento = {
            "nombre": nombreDepartamento,
            "observaciones": observacionesDepartamento,
            "idusuarios": IdUsuaruos
        }

        let ValidadorDatosDepartamento = {
            "nombre": nombreDepartamento,
            "observaciones": observacionesDepartamento,
            "idusuarios": IdUsuaruos
        }

        let ObjetoValidador = {}

        for (let propiedad in ValidadorDatosDepartamento) {
            if (ValidadorDatosDepartamento[propiedad] === "") {
                ObjetoValidador = {
                    [propiedad]: true
                }
                ValidarCampos[propiedad] = ObjetoValidador[propiedad]
                setValidarCampos({ ...ValidarCampos, [propiedad]: true })
            } else {
                ObjetoValidador = {
                    [propiedad]: false
                }
                ValidarCampos[propiedad] = ObjetoValidador[propiedad]
                setValidarCampos({ ...ValidarCampos, [propiedad]: false })
            }
        }


        if (DatosDepartamento.nombre && DatosDepartamento.observaciones) {
            await guardarDepartamento(`${process.env.REACT_APP_API_SOPORTE}Departamentos/Agregar/${user.idusuario}`, DatosDepartamento);
        } else {
            alertError("Favor de llenar todos los campos");
        }

    }
    const [mostrarBotones, setMostrarBotones] = useState("");


    const CancelProject = async (iddepartamento) => {

        mostrarOpciones(iddepartamento);

        let sendEliminarDepartamentos = { estatus: 3 };

        await EliminarDepartamento(`${process.env.REACT_APP_API_SOPORTE}Departamentos/Eliminar/${iddepartamento}/${user.idusuario}`, sendEliminarDepartamentos);

    }






    const mostrarModal = (e) => {
        limpiarModal();
        setMostrarBotones("Guardar");
        setShowModalAgregar(true);
    }

    const limpiarModal = async () => {
        setNombreDepartamento("");
        setObservacionesDepartamento("");

        for (let propiedad in ValidarCampos) {
            setValidarCampos({ [propiedad]: false });
        }
    }

    const cerrarModalAgregar = () => {
        setShowModalAgregar(false);
        limpiarModal();
    }
    const cerrarModal = () => {
        setShowModalAgregar(false);
    }

    //funcion para mostrar  y ocultar opciones con los 3 puntos de la tabla
    const mostrarOpciones = (id, estatusC) => {

        if (id === ids) {
            setIds("");

        } else {
            setIds(id);
        }

        if (estatusC === 3) {
            setDepartamentoEliminar(true);
        } else {
            setDepartamentoEliminar(false);
        }
    }


    const saveChanges = async () => {

        setShowModalAgregar(false);
        //Hacemos una copia del arreglo original
        let copiaEditData = {
            ...DetallesDepartamento,
            nombre: DetallesDepartamento?.nombre,
            observaciones: DetallesDepartamento?.observaciones,
            idusuarios: DetallesDepartamento?.idusuarios

        }

        //Creamos un arreglo nuevo para mandarlo al servicio
        let datosModificados = {};

        //Actualizamos los propiedades del objeto original
        DetallesDepartamento.nombre = nombreDepartamento;

        DetallesDepartamento.observaciones = observacionesDepartamento;
        DetallesDepartamento.idusuarios = IdUsuaruos;

        //startDate = formatFecha(cambiarFecha);

        for (let propiedad in DetallesDepartamento) {
            if (DetallesDepartamento[propiedad] !== copiaEditData[propiedad]) {
                DetallesDepartamento[propiedad] = DetallesDepartamento[propiedad];

            }
        }

        await guardarCambiosDepartamento(`${process.env.REACT_APP_API_SOPORTE}Departamentos/Actualizar/${DetallesDepartamento.id}/${user.idusuario}`, datosModificados);

    }



    const renderItemCabeceraTablaPendientes = () => (
        <>
            <div className='ItemHeader-TablaPaginador' style={{ flex: 1 }}>
                <p>ID</p>
            </div>
            <div className='ItemHeader-TablaPaginador' style={{ flex: 1 }}>
                <p>Nombre</p>
            </div>
            <div className='ItemHeader-TablaPaginador' style={{ flex: 1 }}>
                <p>Observaciones</p>
            </div>
            <div className='ItemHeader-TablaPaginador' style={{ flex: 1 }}>
                <p>Estatus</p>
            </div>
            <div className='ItemHeader-TablaPaginador' style={{ flex: 1 }}>
                <p>Id del Usuario</p>
            </div>
        </>
    );



    const OcultarOpciones = () => {

        setIds("");
    }

    let result = []

    if (!buscar) {
        result = ObtenerDepartamentos
    } else {
        result = ObtenerDepartamentos.filter((Departamento) =>
            Departamento.nombre.toLowerCase().includes(buscar.toLowerCase())
        );
    }

    const MostrarDatosProyecto = async (id, e) => {
        setShowModalAgregar(true);
        mostrarOpciones(id);
        await showDetailsProyect(`${process.env.REACT_APP_API_SOPORTE}Departamentos/${id}`);
        setMostrarBotones("Editar");
    }

    const [busquedaLocal, setBusquedaLocal] = useState('');
    const Buscador2 = (e) => {
        const valor = e.target.value;
        setBusquedaLocal(valor);
        clearTimeout(window.searchTimeout);
        window.searchTimeout = setTimeout(() => {
            if (valor.trim() === '') {
                ObtenerOrigenes33(); 
            } else {
                ObtenerOrigenes222(valor);
            }
        }, 200); 
    };





    const renderItemCuerpoTablaPendientes = () => (
        <>

            {InfoTab?.map(resp => {
                const submenuOptions = resp.estatus === 'A' ? [
                    { label: 'Editar', image: Editar },
                    { label: 'Deshabilitar', image: Eliminar }
                ] : [
                    { label: 'Habilitar', image: Activar }
                ];

                return (

                    <div className="cuerpoPendientes" key={resp.id}>

                        <div className="Cuerpo-TablaSinPaginador" style={{ height: '50px' }}>

                            <div className='ItemCuerpo-Tablapaginador' style={{ flex: 1, padding: '3px' }}>

                                <p style={{ margin: '0px' }}>{resp.id}</p>
                            </div>
                            <div className='ItemCuerpo-Tablapaginador' style={{ flex: 1 , padding: '3px'}}>
                                <p>{resp.nombre}</p>
                            </div>
                            <div className='ItemCuerpo-Tablapaginador' style={{ flex: 1, padding: '0px'}}>
                                <p>{resp.observaciones}</p>
                            </div>
                            <div className='ItemHeader-TablaPaginador' style={{ flex: 1, padding:'3px', textAlign: 'center'}}>

                                <p style={resp.estatus === 'A'
                                    ? {
                                        color: "#ffff",
                                        backgroundColor: '#3baf2a',
                                        borderRadius: '20px',
                                        width: '45%',
                                        margin: 'auto',
                                        padding: '10px 0px'

                                    }
                                    : {
                                        color: "white",
                                        backgroundColor: '#ec1c1c',
                                        borderRadius: '20px',
                                        width: '45%',
                                        margin: 'auto',
                                        padding: '10px 0px'
                                    }
                                }>
                                    {resp.estatus === "A" ? "Activo" : "Inactivo"}
                                </p>

                            </div>
                            <div className='ItemCuerpo-Tablapaginador' style={{ flex: 1, padding: '0px' }}>

                            <p>{resp.idusuarios}</p>
                            </div>

                            <div className='ItemCuerpo-Tablapaginador' style={{ flex: 0, padding: '0px'}}>
                                <div className='seleccionarOpciones' onClick={() => mostrarOpciones(resp.id)} >
                                    {/* {ids === item.id ? `seleccionarOpcionesRegistro seleccionarOpciones` : `seleccionarOpciones`} */}
                                    <img id={resp.id} className="seleccionarOpciones" src={Opciones} alt="Opciones" />
                                </div>
                                <div className='backOpciones' onMouseLeave={() => OcultarOpciones()}>
                                    <div className="ListadoOpciones opciones" id='ListadoSeguimiento' style={ids === resp.id ? { display: "block" } : { display: 'none' }}>

                                        <div className='opcionSeguimiento' onClick={() => MostrarDatosProyecto(resp.id)}>
                                            <div className='iconoEditar'>
                                                <img src={Editar} alt="IconoEditar" />
                                            </div>
                                            <p>Editar</p>
                                        </div>
                                        <div className={DepartamentoEliminar ? "actividadesCanceladas" : "opcionSeguimiento"} onClick={() =>
                                            Swal.fire({
                                                title: 'Estas seguro que deseas cancelar el proyecto?',
                                                text: `${resp.id}.- ${resp.nombre}`,
                                                icon: 'warning',
                                                showCancelButton: true,
                                                confirmButtonColor: '#3BAF2A',
                                                cancelButtonColor: '#d33',
                                                confirmButtonText: 'Si'
                                            }).then((result) => {
                                                if (result.isConfirmed) {
                                                    CancelProject(resp.id);
                                                }
                                            })} >
                                            <div className='iconoEliminar'>
                                                <img src={Eliminar} alt="IconoEliminar" />
                                            </div>
                                            <p disabled={DepartamentoEliminar ? true : false}>Cancelar</p>
                                        </div>
                                    </div>
                                </div>
                             
                            </div>
                        </div>
                    </div>
                );
            })}
        </>
    );
    if (loading) {
        return <Loader />
    }



    return (

        <>
            <StyledBitacoraSoporte>
                <div className='contenedor-principal'>
                    <div className='tabla-catalogo'>
                        <TablaPaginador
                            Cabezera={renderItemCabeceraTablaPendientes}
                            Cuerpo={renderItemCuerpoTablaPendientes}
                            identificador={Tablapaginador}
                            ProPrevPg={handlePrimeraPg}
                            PrevPg={handlePreviaPg}
                            NextPg={handleNexPg}
                            ProNextPg={handleUltimaPg}
                            Pg={Pg}
                            MaxPg={MaxPg}
                            RegsPg={RegsPg}
                            setRegsPg={setRegsPg}
                            ChangeRegs={ChangeRegs}
                            InfoTab={InfoTab}

                        />
                    </div>
                    { }
          


                    <StyledActividades>
                    <div className='acomodo'>
                        Buscar
                        <div className='ContenedorFiltros'>
                            <InputPKT1 
                            label={""} 
                            placeholder={"Departamentos"} 
                            type={"search"}
                            mostrar={true} 
                            background={"rgb(58, 147, 56)"} 
                            BtnImg={Buscarimg} 
                            Accion={Buscador2}
                            value={busquedaLocal}
                            />
                        </div>
                        <div className='ContenedorAgregar'>
                            <Agregar IconoAgregar={add} Accion={() => mostrarModal()} />
                        </div>
                    </div>
                </StyledActividades>



                </div>
            </StyledBitacoraSoporte>



            <Modal estado={showModalAgregar} cambiarEstado={() => cerrarModalAgregar()} titulo="Agregar Departamento">
                <StyledAgregarAct>
                    <div className='AgregarActividades'>
                        <div className='cardForm'>
                            <form className='BodyForm'>
                                <InputPKT1 label={"Titulo"} value={nombreDepartamento} type={"text"} mostrar={"none"} desactivar={false} CampoValidado={ValidarCampos.nombre} placeholder={"Nombre del departamento"} Accion={(e) => setNombreDepartamento(e.target.value)} size={50} />
                                <TextAreaPKT1 label={"Observaciones"} placeholder={"Observaciones"} size={55} value={observacionesDepartamento} onchange={(e) => setObservacionesDepartamento(e.target.value)} CampoValidado={ValidarCampos.observaciones} />
                                <InputPKT1 label={"Id del Usuario"} value={IdUsuaruos} type={"number"} mostrar={"none"} desactivar={false} CampoValidado={ValidarCampos.idusuarios} placeholder={"Id del usuario acargo del departamento"} Accion={(e) => setIdUsuaruos(e.target.value)} />

                                <div className='butons'>
                                    <BotonesAcciones accion={"Cerrar"} colorFondo={"#D60000"} colorLetra={"#FFF"} clickBoton={cerrarModal} />
                                </div>
                                <div className='butons'>
                                    <BotonesAcciones accion={"Guardar"} colorFondo={"#3BAF29"} colorLetra={"#FFF"} clickBoton={handelSubmit} />
                                </div>

                                <div className='butons'>
                                    <BotonesAcciones accion={"GuardarCambios"} colorFondo={"#3BAF29"} colorLetra={"#FFF"} clickBoton={saveChanges} />
                                </div>

                            </form>
                        </div>
                    </div>
                </StyledAgregarAct>
            </Modal>
        </>
    );
};
export default CatalagoDepartamentos;