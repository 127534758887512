import styled from 'styled-components';
const StyletablaPaginador = styled.div`
width: 100% !important;
margin:auto;
margin-top: 1px;
/* margin-right: 6px; */
 @media only screen and (min-width: 300px) {

.Paginacion{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
margin: auto;
    }
.paginador2{
    user-select: none;
    display: flex;
    flex-direction: row;
    /* / width: 30%; / */
    justify-content: flex-end;
    align-items: flex-start;
}
.pg{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0px 2px;
    padding: 3px 10px;
    min-height: 30px;
    /* / min-width: 100px; /
    / width: 25%; / */

    background: #ffffff;
    border: 2px solid gray;
    border-radius: 15px;
    color: #9597a7;
    font-size: 20px;
    font-weight: bold;
}
.prev{
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0px 2px;
    padding: 3px;
    min-height: 30px;
    min-width: 30px;
    width: 30px;
    background: #ffffff;
    border: 2px solid gray;
    border-radius: 15px;
    /* color: #9597a7;
    font-size: 20px;
    font-weight: bold; */
}
.prev-plus{
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0px 2px;
    padding: 3px;
    min-height: 30px;
    min-width: 30px;
    width: 30px;
    background: #ffffff;
    border: 2px solid gray;
    border-radius: 15px;
    /* color: #9597a7;
    font-size: 20px;
    font-weight: bold; */
}
.next-plus{
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0px 2px;
    padding: 3px;
    min-height: 30px;
    min-width: 30px;
    width: 30px;
    background: #ffffff;
    border: 2px solid gray;
    border-radius: 15px;
    /* color: #9597a7;
    font-size: 20px;
    font-weight: bold; */
}
.next{
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0px 2px;
    padding: 3px;
    min-height: 30px;
    min-width: 30px;
    width: 30px;
    background: #ffffff;
    border: 2px solid gray;
    border-radius: 15px;
    /* color: #9597a7;
    font-size: 20px;
    font-weight: bold; */
}


.contenedorPaginador{

    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    padding-bottom:10px; 
    padding: 30px 0px 0px 0px;
}
.paginador{
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-end;
    margin-top: 10px;
}
.num{
    background: #fff;
    height: 33px;
    border: solid #6E7687 1px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 8px;
    border-radius: 10px;
    padding-top: 2px;
    padding: 1px 10px;
}
.flecha{
    background: #fff;
    height: 35px;
    width: 35px;
    border: solid #6E7687 1px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
    border-radius: 10px;
}
.flecha img{
    height: 20px;
    width: 20px;
}

    .botonesFiltro{
    width: 100%;
    display: flex;
    justify-content: end;
  }
    .TablaSinpaginador{
        width: 100%;
        margin: auto;
    }
    .Cabezera-TablaSinPaginador{
        display: flex;
        flex-direction: row;
        width: 100%;
        margin-bottom: 7px;
    }
    .cuerpoTabla{
        max-height: 69vh;
        overflow-y: auto;
        /*Firefox*/
  scrollbar-color: #C5C5C9 transparent;
  scrollbar-width: thin;
  scrollbar-gutter: thin;
    }
    .Cuerpo-TablaSinPaginador{
        display: flex;
        flex-direction: row;
       // width: 300%;
        align-items: center;
        border-bottom: 7px solid #eff0f2;
        font-size: 12px;
        margin: auto;
        color: #333333;
        font-weight: 500;
        border-radius: 5px;
        background-color: #ffffff;
    }


    .ItemHeader-TablaSinPaginador{
        text-align:center;
        font-weight: bold;
        color: ${(props) => props.Azul};
        /* margin-right: 10px; */
        height: 5vh;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        min-width: 73px;

    }
    .ItemCuerpo-TablaSinpaginador{
        text-align: center;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        justify-content: center;
        min-width: 73px;
    }
    .campoFecha{
        display: none;
    }
    .Cuerpo-TablaSinPaginador:hover{
        background: #f8f8f8;
      
        cursor: pointer;
    }
   
    // TABLA PAGINADOR
    .ContenedorTablaFiltrosPaginador{
        display: flex; 
        flex-direction: column-reverse;
        width: 96%;
        align-items: center;
        justify-content: space-between;
        position: relative;
        margin: auto;
        margin-left: 19px;
    }
    .iconnuevo-peticion{
        position: absolute;
        top: 0px;
        right: 0px;
        cursor: pointer;
        margin-top: 20px;
        width: 42px;
    }
    .containerFiltrosPaginador{
       position: relative;
        width: 100%;
        margin: auto;
    }
    .CamposFiltrosPaginaror{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .CamposFiltrosPaginaror-Izquierdo{
        display: flex;
        flex-direction: column;
    }





 
    .Cabezera-TablaPaginador {
    background-color: #444971;
    color: #fff;
    font-weight: bold;
    font-size: 14px;
    border-radius: 5px;
    overflow: auto;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

}

.Cabezera-TablaPaginador table {
    width: 100%;
    border-collapse: collapse;
}

.Cabezera-TablaPaginador th {
    padding: 15px 10px;
    text-align: left;
}

/*
.Cuerpoprincipal {
    max-height: 45vh;
    overflow-y: auto;
    background: #fff;
    border-radius: 5px;
    padding: 0 10px;
    border-collapse: separate;
    border-spacing: 10px 50px;
    border-style: none;
}
*/

.Cuerpoprincipal {
    max-height: 60vh;
    overflow-y: auto;
    border-radius: 5px;
    
}

.Cuerpoprincipal table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 90px; /* Ajusta este valor según el espaciado que desees entre filas */
}




/* Estilo para el texto "Activo" */
.estado-activo {
    color: green;
    font-weight: bold;
}

/* Estilo para los tres puntos verticales */
.opciones {
    cursor: pointer;
    font-weight: bold;
    color: #444971;
}

/* Estilos para la barra de desplazamiento */
.Cuerpoprincipal::-webkit-scrollbar {
    width: 8px;
}

.Cuerpoprincipal::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 4px;
}

.Cuerpoprincipal::-webkit-scrollbar-thumb {
    background: #C5C5C9;
    border-radius: 4px;
}

.Cuerpoprincipal::-webkit-scrollbar-thumb:hover {
    background: #a8a8a8;
}

    .ItemCuerpo-Tablapaginador{
        text-align: center;
        
    }
    .contenedororigenPeticionesTabla{
        display: flex;
        flex-direction: row;
        width: 50px;
        height: 30px;
        align-items: center;
        
    }
    .ContainerActions{
    position: absolute;
    right: 3px;
    top: 20px;
    width: 35px !important;
    height: 35px;
    border-radius: 100%;
    margin-right: 4px;
}
.ContainerActions span{
    position: absolute;
    /* background: #9597A7; */
    border-radius: 50%;
    width: 5px;
    height: 5px;
    right: 15px;
}
.active{
position: absolute;
right: 3px;
top: 20px;
width: 35px !important;
height: 35px;
background-color: #D9D9D9;
border-radius: 100%;
/* opacity: 40%; */
cursor: pointer;
}
.ContainerActions span:nth-child(1){
    bottom: 26px;
}
.ContainerActions span:nth-child(2){
    bottom: 16px;
}
.ContainerActions span:nth-child(3){
    bottom: 6px;
}
.ContainerSubAction{
    display: flex;
    flex-direction: column !important;
    position: absolute !important;
    right: 25px;
    background: #DBDDED !important;
    border-radius: 8px;
    z-index: 2;
    box-shadow: -1px 7px 17px rgb(0 0 0 / 35%);    
    border: solid #D4D3D3 1px;
    color: #666770;
    font-weight: bold;
    cursor: pointer;
    width: 225px;
}
.ItemSubmenu{
    display: flex;
    justify-content: space-around;
    height: 49px;
}
.ItemSubmenu img{
    padding-right: 10px;
}
.ItemSubmenu p{
    text-align: left;
    max-width: 80%;
}
}
@media only screen and (max-width: 1366px) {

}


@media only screen and (min-width: 300px) {
    .contenedorPaginador{
        display: flex;
        flex-direction: column;
   justify-content: space-between;
    width: 100%;
   // margin-top: 4rem;
    align-items: center;
    }
    .PaginadorSelect{
        flex: 10;
    }

    .Paginacion{
        flex: 2;
    }
    .Ocultar{
        display: none;
    }
}
 /* Small devices (portrait tablets and large phones, 600px and up) */
 @media only screen and (min-width: 600px) {
    .Cuerpo-TablaSinPaginador{
        font-size: 14px;
        width: 100%;
        font-weight: 600;
       
    }
    .Cabezera-TablaPaginador{
       font-size: 14px;
        width: 100%;
    }
    .CamposFiltrosPaginaror-Izquierdo{
        flex-direction: row;
    }
    .contenedorPaginador{
        display: flex;
        flex-direction: row;
   justify-content: space-between;
    width: 100%;
   // margin-top: 4rem;
    align-items: center;
    }
    .PaginadorSelect{
        flex: 10;
    }

    .Paginacion{
        flex: 2;
    }
 }
// /* Medium devices (landscape tablets, 768px and up) */
 @media only screen and (min-width: 768px) {
 
 }

// /* Large devices (laptops/desktops, 992px and up) */
 @media only screen and (min-width: 992px) {
    .Ocultar{
        display: block;
    }
    .ContenedorTablaFiltrosPaginador{
      flex-direction: row;
      width: 96%;
      margin: auto;
      padding-bottom: 12px;
  }
  .botonesFiltro{
    width: 10%;
    display: flex;
    justify-content: end;
  }
  .CamposFiltrosPaginaror{
      width: 100%;
      flex-direction: row;
      
  }
   .iconnuevo-peticion{
        position: relative;
        cursor: pointer;
        margin-top: 0px;
        display: flex;
        justify-content: end;
        width: 42px;
        right: 9px;
        padding-top: 32px;
    }
    .campoFecha{
        display: block;
    }
    .containerFiltrosPaginador{
        width: 89%;
    }
 }

// /* Extra large devices (large laptops and desktops, 1366px and up) */
 @media only screen and (min-width: 1366px) {
  
 }
 @media only screen and (min-width: 1370px) {
    .Cuerpoprincipal{
                max-height: 60vh;
    }
}

`

export { StyletablaPaginador }