import React, { useEffect, useState } from 'react';
import { StyledHome } from "../themes/StyledHome";
import { useAuth } from '../Context/Context';
import { useLocalStorage } from '../hooks/useLocalStorage';
import { useGraficas } from '../hooks/useGraficas';
import ReactApexChart from 'react-apexcharts';
import { useCalendario } from '../hooks/useCalendario';
import Card from '../Componentes/Card';
import { useFecha } from '../hooks/useFecha';

const Home = () => {

  //Llamada al hook para mostrar el usurio en linea
  const { user } = useAuth();

  const { cambioSeccion } = useLocalStorage();

  const { ServicioGraficas, DatosGrafica } = useGraficas();

  const { MostrarCalendario, SemanaLaborable } = useCalendario();

  const { formatShowFecha } = useFecha();

  const [config, setConfig] = useState({
    series: [],
      options: {
        chart: {
          type: 'line',
        },
        xaxis: {
          categories: [],
        },
      },
  });

  const [categoriaData, setCategoriaData] = useState([
    {
      id:0,
      descripcion: "Lunes"
    },
    {
      id:1,
      descripcion: "Martes"
    },
    {
      id:2,
      descripcion: "Miercoles"
    },
    {
      id:3,
      descripcion: "Jueves"
    },
    {
      id:4,
      descripcion: "Viernes"
    },
    {
      id:5,
      descripcion: "Sabado"
    }
  ]);

  useEffect(() => {

    cambioSeccion("Inicio");

    ServicioGraficas("https://reportesdesarrollo.paq1.com.mx/Api/V1/Usuarios/HorasRegistradas?FechaInicio=2024-07-01&FechaFin=2024-07-06&IdUsuario=747");

    
  }, []);
  
  useEffect(() => {

    MostrarCalendario();
    
    setConfig({
      series: [
        {
          name: 'Horas trabajadas',
          data: DatosGrafica?.map(item => item?.acumuladoMinutosTrabajados / 60 )
        },
        {
          name: 'Horas estimadas',
          data: DatosGrafica?.map(item => item.acumuladoMinutosEstimados / 60)
        }
      ],
      options: {
        ...config?.options,
        xaxis: {
          categories: categoriaData?.map(item => item?.descripcion)
        },
      },
    });

    // console.log(FechasYear)
  
    
  }, [DatosGrafica, SemanaLaborable]);
  


  return (
    <StyledHome>
      <div className='ContenedorInicio'>
        {/* <div className='CardWelcome'>
          <h1>Bienvenido:</h1>
          <p>{user?.descripcion}</p>
        </div>

        <div className='ContenedorGraf'>
          <div className='CardGraf'>
            <ReactApexChart options={config?.options} series={config?.series} type={"line"} />
            <button onClick={() => MostrarCalendario()}>Fechas</button>
          </div>
        </div> */}

        <div>
          <Card titulo={"Semanas laborables"}>
            <div className='ContenedorFechas'>
              {SemanaLaborable?.map((semana,index) => (
                <p className='fechas' key={index}>{index + 1} {semana}</p>
              ))}
            </div>
          </Card>
        </div>
      </div>
    </StyledHome>
  )
}

export default Home