import styled from 'styled-components';


export const StyledDashboard = styled.div`

//SideBar

.DashboardTop{
    background: var(--AzulHeader);
  //  clip-path: polygon(0px 0px, 100% 0px, 100% 78%, 91% 76%, 85% 76%, 78% 77%, 60% 84%, 51% 84%, 40% 81%, 30% 80%, 16% 82%, 0px 88%);
}

.DashBoard{
    display: grid;
    grid-template-rows: 1fr 14fr;
}

.DashboardBody{
    background-color: var(--Blanco);
    position: relative;
    padding: 1rem 1rem 0 0;
    list-style: none;
    font-weight: bold;
    display: flex;
    flex-direction: column;
    height: 92vh;
    
}

.DashboardBody li img{
    width: 35px;
}

.DashboardBody div img{
    width: 35px;
}

.DashboardBody .Seleccion{
    display: flex;
    width: 100%;
    gap: 1rem;
    margin-left: 2rem;
    align-items: center;
    animation-name: DeplazamientoIconos;
    animation-duration: 1s;
}

.ContenedorOpcion{
    position: absolute;
    content: "";
    background: #2F3768;
    width: 5px;
    height: 2rem;
    border-radius: 1rem;
    left: 0;
    animation-name: SeleccionMenu;
    animation-duration: 1s;
}

.DashboardBody .Seleccion p{
    text-align: center;
}

.DashboardBody .Desmarcado{
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-radius: 1rem;
    gap: 1rem;
}

.DashboardBody .Alinear p{
    color:#666770;
    font-size: small;
}

.mouseSobreElemento{
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 1rem;
    
}

home:hover{
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 1rem;
    width: 7rem;
    margin-bottom: 2rem;
    margin-top: 2rem;

}

.backDiv{
    width: 11rem;
    padding-top: 10px;
}
    
.backDiv:hover{
    cursor: pointer;
}

.backDivCatalogo{
    /* background: aqua; */
}

.submenuDoc{
        position: absolute;
        margin-left: 8.5rem;
        margin-top: -5.4rem;
        z-index: 99;
    }

    .submenuFac{
        position: absolute;
        margin-top: -8rem;
        margin-left: 8.5rem;
        z-index: 99;
    }

    .submenuBit{
        //position: absolute;
        //margin-top: -5.7rem;
        //margin-left: 8.5rem;
        //z-index: 99;
    }

    .submenuCat{
        /* position: absolute;
        margin-top: -5.7rem;
        margin-left: 8.5rem;
        z-index: 99; */
    }



    .separar::-webkit-scrollbar{
        overflow-y: hidden;
    }

    .logo{
        display: flex;
        justify-content: center;
        padding: 1rem;

    }

    .logo img{
        width: 50%;
        z-index: 99;
    }

    .DashboardTop{
        position: relative;
        list-style: none;
        width: 100%;
    }

    
   .Wave{
        position: absolute;
        clip-path: polygon(0 0, 58% 0, 58% 100%, 0% 100%);
    }




    /*
.Selector{
    position: absolute;
    content: "";
    background: var(--Fondo);
    width: 25px;
    height: 25px;
    border-top-left-radius: 2rem;
    border-bottom-left-radius: 2rem;
    right: 0;
    animation-name: CambioMenu;
    animation-duration: 1s;
}

.Selector::before{
    position: absolute;
    content: "";
    background: var(--Fondo);
    width: 30px;
    height: 25px;
    right: 0;
    top: -7px;
    left: 10px;
    clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
    transform: rotate(150deg);
    animation-name: CambioMenu;
    animation-duration: 0.5s;
}


.Selector::after{
    position: absolute;
    content: "";
    background: var(--Fondo);
    width: 25px;
    height: 25px;
    right: 0;
    top: 15px;
    left: 9px;
    clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
    transform: rotate(150deg);
    animation-name: CambioMenu;
    animation-duration: 0.5s;
} 

*/


@keyframes CambioMenu {
  from {width: 0}
  to {width: 25px}
}

@keyframes SeleccionMenu {
    from {opacity: 0}
    to {opacity: 1}
}

@keyframes DeplazamientoIconos {
  from {margin-left: 1rem}
  to {margin-left: 2rem}
}

@keyframes desplazarSubmenu {
    0% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(10px);
    }
}

@media only screen and (min-width: 300px) {

    .DashboardBody .Seleccion p{
        font-size: 12px;
    }

    .cerrarMenu{
        position: absolute;
        right: 1rem;
        top: -3rem;
    }

    .Alinear img{
        width: 50%;
    }
    
    
}
/* / Small devices (portrait tablets and large phones, 600px and up) / */
@media only screen and (min-width: 600px) {

    .cerrarMenu{
        display: none;
    }

}
/* / Medium devices (landscape tablets, 768px and up) / */
@media only screen and (min-width: 768px) {
}
/* / Large devices (laptops/desktops, 992px and up) / */
@media only screen and (min-width: 992px) {
    
}
/* / Extra large devices (large laptops and desktops, 1200px and up) / */
@media only screen and (min-width: 1200px) {

    .Alinear img{
        width: 40%;
    }

    .submenuDoc{
        position: absolute;
        margin-left: 8.5rem;
        margin-top: -5.4rem;
        z-index: 99;
    }

    .submenuFac{
        position: absolute;
        margin-top: -8rem;
        margin-left: 8.5rem;
        z-index: 99;
    }

    .submenuBit{
        /* position: absolute;
        margin-top: -5.7rem;
        margin-left: 8.5rem;
        z-index: 99; */
    }

    .submenuCat{
        /* position: absolute;
        margin-top: -5.7rem;
        margin-left: 8.5rem;
        z-index: 99; */
    }

    .DashboardBody .Seleccion p{
        font-size: 12px;
    }
}

`