// usePrueba.js
import React, { useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import { useAuth } from '../Context/Context';
import { usePaginadorNuevo } from './usePaginadorNuevo';

const usePrueba = () => {
    const { user } = useAuth();
    const [nuevoDepartamento, setnuevoDepartamento] = useState();
    const [nombreDepartamento, setNombreDepartamento] = useState('');
const [observacionDepartamento, setObservacionDepartamento] = useState('');
const [idUsuarios, setIdUsuarios] = useState('');
    const [respPaginador, setrespPaginador] = useState();
    const [showModal, setShowModal] = useState(false);
    const [modoEdicion, setModoEdicion] = useState(false);
    const [departamentoSeleccionado, setdepartamentoSeleccionado] = useState(null);
    const [page, setPage] = useState(1);
    const [totalPage, setTotalPage] = useState(1);
    const [Buscar, setBuscar] = useState();
    const [RegistrosPorPagina, setRegistrosPorPagina] = useState(10)
    const [RespestaGetDepartamento, setRespestaGetDepartamento] = useState();
    const [RespuestaUsuarios, setRespuestaUsuarios] = useState();
    const { Pg, MaxPg, RegsPg, Loading, InfoTab, GetPages, ProPrevPg, PrevPg, NextPg, ProNextPg, setRegsPg } = usePaginadorNuevo();
    
    const ObtenerDepartamento222 = (busqueda) => {
        const busquedaParam = busqueda.trim() !== '' ? busqueda : '';
        GetPages(`${process.env.REACT_APP_API_SOPORTE}Departamentos/Paginador?buscar=${busquedaParam}&pagina=${Pg}&registrosPorPagina=${RegsPg}`);
    };
    const ObtenerDepartamentos33 = () => {
        GetPages(`${process.env.REACT_APP_API_SOPORTE}Departamentos/Paginador?buscar=&pagina=${Pg}&registrosPorPagina=${RegsPg}`);
    };

    
    const ObtenerDepartamentos2 = (busqueda) => {
        GetPages(`${process.env.REACT_APP_API_SOPORTE}Departamentos/Paginador?buscar=${busqueda  }&pagina=${Pg === 0 ? 1 : Pg}&registrosPorPagina=${RegsPg === 0 ? 10 : RegsPg}`);
      };



    const ObtenerDepartamentos = () => {
        GetPages(`${process.env.REACT_APP_API_SOPORTE}Departamentos/Paginador?buscar=${Buscar === undefined ? '' : Buscar}&pagina=${Pg === 0 ? 1 : Pg}&registrosPorPagina=${RegsPg === 0 ? 10 : RegsPg} `);
    }


    const ObtenerUser2 = async () => {
        setLoading(true);
    
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_SOPORTE}Usuarios/Usuarios?filtro=false`);
            const respuestaServicio2 = response.data;
    
            console.log('Respuesta completa:', respuestaServicio2);
    
            if (respuestaServicio2 && Array.isArray(respuestaServicio2.value)) {
                const nuevaRespuesta2 = respuestaServicio2.value.map(usuario => ({
                    idUsuario: usuario.idUsuario,
                    encargado: usuario.encargado
                }));
    
                setRespuestaUsuarios(nuevaRespuesta2);
                console.log("Datos procesados de usuarios:", nuevaRespuesta2);
            } else {
                console.error('No se encontró un array de usuarios en la respuesta:', respuestaServicio2);
                setRespuestaUsuarios([]);
            }
        } catch (error) {
            console.error('Error al obtener usuarios:', error);
            setRespuestaUsuarios([]);
        } finally {
            setLoading(false);
        }
    }
    
    const ObtenerDepartamento = async () => {
        setLoading(true);
    
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_SOPORTE}Departamentos/Obtener`);
    
            const respuestaServicio = response.data;
    
            const nuevaRespuesta = respuestaServicio.map(resp => ({
                id: resp.id,
                descripcion: resp.nombre
            }));
    
            setRespestaGetDepartamento(nuevaRespuesta);
    
            console.log("Datos obtenidos:", nuevaRespuesta);
    
        } catch (error) {
            console.error('Error al obtener departamento:', error);
            
        } finally {
            setLoading(false);
        }
    };


    const ChangeRegs = (regis) => {
        GetPages(`${process.env.REACT_APP_API_SOPORTE}Departamentos/Paginador?buscar=${Buscar === undefined ? '' : Buscar}&pagina=${1}&registrosPorPagina=${regis} `);
    }
    const handlePrimeraPg = () => {
        ProPrevPg(`${process.env.REACT_APP_API_SOPORTE}Departamentos/Paginador?buscar=${Buscar === undefined ? '' : Buscar}&pagina=${1}&registrosPorPagina=${RegsPg === 0 ? 10 : RegsPg} `);
    }

    const handlePreviaPg = () => {
        PrevPg(`${process.env.REACT_APP_API_SOPORTE}Departamentos/Paginador?buscar=${Buscar === undefined ? '' : Buscar}&pagina=${Pg - 1}&registrosPorPagina=${RegsPg === 0 ? 10 : RegsPg} `)
    }
    const handleNexPg = () => {
        NextPg(`${process.env.REACT_APP_API_SOPORTE}Departamentos/Paginador?buscar=${Buscar === undefined ? '' : Buscar}&pagina=${Pg + 1}&registrosPorPagina=${RegsPg === 0 ? 10 : RegsPg} `)
    }
    const handleUltimaPg = () => {
        ProNextPg(`${process.env.REACT_APP_API_SOPORTE}Departamentos/Paginador?buscar=${Buscar === undefined ? '' : Buscar}&pagina=${MaxPg}&registrosPorPagina=${RegsPg === 0 ? 10 : RegsPg} `)
    }
    const ChangeNombre = (data) => {
        setnuevoDepartamento(data);
        setObservacionDepartamento(data);
        setIdUsuarios(data);

    };
    const handleNombreChange = (data) => {
        setNombreDepartamento(data);
      };
      
      const handleObservacionesChange = (data) => {
        setObservacionDepartamento(data);
      };
      
      const handleIdUsuariosChange = (data) => {
        setIdUsuarios(data);
      };




    const handleInputChange = (fieldName, data) => {
        switch (fieldName) {
          case 'nombre':
            setNombreDepartamento(data);
            break;
          case 'observaciones':
            setObservacionDepartamento(data);
            break;
          case 'idUsuarios':
            setIdUsuarios(data);
            break;
          default:
            break;
        }
      };



    const CleanNombre = () => {
        setnuevoDepartamento();
    };

    
    const limpiarModal = async () => {
        setNombreDepartamento("");
        setObservacionDepartamento("");
        setIdUsuarios("");
    }


    const closeModal = () => {
        setShowModal(false);
        limpiarModal();
        setdepartamentoSeleccionado(null);
        setModoEdicion(false);

    };


const openModal = (departamento) => {
  setShowModal(true);
  if (departamento) {
    setModoEdicion(true);
    setdepartamentoSeleccionado(departamento);
    handleNombreChange(departamento.nombre);
    handleObservacionesChange(departamento.observaciones);
    handleIdUsuariosChange(departamento.idUsuarios);
  } else {
    setModoEdicion(false);
    handleNombreChange('');
    handleObservacionesChange('');
    handleIdUsuariosChange('');
  }
};





const handlerGuardarDepartamento = () => {
    if (!nombreDepartamento || nombreDepartamento.trim() === '') {
        Swal.fire({
            title: 'Campo vacío',
            text: 'El nombre del departamento no puede estar vacío.',
            icon: 'error',
            confirmButtonText: 'Entendido'
        });
    
        return;
    }

    
    if (!idUsuarios) {
        Swal.fire({
            title: 'Usuario no seleccionado, el espacio no puede quedar vacio',
            text: 'Por favor, seleccione un usuario para el departamento.',
            icon: 'error',
            confirmButtonText: 'Entendido'
        });
        return;
    }

    if (modoEdicion && departamentoSeleccionado) {
        const datosModificados = {
            nombre: nombreDepartamento.trim(),
            observaciones: observacionDepartamento.trim(),
            idUsuarios: idUsuarios
        };
        ModificarDepartamento(departamentoSeleccionado.id, user.idusuario, datosModificados)
            .then(() => {
                Swal.fire('Éxito', 'El departamento ha sido modificado correctamente.', 'success');
                closeModal();
                ObtenerDepartamentos();
            })
            .catch(error => {
                Swal.fire('Error', 'No se pudo modificar el departamento. Por favor, intenta de nuevo.', 'error');
                console.error('Error al modificar departamento:', error);
            });
    } else {
        const requestDepartamento = {
            nombre: nombreDepartamento.trim(),
            observaciones: observacionDepartamento.trim(),
            idUsuarios: idUsuarios
        };
        CrearDepartamento(requestDepartamento, user.idusuario)
            .then(() => {
                Swal.fire('Éxito', 'El nuevo departamento ha sido creado correctamente.', 'success');
                closeModal();
                ObtenerDepartamentos();
            })
            .catch(error => {
                Swal.fire('Error', 'No se pudo crear el nuevo departamento. Por favor, intenta de nuevo.', 'error');
                console.error('Error al crear departamento:', error);
            });
    }
};







      
    const EditarDepartamento = async () => {
        if (!nuevoDepartamento || nuevoDepartamento.trim() === '') {
            Swal.fire('Error', 'El nombre del departamento no puede estar vacío.', 'error');
            return;
        }

        setLoading(true);
        try {
            await axios.put(`${process.env.REACT_APP_API_SOPORTE}Departamentos/Actualizar/${departamentoSeleccionado.id}/${departamentoSeleccionado.idUsuario}`, {
                nombre: nuevoDepartamento.trim()
            });
            Swal.fire('Éxito', 'El departamento ha sido modificado correctamente.', 'success');
            setModoEdicion(false);
            setdepartamentoSeleccionado(null);
            await PaginadorDepartamentos();
        } catch (error) {
            console.error('Error al modificar el departamento: ', error);
            Swal.fire('Error', 'No se pudo modificar el departamento. Por favor, intenta de nuevo.', 'error');
        } finally {
            setLoading(false);
        }
    };




    const CrearDepartamento = async (request, usuario) => {
        setLoading(true);
        try {
            await axios.post(
                `${process.env.REACT_APP_API_SOPORTE}Departamentos/Agregar/${usuario}`,
                request,
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );
             ObtenerDepartamentos();
            closeModal();
        } catch (error) {
            console.log('Error al agregar nuevo departamento:', error);
        } finally {
            setLoading(false);
        }
    };

    const PaginadorDepartamentos = async () => {
        setLoading(true);
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API_SOPORTE}Departamentos/Paginador?buscar=${''}&pagina=${Pg}&registrosPorPagina=${RegsPg === undefined ? '10' : RegsPg} `);

            setrespPaginador(response.data.resultado);
            setTotalPage(response.data.totalPage);
            // HanlderChangueResultados(response.data.TotalRegistros);
            return response.data.resultado;

        }
        catch (error) {
            console.error('Error al obtener paginador de departamentoes:', error);
        } finally {
            setLoading(false);
        }
    };

    const EliminarDepartamento = async (idUsuario , iddepartamento) => {
        setLoading(true);
        try {
            await axios.delete(`${process.env.REACT_APP_API_SOPORTE}Departamentos/Eliminar/${idUsuario }/${iddepartamento}`, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            ObtenerDepartamentos();
        } catch (error) {
            console.error('Error al dar de baja el departamento: ', error);
        } finally {
            setLoading(false);
        }
    };

    

    const HabilitarDepartamentos = async (iddepartamento , idUsuario) => {
        setLoading(true);
        try {
            await axios.put(`${process.env.REACT_APP_API_SOPORTE}Departamentos/Habilitar/${iddepartamento }/${idUsuario}`, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            ObtenerDepartamentos();
        } catch (error) {
            console.error('Error al habiliatr el departamento: ', error);
        } finally {
            setLoading(false);
        }
    };

    

    const [mostrarProyectos, setMostrarProyectos] = useState();
    const [error, setError] = useState([]);
    
    const [showSelect, setShowSelect] = useState(false);
    const [Pagina, setPagina] = useState();
    const [TotalPaginas, setTotalPaginas] = useState();
    const [TotalRegistros, setTotalRegistros] = useState();
    const [FormularioProyectos, setFormularioProyectos] = useState();
    const [loading, setLoading] = useState(true);
    const [showTable, setShowTable] = useState(false);

  
    const showDatosPoryectos = async (url, filtros) => {
      // setLoading(true);
      let configpost = {
        method: 'get',
        url: url,
        headers: {
          'Content-Type': 'application/json'
        },
        data: filtros
      };
  
      await axios(configpost)
      .then(response => {
  
        setLoading(false);
        
        // console.log(response.data);
        let RespuestaServicio = response.data.resultado;
    
        const NuevaRespuesta = RespuestaServicio.map(resp => {
          const RehacerObjeto = { "id": resp.id, "descripcion":resp.nombre }
          return RehacerObjeto
        })
            setFormularioProyectos(NuevaRespuesta);
            setMostrarProyectos(response.data.resultado);
            setPagina(response.data.paginaActual);
            setTotalPaginas(response.data.totalPaginas);
            setTotalRegistros(response.data.registrosPorPagina);
      })
        .catch(error => {
          setError(error);
        });
    }
  




    const ModificarDepartamento = async (iddepartamento , idUsuario, datosModificados) => {
        setLoading(true);
        try {
            await axios.put(
                `${process.env.REACT_APP_API_SOPORTE}Departamentos/Actualizar/${iddepartamento }/${idUsuario}`,
                datosModificados,
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );
             ObtenerDepartamentos();
            closeModal();
            // alert(`departamento con ID ${iddepartamento } modificado.`);
        } catch (error) {
            alert('Error al modificar departamento:', error);
        } finally {
            setLoading(false);
        }
    };

    

    return {
        ChangeNombre,
        nuevoDepartamento,
        CrearDepartamento,
        CleanNombre,
        respPaginador,
        PaginadorDepartamentos,
        EliminarDepartamento,
        ModificarDepartamento,
        ObtenerDepartamento,
        handlerGuardarDepartamento,
        showModal,
        openModal,
        modoEdicion,
        closeModal,
        user,
        loading,
        page,
        totalPage,
        TotalRegistros,
        Buscar,
        ObtenerDepartamentos,
        handlePrimeraPg,
        handlePreviaPg,
        handleNexPg,
        handleUltimaPg,
        InfoTab,
        ChangeRegs,
        RegsPg,
        setRegsPg,
        Pg,
        MaxPg,
        RespestaGetDepartamento, 
        RespuestaUsuarios,
        HabilitarDepartamentos,
        ObtenerDepartamentos2,
        ObtenerDepartamento222,
        ObtenerDepartamentos33,
        mostrarProyectos,
        showDatosPoryectos,
        showSelect,
        setShowSelect,
        loading,
        TotalPaginas,
        TotalRegistros,
        Pagina,
        setPagina,
        FormularioProyectos,
        EditarDepartamento,
        handleInputChange,
        ObtenerUser2,

        nombreDepartamento,
        observacionDepartamento,
        idUsuarios,
        handleNombreChange,
        handleIdUsuariosChange,
        handleObservacionesChange,
        departamentoSeleccionado,
        showTable, setShowTable,
        setLoading
    };
};

export default usePrueba;