import axios from 'axios'
import React, { useState } from 'react'

export const useGraficas = () => {

    const [DatosGrafica, setDatosGrafica] = useState([]);

    const ServicioGraficas = async (url) =>{

        // let len;

        try{
            let RespuestaServicio = await axios.get(url);

            // len = RespuestaServicio.data.length;

            // for(let i = 0; i < len; i++){
            //     setDatosGrafica(RespuestaServicio.data[i]);
            // }

            setDatosGrafica(RespuestaServicio.data);

        }catch(error){
            console.log(error);
        }
    }


  return {
    ServicioGraficas,
    DatosGrafica
  }
}
