// useOrigenes.js
import React, { useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import { useAuth } from '../Context/Context';
import { usePaginadorNuevo } from './usePaginadorNuevo';

const useOrigenes = () => {
    const { user } = useAuth();
    const [nuevoOrigen, setnuevoOrigen] = useState();
    const [respPaginador, setrespPaginador] = useState();
    const [showModal, setShowModal] = useState(false);
    const [modoEdicion, setModoEdicion] = useState(false);
    const [origenSeleccionado, setOrigenSeleccionado] = useState(null);
   // const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [totalPage, setTotalPage] = useState(1);
    //const [TotalRegistros, setTotalRegistros] = useState();
    const [Buscar, setBuscar] = useState();
    const [RegistrosPorPagina, setRegistrosPorPagina] = useState(10)
    const [RespestaGetOrigen, setRespestaGetOrigen] = useState();
    const { Pg, MaxPg, RegsPg, Loading, InfoTab, GetPages, ProPrevPg, PrevPg, NextPg, ProNextPg, setRegsPg } = usePaginadorNuevo();
    
    const ObtenerOrigenes222 = (busqueda) => {
        const busquedaParam = busqueda.trim() !== '' ? busqueda : '';
        GetPages(`${process.env.REACT_APP_API_SOPORTE}Origenes/Paginador?buscar=${busquedaParam}&pagina=${Pg}&registrosPorPagina=${RegsPg}`);
    };
    const ObtenerOrigenes33 = () => {
        GetPages(`${process.env.REACT_APP_API_SOPORTE}Origenes/Paginador?buscar=&pagina=${Pg}&registrosPorPagina=${RegsPg}`);
    };
    const ObtenerOrigenes2 = (busqueda) => {
        GetPages(`${process.env.REACT_APP_API_SOPORTE}Origenes/Paginador?buscar=${busqueda  }&pagina=${Pg === 0 ? 1 : Pg}&registrosPorPagina=${RegsPg === 0 ? 10 : RegsPg}`);
      };

    const ObtenerOrigenes = () => {
        GetPages(`${process.env.REACT_APP_API_SOPORTE}Origenes/Paginador?buscar=${Buscar === undefined ? '' : Buscar}&pagina=${Pg === 0 ? 1 : Pg}&registrosPorPagina=${RegsPg === 0 ? 10 : RegsPg} `);
    }

    const ChangeRegs = (regis) => {
        GetPages(`${process.env.REACT_APP_API_SOPORTE}Origenes/Paginador?buscar=${Buscar === undefined ? '' : Buscar}&pagina=${1}&registrosPorPagina=${regis} `);
    }
    const handlePrimeraPg = () => {
        ProPrevPg(`${process.env.REACT_APP_API_SOPORTE}Origenes/Paginador?buscar=${Buscar === undefined ? '' : Buscar}&pagina=${1}&registrosPorPagina=${RegsPg === 0 ? 10 : RegsPg} `);
    }

    const handlePreviaPg = () => {
        PrevPg(`${process.env.REACT_APP_API_SOPORTE}Origenes/Paginador?buscar=${Buscar === undefined ? '' : Buscar}&pagina=${Pg - 1}&registrosPorPagina=${RegsPg === 0 ? 10 : RegsPg} `)
    }
    const handleNexPg = () => {
        NextPg(`${process.env.REACT_APP_API_SOPORTE}Origenes/Paginador?buscar=${Buscar === undefined ? '' : Buscar}&pagina=${Pg + 1}&registrosPorPagina=${RegsPg === 0 ? 10 : RegsPg} `)
    }
    const handleUltimaPg = () => {
        ProNextPg(`${process.env.REACT_APP_API_SOPORTE}Origenes/Paginador?buscar=${Buscar === undefined ? '' : Buscar}&pagina=${MaxPg}&registrosPorPagina=${RegsPg === 0 ? 10 : RegsPg} `)
    }


    const RenderID = ({ id }) => {
        return <span>{id}</span>;
    };

    const RenderNombre = ({ nombre }) => {
        return <span>{nombre}</span>;
    };



    const handleSeleccionarOrigen = (origen) => {
        setOrigenSeleccionado(origen);
      };


    const ChangeNombre = (data) => {
        setnuevoOrigen(data);
    };

    const CleanNombre = () => {
        setnuevoOrigen();
    };

    const closeModal = () => {
        setShowModal(false);
        CleanNombre();
        setOrigenSeleccionado(null);
        setModoEdicion(false);
    };

    const openModal = (origen) => {
        setShowModal(true);
        if (origen) {
            setModoEdicion(true);
            setOrigenSeleccionado(origen);
            ChangeNombre(origen.nombre);
            // alert("editar origen")
        } else {
            setModoEdicion(false);
            CleanNombre();
        }
    };

    const handlerGuardarOrigen = () => {
        if (!nuevoOrigen || nuevoOrigen.trim() === '') {
            Swal.fire({
                title: 'Campo vacío',
                text: 'El nombre del origen no puede estar vacío.',
                icon: 'error',
                confirmButtonText: 'Entendido'
            });
            return;
        }
    
        if (modoEdicion && origenSeleccionado) {
            const datosModificados = { nombre: nuevoOrigen.trim() };
            ModificarOrigen(origenSeleccionado.id, user.idusuario, datosModificados)
                .then(() => {
                    Swal.fire('Éxito', 'El origen ha sido modificado correctamente.', 'success');
                    // Aquí podrías añadir lógica adicional, como cerrar el modal o actualizar la lista de orígenes
                    console.log('Guardando cambios:', { id: origenSeleccionado.id, nuevoOrigen });

                })
                .catch(error => {
                    Swal.fire('Error', 'No se pudo modificar el origen. Por favor, intenta de nuevo.', 'error');
                    console.error('Error al modificar origen:', error);
                });
        } else {
            const requestOrigen = { nombre: nuevoOrigen.trim() };
            CrearOrigen(requestOrigen, user.idusuario)
                .then(() => {
                    Swal.fire('Éxito', 'El nuevo origen ha sido creado correctamente.', 'success');
                    // Aquí podrías añadir lógica adicional, como limpiar el campo de entrada o actualizar la lista de orígenes
                })
                .catch(error => {
                    Swal.fire('Error', 'No se pudo crear el nuevo origen. Por favor, intenta de nuevo.', 'error');
                    console.error('Error al crear origen:', error);
                });
        }
    };
    

    const EditarOrigen = async () => {
        if (!nuevoOrigen || nuevoOrigen.trim() === '') {
            Swal.fire('Error', 'El nombre del origen no puede estar vacío.', 'error');
            return;
        }

        setLoading(true);
        try {
            await axios.put(`${process.env.REACT_APP_API_SOPORTE}Origenes/Actualizar/${origenSeleccionado.id}/${origenSeleccionado.idUsuario}`, {
                nombre: nuevoOrigen.trim()
            });
            Swal.fire('Éxito', 'El origen ha sido modificado correctamente.', 'success');
            setModoEdicion(false);
            setOrigenSeleccionado(null);
            await PaginadorOrigenes();
        } catch (error) {
            console.error('Error al modificar el origen: ', error);
            Swal.fire('Error', 'No se pudo modificar el origen. Por favor, intenta de nuevo.', 'error');
        } finally {
            setLoading(false);
        }
    };


    const abrirModalEdicion = (origen) => {
        setOrigenSeleccionado(origen);
        setnuevoOrigen(origen.nombre);
        setModoEdicion(true);
    };


    const CrearOrigen = async (request, usuario) => {
        setLoading(true);
        try {
            await axios.post(
                `${process.env.REACT_APP_API_SOPORTE}Origenes/Agregar/${usuario}`,
                request,
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );
             ObtenerOrigenes();
            closeModal();
        } catch (error) {
            console.log('Error al agregar nuevo origen:', error);
        } finally {
            setLoading(false);
        }
    };

    const PaginadorOrigenes = async () => {
        setLoading(true);
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API_SOPORTE}Origenes/Paginador?buscar=${''}&pagina=${Pg}&registrosPorPagina=${RegsPg === undefined ? '10' : RegsPg} `);

            setrespPaginador(response.data.resultado);
            setTotalPage(response.data.totalPage);
            // HanlderChangueResultados(response.data.TotalRegistros);
            return response.data.resultado;

        }
        catch (error) {
            console.error('Error al obtener paginador de origenes:', error);
        } finally {
            setLoading(false);
        }
    };

    const EliminarOrigen = async (idOrigen, idUsuario) => {
        setLoading(true);
        try {
            await axios.delete(`${process.env.REACT_APP_API_SOPORTE}Origenes/Eliminar/${idOrigen}/${idUsuario}`, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            ObtenerOrigenes();
        } catch (error) {
            console.error('Error al dar de baja el origen: ', error);
        } finally {
            setLoading(false);
        }
    };

    const HabilitarOrigen = async (idOrigen, idUsuario) => {
        setLoading(true);
        try {
            await axios.put(`${process.env.REACT_APP_API_SOPORTE}Origenes/Habilitar/${idOrigen}/${idUsuario}`, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            ObtenerOrigenes();
        } catch (error) {
            console.error('Error al habiliatr el origen: ', error);
        } finally {
            setLoading(false);
        }
    };

    

    const [mostrarProyectos, setMostrarProyectos] = useState();
    const [error, setError] = useState([]);
    
    const [showSelect, setShowSelect] = useState(false);
    const [Pagina, setPagina] = useState();
    const [TotalPaginas, setTotalPaginas] = useState();
    const [TotalRegistros, setTotalRegistros] = useState();
    const [FormularioProyectos, setFormularioProyectos] = useState();
    const [loading, setLoading] = useState(true);
    const [showTable, setShowTable] = useState(false);
  
     //formulario de registro de proyectos
     
  
    const showDatosPoryectos = async (url, filtros) => {
      // setLoading(true);
      let configpost = {
        method: 'get',
        url: url,
        headers: {
          'Content-Type': 'application/json'
        },
        data: filtros
      };
  
      await axios(configpost)
      .then(response => {
  
        setLoading(false);
        
        // console.log(response.data);
        let RespuestaServicio = response.data.resultado;
    
        const NuevaRespuesta = RespuestaServicio.map(resp => {
          const RehacerObjeto = { "id": resp.id, "descripcion":resp.nombre }
          return RehacerObjeto
        })
            setFormularioProyectos(NuevaRespuesta);
            setMostrarProyectos(response.data.resultado);
            setPagina(response.data.paginaActual);
            setTotalPaginas(response.data.totalPaginas);
            setTotalRegistros(response.data.registrosPorPagina);
      })
        .catch(error => {
          setError(error);
        });
    }
  




    const ModificarOrigen = async (idOrigen, idUsuario, datosModificados) => {
        setLoading(true);
        try {
            await axios.put(
                `${process.env.REACT_APP_API_SOPORTE}Origenes/Actualizar/${idOrigen}/${idUsuario}`,
                datosModificados,
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );
             ObtenerOrigenes();
            closeModal();
            // alert(`Origen con ID ${idOrigen} modificado.`);
        } catch (error) {
            alert('Error al modificar origen:', error);
        } finally {
            setLoading(false);
        }
    };

    const ObtenerOrigen = async () => {
        setLoading(true);
    
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_SOPORTE}Origenes/Obtener`);
    
            const respuestaServicio = response.data;
    
            const nuevaRespuesta = respuestaServicio.map(resp => ({
                id: resp.id,
                descripcion: resp.nombre
            }));
    
            setRespestaGetOrigen(nuevaRespuesta);
    
            console.log("Datos obtenidos:", nuevaRespuesta);
    
        } catch (error) {
            console.error('Error al obtener origen:', error);
            
        } finally {
            setLoading(false);
        }
    };
    

    return {
        ChangeNombre,
        nuevoOrigen,
        CrearOrigen,
        CleanNombre,
        respPaginador,
        PaginadorOrigenes,
        EliminarOrigen,
        ModificarOrigen,
        ObtenerOrigen,
        handlerGuardarOrigen,
        showModal,
        openModal,
        modoEdicion,
        closeModal,
        user,
        loading,
        page,
        totalPage,
        TotalRegistros,
        Buscar,
        ObtenerOrigenes,
        handlePrimeraPg,
        handlePreviaPg,
        handleNexPg,
        handleUltimaPg,
        InfoTab,
        ChangeRegs,
        RegsPg,
        setRegsPg,
        Pg,
        MaxPg,
        RespestaGetOrigen, 
        HabilitarOrigen,
        ObtenerOrigenes2,
        ObtenerOrigenes222,
        ObtenerOrigenes33,
        mostrarProyectos,
        showDatosPoryectos,
        showSelect,
        setShowSelect,
        loading,
        TotalPaginas,
        TotalRegistros,
        Pagina,
        setPagina,
        FormularioProyectos,
        EditarOrigen,
abrirModalEdicion,

showTable, setShowTable,
setLoading,
handleSeleccionarOrigen,
origenSeleccionado,
nuevoOrigen



    };
};

export default useOrigenes;