import styled from 'styled-components';
const StyledTextAreaPKT1 = styled.div`

    .ContenedorTextArea{
        display: flex;
        flex-direction: column;
        gap: 5px;
        
    }

    textarea {
        resize: none;
        outline: none;
        min-height: 8rem;
        max-height: 12rem;
        border: 2px solid rgb(102, 103, 112);
        border-radius: 7px;
    }

    .TextareaVacio{
        border: 2px solid var(--Rojo);
    }

    .FaltaCampo{
        color: var(--Rojo);
        font-weight: var(--Negritas);
    }

    .LabelTextAreaVacio{
        color: var(--Rojo);
    }

    .ContenedorCaracteres{
        margin: 0;
    }

`

export { StyledTextAreaPKT1 }