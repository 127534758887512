import React from 'react';
import { StyledInputPKT1 } from '../themes/StyledInputPKT1';

const InputPKT2 = (props) => {
    const {
        type = "text",
        label,
        placeholder = "",
        value,
        Accion,
        desactivar = false,
        size,
        limite,
        minimo,
        CampoValidado,
        OverrideStyles,
        mostrar = "none",
        BtnImg,
        background,
    } = props;

    // Determinar clases dinámicas de manera clara
    const inputClass = 
        type === "search" 
            ? "inputSearch" 
            : CampoValidado && value === "" 
            ? "inputCamposVacios" 
            : "input";

    return (
        <StyledInputPKT1 style={OverrideStyles}>
            <div className="atras">
                <div className="ContenedorInput">
                    <div className={mostrar === "none" ? "Input" : "InputBtn"}>
                        <label 
                            className={CampoValidado && value === "" 
                                ? "InputLabelCamposVacios" 
                                : "InputLabel"}
                        >
                            {label}
                        </label>
                        <input
                            className={inputClass}
                            type={type}
                            placeholder={placeholder}
                            value={value}
                            onChange={Accion} // Usar directamente la prop Accion para manejar cambios
                            disabled={desactivar}
                            maxLength={size}
                            max={limite}
                            min={minimo}
                        />
                        {/* Botón opcional */}
                        {mostrar !== "none" && (
                            <button
                                className="btn"
                                style={{ background }}
                            >
                                <img src={BtnImg} alt="Buscar" />
                            </button>
                        )}
                    </div>
                    {/* Mostrar contador de caracteres si el tamaño máximo está definido */}
                    {size !== undefined && (
                        <p className="ContenedorCaracteres">
                            {value.length} / {size}
                        </p>
                    )}
                </div>
            </div>
        </StyledInputPKT1>
    );
};

export default InputPKT2;
