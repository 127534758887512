const URL = process.env.REACT_APP_API_URL
const API_URL = `${URL}/api/v2/`
export default {
    API_URL
}

// const URL = process.env.REACT_APP_API_SOPORTE
// const API_URL = `${URL}/api/v2/`
// export default {
//     API_URL
// }