import React, { useRef, useState, useEffect } from 'react';
import { StyledBitacoraSoporte } from '../themes/StyledBitacoraSoporte';
import { StyledActividades } from '../themes/StyledActividades';
import Modal from '../Componentes/Modal';
import InputPKT1 from '../Componentes/InputPKT1';
import BotonesAcciones from '../Componentes/BotonesAcciones';
import TablaPaginador from '../Componentes/TablaPaginador';
import useOrigenes from '../hooks/useOrigenes';
import ItemSubMenu from '../Componentes/ItemSubMenu';
import Editar from '../assets/svg/EditarNew.svg';
import Eliminar from '../assets/svg/eliminarNew.svg'
import Loader from '../Componentes/Loader';
import TablaPkt1 from '../Componentes/TablaPKT1'
import Activar from '../assets/svg/activar.svg'
import Agregar from '../Componentes/Agregar'
import Add from '../assets/svg/add.svg'
import Buscarimg from '../assets/svg/buscar.svg'
import Swal from 'sweetalert2';


const CatalagoSoporte = (props) => {
    const Tablapaginador = useRef();
    const { ChangeNombre, CrearOrigen, CleanNombre, respPaginador, PaginadorOrigenes,
        EliminarOrigen, HabilitarOrigen, ModificarOrigen, handlerGuardarOrigen, openModal, closeModal, showModal, modoEdicion, user, loading, page,
        totalPage,
        Buscar,
        ObtenerOrigenes,
        handlePrimeraPg,
        handlePreviaPg,
        handleNexPg,
        handleUltimaPg,
        InfoTab,
        RegsPg,
        ChangeRegs,
        Pg, MaxPg, setRegsPg,
        ObtenerOrigenes2,
        ObtenerOrigenes222,
        ObtenerOrigenes33,
        EditarOrigen,
        showTable, setShowTable,
        setLoading,
        handleSeleccionarOrigen,
        origenSeleccionado,
nuevoOrigen

    } = useOrigenes();



    const RenderID = ({ id }) => {
        return <span>{id}</span>;
    };

    const RenderNombre = ({ nombre }) => {
        return <span>{nombre}</span>;
    };



    useEffect(() => {
        const timer = setTimeout(() => {
            setLoading(false);
            setShowTable(true);
        }, 1000);
        return () => clearTimeout(timer);
    }, []);



    useEffect(() => {
        ObtenerOrigenes()
    }, []);

    const [buscar, setBuscar] = useState();
    const [busquedaLocal, setBusquedaLocal] = useState('');
    const handleOptionClick = (label, origen) => {
        if (label === 'Editar') {
            openModal(origen);
        } else if (label === 'Deshabilitar') {
            Swal.fire({
                title: '¿Estás seguro que deseas deshabilitar este origen?',
                text: `Origen: ${origen.id} - ${origen.nombre}`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3BAF2A',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Sí, deshabilitar',
                cancelButtonText: 'Cancelar'
            }).then((result) => {
                if (result.isConfirmed) {
                    EliminarOrigen(origen.id, user.idusuario, false);
                    ObtenerOrigenes();
                }
            });
        } else if (label === 'Habilitar') {
            HabilitarOrigen(origen.id, user.idusuario, true);
            ObtenerOrigenes();
        }
    };


    const Buscador2 = (e) => {
        const valor = e.target.value;
        setBusquedaLocal(valor);
        clearTimeout(window.searchTimeout);
        window.searchTimeout = setTimeout(() => {
            if (valor.trim() === '') {
                ObtenerOrigenes33();
            } else {
                ObtenerOrigenes222(valor);
            }
        }, 200);
    };

    const renderItemCabeceraTablaPendientes = () => (
        <>
            <div className='ItemHeader-TablaPaginador' style={{ flex: 1 }}>
                <p>ID</p>
            </div>
            <div className='ItemHeader-TablaPaginador' style={{ flex: 3 }}>
                <p>Nombre</p>
            </div>
            <div className='ItemHeader-TablaPaginador' style={{ flex: 1 }}>
                <p>Estatus</p>
            </div>
        </>
    );

    const renderItemCuerpoTablaPendientes = () => (
        <>

            {InfoTab?.map(resp => {
                const submenuOptions = resp.estatus === 'A' ? [
                    { label: 'Editar', image: Editar },
                    { label: 'Deshabilitar', image: Eliminar }
                ] : [
                    { label: 'Habilitar', image: Activar }
                ];

                return (

                    <div className="cuerpoPendientes" key={resp.id}>

                        <div className="Cuerpo-TablaSinPaginador" style={{ height: '50px' }}>

                            <div className='ItemCuerpo-Tablapaginador' style={{ flex: 1, paddingTop: '0px' }}>

                                <p style={{ margin: '0px' }}>{resp.id}</p>
                            </div>
                            <div className='ItemCuerpo-Tablapaginador' style={{ flex: 3, padding: '0px' }}>
                                <p>{resp.nombre}</p>
                            </div>
                            <div className='ItemHeader-TablaPaginador' style={{ flex: 1, padding: '0px', textAlign: 'center' }}>
                                <p style={resp.estatus === 'A'
                                    ? {
                                        color: "#ffff",
                                        backgroundColor: '#3baf2a',
                                        borderRadius: '20px',
                                        width: '45%',
                                        margin: 'auto',
                                        padding: '8px 0px'

                                    }
                                    : {
                                        color: "white",
                                        backgroundColor: '#ec1c1c',
                                        borderRadius: '20px',
                                        width: '45%',
                                        margin: 'auto',
                                        padding: '8px 0px'
                                    }
                                }>     {resp.estatus === "A" ? "Activo" : "Inactivo"}
                                </p>



                                <div className='contentainer'>
                                    <ItemSubMenu
                                        options={submenuOptions}
                                        onOptionClick={(label) => handleOptionClick(label, resp)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                );
            })}
        </>
    );




    return (
        <StyledBitacoraSoporte>

            <div className='contenedor-principal'>
                <div className='tabla-catalogo'>
                    {loading ? (
                        <div className='PositionLoader'>
                            <Loader />
                        </div>
                    ) : 
                        <TablaPaginador
                            Cabezera={renderItemCabeceraTablaPendientes}
                            Cuerpo={renderItemCuerpoTablaPendientes}
                            identificador={Tablapaginador}
                            ProPrevPg={handlePrimeraPg}
                            PrevPg={handlePreviaPg}
                            NextPg={handleNexPg}
                            ProNextPg={handleUltimaPg}
                            Pg={Pg}
                            MaxPg={MaxPg}
                            RegsPg={RegsPg}
                            setRegsPg={setRegsPg}
                            ChangeRegs={ChangeRegs}
                            InfoTab={InfoTab}
                        />
                     }
                </div>

                <StyledActividades>
                    <div className='acomodo'>
                        
                        <div className='ContenedorFiltros'>
                            <InputPKT1
                                label={"Buscar"}
                                placeholder={"Origen"}
                                type={"search"}
                                mostrar={true}
                                background={"rgb(58, 147, 56)"}
                                BtnImg={Buscarimg}
                                Accion={Buscador2}
                                value={busquedaLocal}
                            />
                        </div>
                        <div className='ContenedorAgregar'>
                            <Agregar IconoAgregar={Add} Accion={() => openModal()} />
                        </div>
                    </div>
                </StyledActividades>
            </div>

            <Modal
                estado={showModal}
                titulo={modoEdicion ? "EDITAR ORIGEN" : "NUEVO ORIGEN"}
                cambiarEstado={closeModal}
            >
                <div>

                    <div className='identificador'>
                        {origenSeleccionado && (
                            <div style={{marginLeft:'20px'}}>
                                <p>Editando origen: <RenderID id={origenSeleccionado.id} /> <RenderNombre nombre={origenSeleccionado.nombre}/>   </p>
                            </div>
                        )}
                    </div>

                    <div className='inputOrigen'>
                        <InputPKT1
                            label={"Nombre"}
                            type={"text"}
                            mostrar={"none"}
                            desactivar={false}
                            Accion={(e) => ChangeNombre(e.target.value)}
                            value={nuevoOrigen}
                        />
                    </div>
                    <div className='botonAccion'>
                        <BotonesAcciones
                            accion={modoEdicion ? "Guardar cambios" : "Guardar y enviar"}
                            colorFondo={"#2D8520"}
                            colorLetra={"#FFF"}
                            clickBoton={handlerGuardarOrigen}
                        />
                    </div>
                </div>
            </Modal>
        </StyledBitacoraSoporte>
    );
};
export default CatalagoSoporte;