import React, { useState } from 'react'
import { StyledDashboard } from '../themes/StyledDashboard'
import Home from '../assets/svg/inicio.svg';
import Documento from '../assets/svg/papel.svg';
import DocumentoGreen from '../assets/svg/papelGreen.svg';
import Factura from '../assets/svg/factura.svg';
import FacturaGreen from '../assets/svg/facturaGreen.svg';
import Bitacora from '../assets/svg/libro.svg';
import BitacoraGreen from '../assets/svg/libroGreen.svg';
import Submenu from './Submenu';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import Reportes from '../assets/svg/analitica.svg';
import ReportesGreen from '../assets/svg/analiticaGreen.svg';
import Catalogo from '../assets/svg/Catalogo.svg';
import CatalogoGreen from '../assets/svg/CatalogoGreen.svg';
import Cerrar from "../assets/svg/xMenu.svg";
import K from '../assets/svg/PKT1MiniLogo.png';
import Wave from '../assets/svg/wave.svg';
import PKT1 from '../assets/svg/PKT1_SVG_white.svg';
import { useLocalStorage } from '../hooks/useLocalStorage';


const Dashboard = (props) => {
  const [imagenHome, setimagenHome] = useState(Home);
  // const [imagenDocumento, setimagenDocumento] = useState(Documento);
  // const [imagenFac, setimagenFac] = useState(Factura);
  const [imagenBit, setimagenBit] = useState(Bitacora);
  const [imagenReportes, setimagenReportes] = useState(Reportes);
  const [imagenCatalogo, setimagenCatalogo] = useState(Catalogo);
  // const [home, setHome] = useState(true);
  // const [submenuDoc, setSubmenuDoc] = useState(false);
  // const [submenuFac, setSubmenuFac] = useState(false);
  const [submenuBit, setSubmenuBit] = useState(false);
  const [submenuReportes, setSubmenuReportes] = useState(false);
  const [hoverHome, setHoverHome] = useState("Desmarcado");
  // const [marcadoHome, setMarcadoHome] = useState(true);
  // const [showSubmenuDoc, setShowSubmenuDoc] = useState("none");
  // const [hoverDoc, setHoverDoc] = useState("Desmarcado");
  // const [marcadoDoc, setMarcadoDoc] = useState(false);
  // const [showSubmenuFac, setShowSubmenuFac] = useState("none");
  // const [hoverFac, setHoverFac] = useState("Desmarcado");
  // const [marcadoFac, setMarcadoFac] = useState(false);
  const [showSubmenuBit, setShowSubmenuBit] = useState("none");
  const [hoverBit, setHoverBit] = useState("Desmarcado");
  // const [marcadoBit, setMarcadoBit] = useState(false);
  const [showSubmenuReportes, setShowSubmenuReportes] = useState("none");
  const [hoverReportes, setHoverReportes] = useState("Desmarcado");
  // const [marcadoReportes, setMarcadoReportes] = useState(false);
  const [showSubmenuProyectos, setshowSubmenuProyectos] = useState("none");
  const [hoverCatalogo, setHoverCatalogo] = useState("Desmarcado");
  const [marcadoCatalogo, setMarcadoCatalogo] = useState(false);
  const navigate = useNavigate();


  const { PaginaActual, cambioSeccion } = useLocalStorage();

  const mostrarSubmenuBit = () => {
    if (!submenuBit) {
      setShowSubmenuBit("block");
      setHoverBit("mouseSobreElemento");
    }
  }


  const ocultarSubmenuBit = () => {
    if (!submenuBit) {
      setShowSubmenuBit("none");
      setHoverBit("Desmarcado");
    }
  }

  const mostrarSubmenuReportes = () => {
    if (!submenuReportes) {
      setShowSubmenuReportes("block");
      setHoverReportes("mouseSobreElemento");
    }
  }

  const ocultarSubmenuReportes = () => {
    if (!submenuReportes) {
      setShowSubmenuReportes("none");
      setHoverReportes("Desmarcado");
    }
  }

  const mostrarSubmenuCatalogo = () => {
    if (!submenuReportes) {
      setshowSubmenuProyectos("block");
      setHoverCatalogo("mouseSobreElemento");
    }
  }

  const ocultarSubmenuCatalogo = () => {
    if (!submenuReportes) {
      setshowSubmenuProyectos("none");
      setHoverCatalogo("Desmarcado");
    }
  }

  // const seccionDesarrollo = () => {
  //   Swal.fire({
  //     title: 'Sección en Desarrollo',
  //     icon: 'warning'
  //   })
  // }

  //Esta funcion se manda a llamar cuando se le hace click al Home y se le hereda la clase Seleccion

  const seleccionHome = () => {
    navigate("/Inicio");
    cambioSeccion("Inicio");
    props.setMostrarMenu(false);
  }

  //Esta funcion se manda a llamar cuando se le hace click al submenu de documentacion y se le hereda la clase Seleccion

  // const seleccionDocumentacion = () => {
  //   setMarcadoDoc(true);
  //   setSubmenuDoc(false);
  //   setShowSubmenuDoc("none");
  //   setHoverDoc("Desmarcado");
  //   setHome(false);
  //   setimagenHome(Home);
  //   setMarcadoHome(false);
  //   setSubmenuFac(false);
  //   setimagenFac(Factura);
  //   setShowSubmenuFac("none");
  //   setMarcadoFac(false);
  //   setSubmenuBit(false);
  //   setimagenBit(Bitacora);
  //   setShowSubmenuBit("none");
  //   setMarcadoBit(false);
  //   setMarcadoReportes(false);
  //   setimagenReportes(Reportes);
  //   setMarcadoCatalogo(false);
  //   setimagenCatalogo(Catalogo);
  //   setHoverCatalogo("Desmarcado");
  // }

  // //Esta funcion se manda a llamar cuando se le hace click al submenu de facturas y se le hereda la clase Seleccion
  // const seleccionFacturas = () => {
  //   setMarcadoFac(true);
  //   setSubmenuFac(false);
  //   setShowSubmenuFac("none");
  //   setHoverFac("Desmarcado");
  //   setHome(false);
  //   setimagenHome(Home);
  //   setMarcadoHome(false);
  //   setSubmenuDoc(false);
  //   setimagenDocumento(Documento);
  //   setShowSubmenuDoc("none");
  //   setMarcadoDoc(false);
  //   setSubmenuBit(false);
  //   setimagenBit(Bitacora);
  //   setShowSubmenuBit("none");
  //   setMarcadoBit(false);
  //   setMarcadoReportes(false);
  //   setimagenReportes(Reportes);
  //   setMarcadoCatalogo(false);
  //   setimagenCatalogo(Catalogo);
  //   setHoverCatalogo("Desmarcado");
  //   setshowSubmenuProyectos("none");
  // }


  //Esta funcion se manda a llamar cuando se le hace click al submenu de bitacora y se le hereda la clase Seleccion
  const seleccionActividades = () => {
    navigate("/listadoActividades");
    cambioSeccion("Bitacora");
    props.setMostrarMenu(false);
  }


  const seleccionSoportes = () => {
    navigate("/soportes");
    cambioSeccion("Soporte de Tickets");

    props.setMostrarMenu(false);
  }

  const seleccionReportes = () => {
    navigate("/ReportesActividades");
    cambioSeccion("Reportes");
    props.setMostrarMenu(false);
  }

  const seleccionProyectos = () => {
    navigate("/CatalogoProyectos");
    cambioSeccion("Catalogo");
    props.setMostrarMenu(false);
  }
  const seleccionSoporte = () => {
    navigate("/CatalagoSoporte");
    cambioSeccion("Origenes");
    props.setMostrarMenu(false);
  }


  const seleccionDepartamentos = () => {
    navigate("/CatalagoDepartamentos");
    cambioSeccion("Catálogo");
    props.setMostrarMenu(false);
  }


  const seleccionPrueba = () => {
    navigate("/Prueba");
    cambioSeccion("Departamentos");
    props.setMostrarMenu(false);
  }


  const seleccionProcesos = () => {
    navigate("/CatalagoProcesos");
    cambioSeccion("Procesos");
    props.setMostrarMenu(false);
  }


  const seleccionTipos = () => {
    navigate("/CatalagoTipos");
    cambioSeccion("Tipos de Problemas");
    props.setMostrarMenu(false);
  }


  const NivelPrioridad = () => {
    navigate("/CatalogoNivelPrioridad");
    cambioSeccion("Nivel de prioridad");
    props.setMostrarMenu(false);
  }



  return (
    <StyledDashboard>
      <div className='DashBoard'>
        <div className='DashboardTop'>
          <div className='Wave'>
          </div>
          {/* <img className='Wave' src={Wave} alt="" /> */}
          <li className='logo'><img src={PKT1} alt="PKT1" /></li>
        </div>
        <div className='DashboardBody'>
          <img className='cerrarMenu' src={Cerrar} alt="Cerrar" onClick={() => props.setMostrarMenu(false)} />
          <div className='backDiv'>
            <li className={PaginaActual == "Inicio" ? `Seleccion Alinear` : `Desmarcado Alinear ${hoverHome}`} /*onMouseEnter={mostrarHoverHome} onMouseLeave={ocultarHoverHome}*/ onClick={seleccionHome}>
              <div className={PaginaActual == "Inicio" ? 'ContenedorOpcion' : '' }></div>
              <img src={imagenHome} alt="Inicio" />
              <p>Inicio</p>
              <div className={PaginaActual == "Inicio" ? 'Selector' : ''}></div>
            </li>
          </div>
          <div className='backDiv backDivCatalogo' onMouseLeave={ocultarSubmenuCatalogo} onClick={mostrarSubmenuCatalogo}>
            <li className={PaginaActual == "Catálogo" ? `Seleccion Alinear` : `Desmarcado Alinear  ${hoverCatalogo}`} /*onMouseEnter={mostrarSubmenuCatalogo}*/>
              <div className={PaginaActual == "Catálogo" ? 'ContenedorOpcion' : ''}></div>
              <img src={imagenCatalogo} alt="Catalogo" />
              <p>Catálogo</p>
              <div className={PaginaActual == "Catálogo" ? 'Selector' : '' }></div>
            </li>
            <div className={marcadoCatalogo && 'submenuCat'} /*onMouseEnter={mostrarSubmenuCatalogo} onMouseLeave={ocultarSubmenuCatalogo}*/>
 
              <div onClick={seleccionSoporte}>
                <Submenu opcion="Origenes" show={showSubmenuProyectos} />
              </div>
        
              <div onClick={seleccionPrueba}>
                <Submenu opcion="Departamentos" show={showSubmenuProyectos} />
              </div>
                      
              <div onClick={seleccionProcesos}>
                <Submenu opcion="Procesos" show={showSubmenuProyectos} />
              </div>

              <div onClick={seleccionTipos}>
                <Submenu opcion="Tipos de Problemas" show={showSubmenuProyectos} />
              </div>

              <div onClick={NivelPrioridad}>
                <Submenu opcion="Nivel de Prioridad" show={showSubmenuProyectos} />
              </div>

            </div>
          </div>
          {/* <div className='backDiv' onMouseLeave={ocultarSubmenuDoc} onClick={seccionDesarrollo}>
            <div className={PaginaActual == "Documentación" ? `Seleccion Alinear` : `Desmarcado Alinear  ${hoverDoc}`} onMouseEnter={mostrarSubmenuDoc}>
              <div className={ PaginaActual == "Documentación" ? 'ContenedorOpcion' : '' }></div>
              <img src={imagenDocumento} alt="Documentacion" />
              <p>Tickets</p>
              <div className={ PaginaActual == "Documentación" ? 'Selector' : '' }></div>
            </div>
            <div className='submenuDoc' onMouseEnter={mostrarSubmenuDoc} onMouseLeave={ocultarSubmenuDoc} onClick={seleccionDocumentacion}>
              <div onClick={seccionDesarrollo}>
                <Submenu opcion="Cancelar" show={showSubmenuDoc} />
              </div>
            </div>
          </div>
          <div className='backDiv' onMouseLeave={ocultarSubmenuFac} onClick={seccionDesarrollo}>
            <li className={PaginaActual == "Facturas" ? `Seleccion Alinear` : `Desmarcado Alinear  ${hoverFac}`} onMouseEnter={mostrarSubmenuFac}>
              <div className={PaginaActual == "Facturas" ? 'ContenedorOpcion' : ''}></div>
              <img src={imagenFac} alt="Facturas" />
              <p>Facturas</p>
              <div className={ PaginaActual == "Facturas" ? 'Selector' : ''}></div>
            </li>
            <div className='submenuFac' onMouseEnter={mostrarSubmenuFac} onMouseLeave={ocultarSubmenuFac} onClick={seleccionFacturas}>
              <div onClick={seccionDesarrollo}>
                <Submenu opcion="Normales" show={showSubmenuFac} />
              </div>
              <div onClick={seccionDesarrollo}>
                <Submenu opcion="Quincenales" show={showSubmenuFac} />
              </div>
              <div onClick={seccionDesarrollo}>
                <Submenu opcion="Anticipo" show={showSubmenuFac} />
              </div>
              <div onClick={seccionDesarrollo}>
                <Submenu opcion="Crédito" show={showSubmenuFac} />
              </div>
              <div onClick={seccionDesarrollo}>
                <Submenu opcion="Venta de embalaje" show={showSubmenuFac} />
              </div>
              <div onClick={seccionDesarrollo}>
                <Submenu opcion="Nota de Crédito" show={showSubmenuFac} />
              </div>
            </div>
          </div> */}
          <div className='backDiv' onMouseLeave={ocultarSubmenuBit} onClick={mostrarSubmenuBit}>
            <li className={PaginaActual == "Bitacora" ? `Seleccion Alinear` : `Desmarcado Alinear  ${hoverBit}`} /*onMouseEnter={mostrarSubmenuBit}*/>
            <div className={PaginaActual == "Bitacora" ? 'ContenedorOpcion' : ''}></div>
              <img src={imagenBit} alt="Bitacora" />
              <p>Bitacora</p>
              <div className={ PaginaActual == "Bitacora" ? 'Selector' : ''}></div>
            </li>
            <div className='submenuBit' /*onMouseEnter={mostrarSubmenuBit} onMouseLeave={ocultarSubmenuBit}*/>
              <div onClick={seleccionSoportes}>
                <Submenu opcion="Soportes" show={showSubmenuBit} />
              </div>
            </div>
          </div>

        </div>
      </div>

    </StyledDashboard>
  )
}

export default Dashboard