import React, { useContext, useState, useEffect } from 'react';
import usuario from '../assets/img/usuario.png';
import contra from '../assets/img/contra.png';
import { useAuth } from '../Context/Context';
import { useNavigate } from "react-router-dom";
import { LayoutcontainerLogin } from '../themes/layout';
import Loader from '../Componentes/Loader';
import ImgCelular from '../assets/img/MovilBk.png';
import ImgEscritorio from '../assets/img/empleado-login.png';

//import moment from 'moment/moment';
function Loginpage() {
  const [mesActual, setmesActual] = useState(/*moment().format("MM")*/)
  const { login, loading, setLoading, messageerror } = useAuth();
  //console.log(process.env)
  const navigate = useNavigate()
  const [user1, setuser] = useState('');
  const [pass, setpass] = useState('')



  // const auth = null; // determine if authorized, from context or however you're doing it


  const handleSubmit = async (e) => {
    e.preventDefault();
    let data = JSON.stringify({
      "nombreUsuario": user1,
      "pass": pass
    });
    try {
      setLoading(true);
      await login(data, navigate);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <LayoutcontainerLogin>
        <div className="login-principal" style={
          mesActual === '1' ? { backgroundImage: `url('https://api.pktuno.mx//IMG/fondoa%C3%B1onvo.png')` } :
            mesActual === '5' ? { background: `url('https://api.pktuno.mx//IMG/fondoaniversario.png')` } :
              mesActual === '11' ? { background: `url('https://api.pktuno.mx//IMG/fondonavidad.png')` } :
                mesActual === '12' ? { background: `url('https://api.pktuno.mx//IMG/fondonavidad.png')` }
                  : { backgroundImage: `url('https://api.pktuno.mx//IMG/fondopkt1.png')` }
        }>
          <div className="contendor-login">
            <form className='formulario-login' onSubmit={(e) => handleSubmit(e)}>
              <div className="contenedor-datos">
                <div className='cuerpoformulario'>
                  <h1>Bienvenido
                    {process.env.REACT_APP_SECRET_NAME}
                  </h1>
                  <h2><span><img src={usuario} /></span>Usuario</h2>
                  <input onChange={(e) => setuser(e.target.value)}></input>
                  <h2><span><img src={contra} /></span>Contraseña</h2>
                  <input type="password" onChange={(e) => setpass(e.target.value)}></input>
                  <div className="btnIngresar"> 
                    <button type="submit" value="Ingresar">Iniciar sesión</button>
                  </div>
                </div>
              </div>
            </form>
            <div className="contenedor-img">
              <img className='ImgCelular' src={ImgCelular} alt="" />
              <img className='ImgEscritorio' src={ImgEscritorio} alt="" />
            </div>
          </div>
        </div>
      </LayoutcontainerLogin>
    </>
  )
}

export default Loginpage


