import React, { useEffect, useState } from 'react'
import { StyledHeader } from '../themes/StyledHeader'
import flechaAbajo from '../assets/svg/abajo.svg';
import fotoUsuario from '../assets/svg/ImgStandartUser.svg';
import cerrarSesion from '../assets/svg/cerrar-sesion.svg';
import Notificaciones from '../assets/svg/Notificaciones.svg';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../Context/Context';
import { useShowDataTable } from '../hooks/useShowDataTable';
import { useLocalStorage } from '../hooks/useLocalStorage';

const Header = () => {

    const [showSubmenu, setShowSubmenu] = useState("none");
    const [changeFlecha, setChangeFlecha] = useState(false);
    const navigate = useNavigate();
    const { user, logout } = useAuth();

    const [MostrarEncabezado, setMostrarEncabezado] = useState("");

    const { PaginaActual } = useLocalStorage();

    const mostrarSubmenuLog = () => {
        if (changeFlecha == false) {
            setChangeFlecha(true);
            setShowSubmenu("block");
        } else {
            setChangeFlecha(false);
            setShowSubmenu("none");
        }
    }

    return (
        <StyledHeader>
            <div className='posicionHeader'>
                <div className='componentHeader'>
                    <div className='cuenta'>
                        <div className='SeccionActual'>
                            <h3>{PaginaActual}</h3>
                        </div>
                        <div className='fotoUsuario'>
                            <div className='ImgUsuario'>
                                <img src={fotoUsuario} onClick={mostrarSubmenuLog} alt="fotoUsuario" />
                            </div>
                        </div>
                    </div>
                    <div className='ContenedorSubmenu'>
                        <div className='submenu' style={{ display: showSubmenu }} onClick={() => logout(navigate)} >
                            <ul>
                                <li>Cerrar sesión</li>
                                <div className='ImgCerrarSesion'>
                                    <img src={cerrarSesion} alt="cerrar sesion" />
                                </div>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </StyledHeader>


        // <StyledHeader>
        //     <div className='posicionHeader'>
        //         <div className='componentHeader'>
        //             <div className='cuenta'>
        //                 <div className='SeccionActual'>
        //                     <h3>{PaginaActual}</h3>
        //                 </div>
        //                 <div className='fotoUsuario'>
        //                     <div className='ImgUsuario'>
        //                         <img src={fotoUsuario} onClick={mostrarSubmenuLog} alt="fotoUsuario" />
        //                     </div>
        //                 </div>
        //             </div>
        //             <div className='ContenedorSubmenu'>
        //                 <div className='submenu' style={{ display: showSubmenu }} onClick={() => logout(navigate)}>
        //                     <ul>
        //                         <li>Cerrar sesión</li>
        //                         <div className='ImgCerrarSesion'>
        //                             <img src={cerrarSesion} alt="cerrar sesion" />
        //                         </div>
        //                     </ul>
        //                 </div>
        //             </div>
        //         </div>
        //     </div>
        // </StyledHeader>
    )
}

export default Header