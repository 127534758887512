import { useState } from "react"


const useValidacion = (data) => {

  const [estatusValidacion, setEstatusValidacion] = useState(true);

  let mensaje = null;

    //Validacion de campos
    const validacionFormularios = (value, MensajeAlerta) =>{

        if(value.length === 0){
          // console.log("No se permiten campos vacios");

           return "No se permiten campos vacios"
        }else if(!/^[a-zA-Z0-9]+( [a-zA-Z0-9]+)*$/.test(value)){
          return MensajeAlerta;
        }else{
          return true;
        }
  };

    const validacionCamposVacios = (campo) => {
      if(campo.length === 0){
        return "No se permiten campos vacios"
      }
    }
  return {
    estatusValidacion,
    validacionFormularios,
    validacionCamposVacios
  }
}

export default useValidacion