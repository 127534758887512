import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { StyledItemSubMenu, MenuOption } from '../themes/StyledItemSubMenu';

const ItemSubMenu = ({ options = [], onOptionClick }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [menuStyles, setMenuStyles] = useState({ top: '100%', bottom: 'auto' });
    const subMenuRef = useRef(null);
    const containerMenuRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (e) => {
            if (subMenuRef.current && !subMenuRef.current.contains(e.target)) {
                setIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleToggleMenu = () => {
        setIsOpen((prev) => !prev);
    };

    useEffect(() => {
        if (isOpen && containerMenuRef.current) {
            const rect = containerMenuRef.current.getBoundingClientRect();
            const windowHeight = window.innerHeight;

            // Ajusta la posición del menú si supera el borde inferior
            if (rect.bottom > windowHeight) {
                setMenuStyles({ top: 'auto', bottom: '100%' });
            } else {
                setMenuStyles({ top: '100%', bottom: 'auto' });
            }
        }
    }, [isOpen]);

    const handleClick = (label) => {
        if (onOptionClick) {
            onOptionClick(label);
        }
        setIsOpen(false); // Cierra el menú al seleccionar una opción
    };

    return (
        <StyledItemSubMenu ref={subMenuRef}>
            <div className="SubMenu" onClick={handleToggleMenu}>
                <span className="StyleOption"></span>
                <span className="StyleOption"></span>
                <span className="StyleOption"></span>
            </div>
            {isOpen && (
                <div
                    className="containerMenu"
                    ref={containerMenuRef}
                    style={menuStyles}
                >
                    {options.map((option, index) => (
                        <MenuOption key={index} onClick={() => handleClick(option.label)}>
                            <img src={option.image} alt={option.label} />
                            <p>{option.label}</p>
                        </MenuOption>
                    ))}
                </div>
            )}
        </StyledItemSubMenu>
    );
};

export default ItemSubMenu;
