import moment from 'moment/moment';
import { useState } from 'react'

export const useFecha = () => {

  const formatoFechaBD = 'YYYY-MM-DD';
  const formatoMostrarFecha = 'DD/MM/YYYY';
  const formatoPrimerMes = "YYYY-MM-01";
  const formatoMostrarPrimerDiaMes = "01/MM/YYYY";

    const formatFecha = (fecha) => {
      return moment(fecha).format(formatoFechaBD);
    }

    const formatInsertFecha = (fecha) => {
      return moment(fecha, 'YYYY-MM-DD').format(formatoFechaBD);
    }

    const formatEditFecha = (fecha) => {
      return moment(fecha, 'YYYY-MM-DD').format(formatoFechaBD);
    }

    const formatShowFecha = (fecha) => {
      return moment(fecha, 'YYYY-MM-DD').format(formatoMostrarFecha);
    }

    const formatShowDefaultFecha = (fecha) => {
      return moment(fecha, 'YYYY-MM-DD').format(formatoMostrarFecha);
    }

    const primerDiaMes = (fecha) =>{
      return moment(fecha).format(formatoPrimerMes);
    }

    const formatprimerDiaMes = (fecha) =>{
      return moment(fecha, 'YYYY-MM-DD').format(formatoMostrarPrimerDiaMes);
    }

    const fechaActualMostrar = moment().format(formatoFechaBD);
    const unMesDespues = moment().add(1, 'months');



    
  return {
    formatFecha,
    formatInsertFecha,
    formatShowFecha,
    formatShowDefaultFecha,
    fechaActualMostrar,
    formatEditFecha,
    primerDiaMes,
    formatprimerDiaMes,
    unMesDespues
  };
}