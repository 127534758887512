import axios from 'axios';
import React, { useState } from 'react'
import { useAlert } from './useAlert';

const useEliminarDepartamento = () => {

    const { alertSave, alertError } = useAlert();

    const [DepartamentoEliminar, setDepartamentoEliminar] = useState(false);

    const EliminarDepartamento = async (url, cancelar) => {

      try{

        let RespuestaServicio =  await axios.put(url, cancelar);
        alertSave("Departamento eliminado correctamente");
      }catch(error){
        alertError(error);
      }

    }

  return {
    EliminarDepartamento,
    DepartamentoEliminar,
    setDepartamentoEliminar
  }
}

export default useEliminarDepartamento