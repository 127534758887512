import React from 'react'
import { StyledInputText } from '../themes/StyledInputText'

const InputText = (props) => {
    return (
        <StyledInputText>
            <div className='ContenedorTextArea'>
                <label className={props.CampoValidado && props.value === "" ? 'LabelTextAreaVacio' : 'TextAreaLabel'}>
                    {props.label}
                </label>
                <div className={props.mostrar === "none" ? 'Input' : 'InputBtn'}>
                    <textarea 
         
                        type={props.type}
                        placeholder={props.placeholder}
                        value={props.value}
                        onChange={props.Accion}
                        disabled={props.desactivar}
                        maxLength={props.size}
                        max={props.limite}
                        min={props.minimo}
                    />
                    {props.mostrar !== "none" && (
                        <button className='btn' style={{ background: props.background }}>
                            <img src={props.BtnImg} alt='Buscar' />
                        </button>
                    )}
                </div>
                {props.size !== undefined && (
                    <p className='ContenedorCaracteres'>
                        {props.value.length} / {props.size}
                    </p>
                )}
            </div>
        </StyledInputText>
    )
}

export default InputText
