import axios from 'axios';
import React, { useState } from 'react'
import moment from 'moment';

const useDetailsDepartamentos = () => {

  const [loading, setLoading] = useState(true);
  const [DetallesDepartamento, setDetallesDepartamento]  = useState();
  const [nombreDepartamento, setNombreDepartamento] = useState("");
  const [observacionesDepartamento, setObservacionesDepartamento] = useState("");
  const [IdUsuaruos, setIdUsuaruos] = useState("");


    const showDetailsProyect = async (url) => {
      try{
        setLoading(true);
        let RespuestaServicio = await axios.get(url)

            setNombreDepartamento(RespuestaServicio.data.nombre);
            setObservacionesDepartamento(RespuestaServicio.data.observaciones);
            setIdUsuaruos(RespuestaServicio.data.idusuarios);
            //setDetallesDepartamento(RespuestaServicio.data);
            setLoading(false);
      }catch(error){
        console.log(error);
      }
    }

  return {
    showDetailsProyect,
    DetallesDepartamento,
    setDetallesDepartamento,
    nombreDepartamento,
    setNombreDepartamento,
    observacionesDepartamento,
    setObservacionesDepartamento,
    IdUsuaruos,
    setIdUsuaruos
  }
}

export default useDetailsDepartamentos