import styled from 'styled-components';
const StyledSwitchButton = styled.div`
.contenedorSwitch{
   display: flex;
    align-items: center;
}
.DivSwitch span:hover{
    color: red;
}
.DivSwitch span{
    color: transparent;
}
.LabelSwitch{
    padding-right: 5px;
}
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #DBB9B9;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: red;
  transition: .4s;
}

input:checked + .slider {
  background-color: #9FC6A8; 
}

input:checked + .slider:before {
  transform: translateX(26px); 
  background: #40A859;
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

`

export { StyledSwitchButton }