import moment from 'moment/moment';
import { useEffect, useState } from 'react'
export const useLocalStorage = () => {
    const [PaginaActual, setPaginaActual] = useState(
      localStorage.getItem("lastmenu") ? JSON.parse(localStorage.getItem("lastmenu")) : "Inicio"
    );
    const cambioSeccion = (seccionActual) => {
        localStorage.setItem("lastmenu", JSON.stringify(seccionActual));
        setPaginaActual(localStorage.getItem("lastmenu"));
    }
    useEffect(() => {
        let nuevaSeccion = JSON.parse(localStorage.getItem('lastmenu'));
        setPaginaActual(nuevaSeccion);
    }, [cambioSeccion])
  return {
    cambioSeccion,
    PaginaActual
    
  };
}





// import { useLocation } from 'react-router-dom';
// import { useEffect, useState } from 'react';
// import moment from 'moment/moment';

// export const useLocalStorage = () => {
//     const location = useLocation();
//     const [PaginaActual, setPaginaActual] = useState(
//         localStorage.getItem("lastmenu") ? JSON.parse(localStorage.getItem("lastmenu")) : "Inicio"
//     );

//     const secciones = {
//       "/CatalagoSoporte": "Orígenes",
//       "/Prueba": "Departamentos",
//       "/CatalagoProcesos": "Procesos",
//       "/CatalagoTipos": "Tipos de Problemas",
//       "/CatalogoNivelPrioridad": "Nivel de Prioridad",
//   };
//         const cambioSeccion = (secciones) => {
//         localStorage.setItem("lastmenu", JSON.stringify(secciones));
//         setPaginaActual(localStorage.getItem("lastmenu"));
//     }
//     // useEffect(() => {
//     //     const nuevaSeccion = secciones[location.pathname] || "Catálogo";
//     //     localStorage.setItem("lastmenu", JSON.stringify(nuevaSeccion));
//     //     setPaginaActual(nuevaSeccion);
//     // }, [cambioSeccion]);


//     useEffect(() => {
//       const nuevaSeccion = secciones[location.pathname] || "Catálogo";
//         localStorage.setItem("lastmenu", JSON.stringify(nuevaSeccion));
//         setPaginaActual(nuevaSeccion);

//     }, [cambioSeccion])

//     return {
//         PaginaActual,
//         cambioSeccion
//     };
// };
