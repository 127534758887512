import { createContext, useContext, useEffect, useState } from "react";
import axios from 'axios'
import config from "../config";
import Swal from "sweetalert2";
import { useLocalStorage } from "../hooks/useLocalStorage";
export const authContext = createContext();

export const useAuth = () => {
  const context = useContext(authContext);
  if (!context) throw new Error("There is no Auth provider");
  return context;
};

export function AuthProvider({ children }) {
  const url = `${config.API_URL}Login/login?Origin=Web`
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')));
  const [messageerror, setmessageerror] = useState();
  const [loading, setLoading] = useState(false);
  const [isActiveSubmenu, setActiveSubmenu] = useState(true);
  const [isActiveMenu, setisActiveMenu] = useState();
  const { cambioSeccion } = useLocalStorage();

  const login = (data, navigate) => {
    setLoading(true);
    
    var headerLogin = {
      method: 'post',
      url: `${config.API_URL}Login/login?Origin=Interno`,
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(headerLogin)
      .then(function (responseLogin) {
        var headerUsers = {
          method: 'get',
          url: `${config.API_URL}Usuario/getuser/${responseLogin.data.idusuario}`,
          headers: {}
        };

        axios(headerUsers)
          .then(function (responseUser) {
            setLoading(false);
            setUser(responseUser.data);
            Swal.fire({
              icon: 'success',
              title: "Conectado"
              })
            navigate("/Inicio");
            cambioSeccion("Inicio");

            axios
              .then(function (responsesuc) {
              })
              .catch(function (error) {
              });

          })
          .catch(function (error) {
          });

      })
      .catch(function (error) {
        Swal.fire({
          icon: 'error',
          title: JSON.stringify(error.response.data)
        })
      });
  };

  const logout = (navigate) => {
    const data = "Login"
    localStorage.removeItem("auth")
    localStorage.removeItem("user")
    localStorage.removeItem("sucursal")
    localStorage.removeItem("lastsubmenu")
    localStorage.setItem("lastmenu", JSON.stringify(data))
    Swal.fire({
      icon: 'success',
      title: "Desconectado"
    })
    navigate("/");
  };
  const handlercambioSucursal = (data) => {
    localStorage.setItem("sucursal", JSON.stringify({ idSucursal: data.id, nombre: data.nombre }))
  }
  const handleractiveMenu = (data) => {
    setisActiveMenu(data);
    
    localStorage.setItem("lastmenu", JSON.stringify(data))
  }
  const handleractiveSubMenu = (data) => {
    setActiveSubmenu(data)

  }
  useEffect(() => {
    localStorage.setItem('user', JSON.stringify(user));
  }, [user])

  return (
    <authContext.Provider
      value={{
        login,
        user,
        logout,
        loading,
        setLoading,
        messageerror,
        isActiveSubmenu,
        isActiveMenu,
        handleractiveMenu,
        handleractiveSubMenu,
      }}
    >
        {children}
    </authContext.Provider>
  );
}