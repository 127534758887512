import { useState } from 'react'
import axios from 'axios'
import { useAlert } from './useAlert';
import { useShowDataTable } from './useShowDataTable';

export const useGuardar = () => {

    const [data, setData] = useState(null);

    const { alertSave, alertError } = useAlert();

    const [showModalAgregar, setShowModalAgregar] = useState(false);

    const { loading, setLoading } = useShowDataTable();

    // const guardar = async (url,data) => {
    //   setLoading(true);
    //   console.log(data);
    //   console.log(url);
    //   await axios.post(url, data)
    //   .then(response => {
    //     setData(response.data);
    //     // console.log(response.data);
    //     alertSave("Actividad registrada");
    //     setShowModalAgregar(false);
    //     setLoading(false);
    //   })
    //   .catch(error => {
    //     console.log(error);
    //     alertError(error.response.data.message);
    //   });
    // }


    const guardar = async (url,data) => {

      try{
        setLoading(true);
        let RespuestaServicio = await axios.post(url, data);
        setData(RespuestaServicio.data);
        // console.log(response.data);
        alertSave("Actividad registrada");
        setShowModalAgregar(false);
        setLoading(false);
      }catch(error){
        console.log(error);
        alertError(error.response.data.message);
      }
    }

    const guardarCambios = async (url,data) => {
      console.log(data);

      try {
        let respuestacambios = await axios.put(url, data)
        setData(respuestacambios.data)
        alertSave("Actividad actualizada");
        setShowModalAgregar(false);
        
      } catch (error) {
        alertError(error.request.response);
      }
    }

    
  return {
    data,
    guardar,
    guardarCambios,
    showModalAgregar,
    setShowModalAgregar
  };
}