// useTicket.js

import React, { useRef, useState, useEffect } from 'react';

import axios from 'axios';
import Swal from 'sweetalert2';
import { useAuth } from '../Context/Context';
import { usePaginadorNuevo } from './usePaginadorNuevo';
import moment from 'moment';

const useTicket = () => {
    const { user } = useAuth();
    const [nuevoTicket, setnuevoTicket] = useState();
    const [respPaginador, setrespPaginador] = useState();
    const [showModal, setShowModal] = useState(false);
    const [modoEdicion, setModoEdicion] = useState(false);
    const [ticketSeleccionado, setTicketSeleccionado] = useState(null);
    const [page, setPage] = useState(1);
    const [totalPage, setTotalPage] = useState(1);
    const [Buscar, setBuscar] = useState();
    const [RegistrosPorPagina, setRegistrosPorPagina] = useState(10)
    const [RespestaGetTicket, setRespestaGetTicket] = useState();
    const { Pg, MaxPg, RegsPg, Loading, InfoTab, GetPages, ProPrevPg, PrevPg, NextPg, ProNextPg, setRegsPg } = usePaginadorNuevo();


    const [isPriorityHigh, setIsPriorityHigh] = useState(false);


    const validateForm = () => {
        console.log("Valor de idUsuarios2:", idUsuarios2); // Para verificar el valor de idUsuarios2
        if (!descripcion.trim()) {
            Swal.fire('Error', 'La descripción no puede estar vacía', 'error');
            return false;
        }
        if (!idTipoProblema) {
            Swal.fire('Error', 'Debe seleccionar un tipo de problema', 'error');
            return false;
        }
        if (!idUsuarios) {
            Swal.fire('Error', 'Debe seleccionar un solicitante', 'error');
            return false;
        }
        if (!idUsuarios2) {
            Swal.fire('Error', 'Debe seleccionar un usuario que resuelve', 'error');
            return false;
        }
        if (!id) {
            Swal.fire('Error', 'Debe seleccionar un origen', 'error');
            return false;
        }
        if (!idSucursal) {
            Swal.fire('Error', 'Debe seleccionar una sucursal', 'error');
            return false;
        }
        if (!idProceso) {
            Swal.fire('Error', 'Debe seleccionar un proceso', 'error');
            return false;
        }

        if (!idPrioridad) {
            Swal.fire('Error', 'Debe seleccionar un nivel de prioridad', 'error');
            return false;
        }
        return true;
    };





    //en esta parte vamos a traernos el servicio del nivel de prioridad porque no podemos usar el que ya teniamos ya que este sera el que 
    //se enviara para agregar o actualizar , no el que se usara para los filtros 

    const [idPrioridad, setidPrioridad] = useState('');
    const [RespuestaPrioridad, setRespuestaPrioridad] = useState('');

    const handleIdPrioridadChange = (data) => {
        setidPrioridad(data);
    };

    const ObtenerNivelPrioridad = async () => {
        setLoading(true);

        try {
            const response = await axios.get(`${process.env.REACT_APP_API_SOPORTE}NivelPrioridad/Obtener?idNivelPrioridad=0`);
            // console.log('Respuesta directa de la API problemas:', response);
            console.log('Datos de la respuesta para el nivel de prioridad:', response.data);

            const datos = response.data;

            if (Array.isArray(datos)) {
                const nuevaRespuesta = datos.map(prioridad => ({
                    id: prioridad.id,
                    nombre: prioridad.nombre
                }));
                setRespuestaPrioridad(nuevaRespuesta);
                console.log("Datos procesados de tipos de prioridad:", nuevaRespuesta);
            } else if (datos && Array.isArray(datos.value)) {
                const nuevaRespuesta = datos.value.map(prioridad => ({
                    id: prioridad.id,
                    nombre: prioridad.nombre
                }));
                setRespuestaPrioridad(nuevaRespuesta);
                console.log("Datos procesados de problema (desde .value):", nuevaRespuesta);
            } else if (datos && typeof datos === 'object') {
                console.log('Claves disponibles en la respuesta:', Object.keys(datos));
                const posibleArray = Object.values(datos).find(val => Array.isArray(val));
                if (posibleArray) {
                    const nuevaRespuesta = posibleArray.map(prioridad => ({
                        id: prioridad.id,
                        nombre: prioridad.nombre
                    }));
                    setRespuestaPrioridad(nuevaRespuesta);
                    console.log("Datos procesados de origenes (estructura alternativa):", nuevaRespuesta);
                } else {
                    console.error('No se pudo encontrar un array en la respuesta');
                    setRespuestaPrioridad([]);
                }
            } else {
                console.error('Estructura de datos inesperada:', datos);
                setRespuestaPrioridad([]);
            }
        } catch (error) {
            console.error('Error completo al obtener origenes:', error);
            if (error.response) {
                console.error('Datos del error:', error.response.data);
                console.error('Estado del error:', error.response.status);
                console.error('Headers del error:', error.response.headers);
            }
            setRespuestaPrioridad([]);
        } finally {
            setLoading(false);
        }
    }




    const [idDepartamento, setIdDepartamento] = useState('');
    const [RespuestaDepartamento, setRespuestaDepartamento] = useState('');

    const handleIdDepaertamentoChange = (data) => {
        setIdDepartamento(data);
    }

    const ObtenerDepartamento = async () => {
        setLoading(true);

        try {
            const response = await axios.get(`${process.env.REACT_APP_API_SOPORTE}Departamentos/Obtener?iddepartamento=0`);
            // console.log('Respuesta directa de la API problemas:', response);
            console.log('aqui van los depas:', response.data);
            const datos = response.data;

            if (Array.isArray(datos)) {
                const nuevaRespuesta = datos.map(departamento => ({
                    id: departamento.id,
                    nombre: departamento.nombre
                }));
                setRespuestaDepartamento(nuevaRespuesta);
                console.log("Datos procesados de departamento:", nuevaRespuesta);
            } else if (datos && Array.isArray(datos.value)) {
                const nuevaRespuesta = datos.value.map(departamento => ({
                    id: departamento.id,
                    nombre: departamento.nombre
                }));
                setRespuestaDepartamento(nuevaRespuesta);
                console.log("Datos procesados de problema (desde .value):", nuevaRespuesta);
            } else if (datos && typeof datos === 'object') {
                console.log('Claves disponibles en la respuesta:', Object.keys(datos));
                const posibleArray = Object.values(datos).find(val => Array.isArray(val));
                if (posibleArray) {
                    const nuevaRespuesta = posibleArray.map(departamento => ({
                        id: departamento.id,
                        nombre: departamento.nombre
                    }));
                    setRespuestaDepartamento(nuevaRespuesta);
                    console.log("Datos procesados de origenes (estructura alternativa):", nuevaRespuesta);
                } else {
                    console.error('No se pudo encontrar un array en la respuesta');
                    setRespuestaDepartamento([]);
                }
            } else {
                console.error('Estructura de datos inesperada:', datos);
                setRespuestaDepartamento([]);
            }
        } catch (error) {
            console.error('Error completo al obtener origenes:', error);
            if (error.response) {
                console.error('Datos del error:', error.response.data);
                console.error('Estado del error:', error.response.status);
                console.error('Headers del error:', error.response.headers);
            }
            setRespuestaDepartamento([]);
        } finally {
            setLoading(false);
        }

    }



    //estos son para obtener los usuairos  para a quien se le va a asignar 
    const [idUsuarios, setIdUsuarios] = useState('');
    const [RespuestaUsuarios, setRespuestaUsuarios] = useState();

    const handleIdUsuariosChange = (data) => {
        setIdUsuarios(data);
    };


    const ObtenerUser2 = async () => {
        setLoading(true);

        try {
            const response = await axios.get(`${process.env.REACT_APP_API_SOPORTE}Usuarios/Usuarios?filtro=false`);
            const respuestaServicio2 = response.data;
            if (respuestaServicio2 && Array.isArray(respuestaServicio2.value)) {
                const nuevaRespuesta2 = respuestaServicio2.value.map(usuario => ({
                    idUsuario: usuario.idUsuario,
                    encargado: usuario.encargado
                }));

                setRespuestaUsuarios(nuevaRespuesta2);
                console.log("Datos procesados de usuarios:", nuevaRespuesta2);
            } else {
                console.error('No se encontró un array de usuarios en la respuesta:', respuestaServicio2);
                setRespuestaUsuarios([]);
            }
        } catch (error) {
            console.error('Error al obtener usuarios:', error);
            setRespuestaUsuarios([]);
        } finally {
            setLoading(false);
        }
    }


    //y ahora ocupamos un servicio exactamente igual al anterior pero  para los usuarios que solicito 
    const [idUsuarios2, setIdUsuarios2] = useState('');
    const [RespuestaUsuarios2, setRespuestaUsuarios2] = useState();

    const handleIdUsuariosChange2 = (data) => {
        setIdUsuarios2(data);
    };

    const ObtenerUser22 = async () => {
        setLoading(true);

        try {
            const response = await axios.get(`${process.env.REACT_APP_API_SOPORTE}Usuarios/Usuarios?filtro=false`);
            const respuestaServicio2 = response.data;
            if (respuestaServicio2 && Array.isArray(respuestaServicio2.value)) {
                const nuevaRespuesta2 = respuestaServicio2.value.map(usuario => ({
                    idUsuario: usuario.idUsuario,
                    encargado: usuario.encargado
                }));

                setRespuestaUsuarios2(nuevaRespuesta2);
                console.log("Datos procesados de usuarios 2:", nuevaRespuesta2);
            } else {
                console.error('No se encontró un array de usuarios en la respuesta:', respuestaServicio2);
                setRespuestaUsuarios2([]);
            }
        } catch (error) {
            console.error('Error al obtener usuarios:', error);
            setRespuestaUsuarios2([]);
        } finally {
            setLoading(false);
        }
    }






    //ahora aca vamos a poner lo de los origenes , se seguira la misma logica que con los usuarios solo se va a cambiar las url y los nomnbres de las variables 

    const [id, setId] = useState('');
    const [RespuestaOrigen, setRespuestaOrigen] = useState();

    const handleIdOrigenChange = (data) => {
        setId(data);
    };

    const ObtenerOrigenes = async () => {
        setLoading(true);

        try {
            const response = await axios.get(`${process.env.REACT_APP_API_SOPORTE}Origenes/Obtener`);
            console.log('Respuesta directa de la API origenes:', response);
            console.log('Datos de la respuesta:', response.data);

            // Si la respuesta viene directamente en data (sin .value)
            const datos = response.data;

            if (Array.isArray(datos)) {
                // Si datos es directamente un array
                const nuevaRespuesta = datos.map(origen => ({
                    id: origen.id,
                    nombre: origen.nombre
                }));
                setRespuestaOrigen(nuevaRespuesta);
                console.log("Datos procesados de origenes:", nuevaRespuesta);
            }
            else if (datos && Array.isArray(datos.value)) {
                // Si los datos están dentro de una propiedad value
                const nuevaRespuesta = datos.value.map(origen => ({
                    id: origen.id,
                    nombre: origen.nombre
                }));
                setRespuestaOrigen(nuevaRespuesta);
                console.log("Datos procesados de origenes (desde .value):", nuevaRespuesta);
            }
            else if (datos && typeof datos === 'object') {
                // Si los datos están en otra estructura, imprimir las claves disponibles
                console.log('Claves disponibles en la respuesta:', Object.keys(datos));

                // Intentar encontrar el array en alguna propiedad del objeto
                const posibleArray = Object.values(datos).find(val => Array.isArray(val));
                if (posibleArray) {
                    const nuevaRespuesta = posibleArray.map(origen => ({
                        id: origen.id,
                        nombre: origen.nombre
                    }));
                    setRespuestaOrigen(nuevaRespuesta);
                    console.log("Datos procesados de origenes (estructura alternativa):", nuevaRespuesta);
                } else {
                    console.error('No se pudo encontrar un array en la respuesta');
                    setRespuestaOrigen([]);
                }
            }
            else {
                console.error('Estructura de datos inesperada:', datos);
                setRespuestaOrigen([]);
            }
        } catch (error) {
            console.error('Error completo al obtener origenes:', error);
            if (error.response) {
                console.error('Datos del error:', error.response.data);
                console.error('Estado del error:', error.response.status);
                console.error('Headers del error:', error.response.headers);
            }
            setRespuestaOrigen([]);
        } finally {
            setLoading(false);
        }
    };




    //ahora vamos a hacer exactamente lo mismo pero con el tipo de problemas , seguiremos la logica de origenes porque la de usaurios nos causo problemas 
    const [idTipoProblema, setIdTipoProblema] = useState('');
    const [RespuestaTipoProblema, setRespuestaTipoProblema] = useState();

    const handleIdTipoProblemaChange = (data) => {
        setIdTipoProblema(data);
    };

    const ObtenerTipoProblema = async () => {
        setLoading(true);

        try {
            const response = await axios.get(`${process.env.REACT_APP_API_SOPORTE}TipoProblema/Obtener`);
            console.log('Respuesta directa de la API problemas:', response);
            console.log('Datos de la respuesta para el TipoProblema:', response.data);

            const datos = response.data;

            if (Array.isArray(datos)) {
                const nuevaRespuesta = datos.map(problema => ({
                    id: problema.id,
                    nombre: problema.nombre
                }));
                setRespuestaTipoProblema(nuevaRespuesta);
                console.log("Datos procesados de tipos de problemas:", nuevaRespuesta);
            } else if (datos && Array.isArray(datos.value)) {
                const nuevaRespuesta = datos.value.map(problema => ({
                    id: problema.id,
                    nombre: problema.nombre
                }));
                setRespuestaTipoProblema(nuevaRespuesta);
                console.log("Datos procesados de problema (desde .value):", nuevaRespuesta);
            } else if (datos && typeof datos === 'object') {
                console.log('Claves disponibles en la respuesta:', Object.keys(datos));
                const posibleArray = Object.values(datos).find(val => Array.isArray(val));
                if (posibleArray) {
                    const nuevaRespuesta = posibleArray.map(problema => ({
                        id: problema.id,
                        nombre: problema.nombre
                    }));
                    setRespuestaTipoProblema(nuevaRespuesta);
                    console.log("Datos procesados de origenes (estructura alternativa):", nuevaRespuesta);
                } else {
                    console.error('No se pudo encontrar un array en la respuesta');
                    setRespuestaTipoProblema([]);
                }
            } else {
                console.error('Estructura de datos inesperada:', datos);
                setRespuestaTipoProblema([]);
            }
        } catch (error) {
            console.error('Error completo al obtener origenes:', error);
            if (error.response) {
                console.error('Datos del error:', error.response.data);
                console.error('Estado del error:', error.response.status);
                console.error('Headers del error:', error.response.headers);
            }
            setRespuestaTipoProblema([]);
        } finally {
            setLoading(false);
        }
    };



    // ahora vamos a obtener las sucursales 

    const [idSucursal, setIdSucursal] = useState('');
    const [RespuestaSucursal, setRespuestaSucursal] = useState();

    const handleIdSucursalChange = (data) => {
        setIdSucursal(data);
    };

    const ObtenerSucursal = async () => {
        setLoading(true);

        try {

            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v2/Sucursal/GetBranchesActive?page=1&registerbypage=10&active=true`);
            console.log('Respuesta directa de la API sucursal:', response);

            console.log('Datos de la respuesta para la Sucursal :', response.data);

            // Si la respuesta viene directamente en data (sin .value)
            const datos = response.data;

            if (Array.isArray(datos)) {
                // Si datos es directamente un array
                const nuevaRespuesta = datos.map(sucursal => ({
                    id: sucursal.id,
                    nombre: sucursal.nombre
                }));
                setRespuestaSucursal(nuevaRespuesta);
                console.log("Datos procesados de sucursal:", nuevaRespuesta);
            }
            else if (datos && Array.isArray(datos.value)) {
                // Si los datos están dentro de una propiedad value
                const nuevaRespuesta = datos.value.map(sucursal => ({
                    id: sucursal.id,
                    nombre: sucursal.nombre
                }));
                setRespuestaSucursal(nuevaRespuesta);
                console.log("Datos procesados de sucursal (desde .value):", nuevaRespuesta);
            }
            else if (datos && typeof datos === 'object') {
                // Si los datos están en otra estructura, imprimir las claves disponibles
                console.log('Claves disponibles en la respuesta:', Object.keys(datos));

                // Intentar encontrar el array en alguna propiedad del objeto
                const posibleArray = Object.values(datos).find(val => Array.isArray(val));
                if (posibleArray) {
                    const nuevaRespuesta = posibleArray.map(sucursal => ({
                        id: sucursal.id,
                        nombre: sucursal.nombre
                    }));
                    setRespuestaSucursal(nuevaRespuesta);
                    console.log("Datos procesados de origenes (estructura alternativa):", nuevaRespuesta);
                } else {
                    console.error('No se pudo encontrar un array en la respuesta');
                    setRespuestaSucursal([]);
                }
            }
            else {
                console.error('Estructura de datos inesperada:', datos);
                setRespuestaSucursal([]);
            }
        } catch (error) {
            console.error('Error completo al obtener origenes:', error);
            if (error.response) {
                console.error('Datos del error:', error.response.data);
                console.error('Estado del error:', error.response.status);
                console.error('Headers del error:', error.response.headers);
            }
            setRespuestaSucursal([]);
        } finally {
            setLoading(false);
        }
    };







    //ahora vamos a obtener los proceso a los que se van a asignar 

    const [idProceso, setIdProceso] = useState('');
    const [RespuestaProceso, setRespuestaProceso] = useState();

    const handleIdProcesoChange = (data) => {
        setIdProceso(data);
    };

    const ObtenerProceso = async () => {
        setLoading(true);

        try {
            // Obtener procesos
            const responseProcesos = await axios.get(`${process.env.REACT_APP_API_SOPORTE}Procesos/Obtener`);
            console.log('Respuesta directa de la API Proceso:', responseProcesos);

            // Obtener departamentos
            const responseDepartamentos = await axios.get(`${process.env.REACT_APP_API_SOPORTE}Departamentos/Obtener`);
            console.log('Respuesta de departamentos:', responseDepartamentos);

            const datos = responseProcesos.data;
            const departamentos = responseDepartamentos.data;

            if (Array.isArray(datos)) {
                const nuevaRespuesta = await Promise.all(datos.map(async proceso => {
                    // Encontrar el departamento correspondiente al proceso
                    const departamento = departamentos.find(dep => dep.id === proceso.iddepartamento);

                    return {
                        id: proceso.id,
                        nombre: proceso.nombre,
                        departamento: departamento ? {
                            id: departamento.id,
                            nombre: departamento.nombre,
                            encargado: departamento.encargado,
                            nombreSucursal: departamento.nombreSucursal
                        } : null
                    };
                }));

                setRespuestaProceso(nuevaRespuesta);
                console.log("Datos procesados de Proceso con departamentos:", nuevaRespuesta);
            }
            else if (datos && Array.isArray(datos.value)) {
                const nuevaRespuesta = await Promise.all(datos.value.map(async proceso => {
                    const departamento = departamentos.value.find(dep => dep.id === proceso.iddepartamento);

                    return {
                        id: proceso.id,
                        nombre: proceso.nombre,
                        departamento: departamento ? {
                            id: departamento.id,
                            nombre: departamento.nombre,
                            encargado: departamento.encargado,
                            nombreSucursal: departamento.nombreSucursal
                        } : null
                    };
                }));

                setRespuestaProceso(nuevaRespuesta);
                console.log("Datos procesados de Proceso con departamentos (desde .value):", nuevaRespuesta);
            }
            else if (datos && typeof datos === 'object') {
                console.log('Claves disponibles en la respuesta:', Object.keys(datos));

                const posibleArray = Object.values(datos).find(val => Array.isArray(val));
                if (posibleArray) {
                    const nuevaRespuesta = await Promise.all(posibleArray.map(async proceso => {
                        const departamento = departamentos.find(dep => dep.id === proceso.iddepartamento);

                        return {
                            id: proceso.id,
                            nombre: proceso.nombre,
                            departamento: departamento ? {
                                id: departamento.id,
                                nombre: departamento.nombre,
                                encargado: departamento.encargado,
                                nombreSucursal: departamento.nombreSucursal
                            } : null
                        };
                    }));

                    setRespuestaProceso(nuevaRespuesta);
                    console.log("Datos procesados con departamentos (estructura alternativa):", nuevaRespuesta);
                } else {
                    console.error('No se pudo encontrar un array en la respuesta');
                    setRespuestaProceso([]);
                }
            }
            else {
                console.error('Estructura de datos inesperada:', datos);
                setRespuestaProceso([]);
            }
        } catch (error) {
            console.error('Error completo al obtener datos:', error);
            if (error.response) {
                console.error('Datos del error:', error.response.data);
                console.error('Estado del error:', error.response.status);
                console.error('Headers del error:', error.response.headers);
            }
            setRespuestaProceso([]);
        } finally {
            setLoading(false);
        }
    };





    const [descripcion, setDescripcion] = useState('');


    const handleDescripcionChange = (data) => {
        console.log('Valor recibido en handleDescripcionChange:', data); // Muestra el valor recibido
        setDescripcion(data);
    };



    const ObtenerTickets222 = (busqueda) => {
        const busquedaParam = busqueda.trim() !== '' ? busqueda : '';
        GetPages(`${process.env.REACT_APP_API_SOPORTE}Tickets/Paginador?buscar=${busquedaParam}&pagina=${Pg}&registrosPorPagina=${RegsPg}`);
    };
    const ObtenerTickets33 = () => {
        GetPages(`${process.env.REACT_APP_API_SOPORTE}Tickets/Paginador?buscar=&pagina=${Pg}&registrosPorPagina=${RegsPg}`);
    };
    const ObtenerTickets2 = (busqueda) => {
        GetPages(`${process.env.REACT_APP_API_SOPORTE}Tickets/Paginador?buscar=${busqueda}&pagina=${Pg === 0 ? 1 : Pg}&registrosPorPagina=${RegsPg === 0 ? 10 : RegsPg}`);
    };

    const ObtenerTickets = () => {
        GetPages(`${process.env.REACT_APP_API_SOPORTE}Tickets/Paginador?buscar=${Buscar === undefined ? '' : Buscar}&pagina=${Pg === 0 ? 1 : Pg}&registrosPorPagina=${RegsPg === 0 ? 10 : RegsPg} `);
    }

    const ChangeRegs = (regis) => {
        GetPages(`${process.env.REACT_APP_API_SOPORTE}Tickets/Paginador?buscar=${Buscar === undefined ? '' : Buscar}&pagina=${1}&registrosPorPagina=${regis} `);
    }
    const handlePrimeraPg = () => {
        ProPrevPg(`${process.env.REACT_APP_API_SOPORTE}Tickets/Paginador?buscar=${Buscar === undefined ? '' : Buscar}&pagina=${1}&registrosPorPagina=${RegsPg === 0 ? 10 : RegsPg} `);
    }

    const handlePreviaPg = () => {
        PrevPg(`${process.env.REACT_APP_API_SOPORTE}Tickets/Paginador?buscar=${Buscar === undefined ? '' : Buscar}&pagina=${Pg - 1}&registrosPorPagina=${RegsPg === 0 ? 10 : RegsPg} `)
    }
    const handleNexPg = () => {
        NextPg(`${process.env.REACT_APP_API_SOPORTE}Tickets/Paginador?buscar=${Buscar === undefined ? '' : Buscar}&pagina=${Pg + 1}&registrosPorPagina=${RegsPg === 0 ? 10 : RegsPg} `)
    }
    const handleUltimaPg = () => {
        ProNextPg(`${process.env.REACT_APP_API_SOPORTE}Tickets/Paginador?buscar=${Buscar === undefined ? '' : Buscar}&pagina=${MaxPg}&registrosPorPagina=${RegsPg === 0 ? 10 : RegsPg} `)
    }


    const RenderID = ({ id }) => {
        return <span>{id}</span>;
    };

    const RenderNombre = ({ nombre }) => {
        return <span>{nombre}</span>;
    };



    const handleSeleccionarTicket = (ticket) => {
        setTicketSeleccionado(ticket);
    };


    const ChangeNombre = (data) => {
        setnuevoTicket(data);
    };

    const CleanNombre = () => {
        setnuevoTicket();
    };

    const closeModal = () => {
        setShowModal(false);
        CleanNombre();
        setDescripcion("");
        setFechaReporte(new Date().toISOString().split('T')[0]);
        setIdDepartamento(null);
        setidPrioridad(null);
        setIdProceso(null);
        setIdTipoProblema(null);
        setIdSucursal(null);
        setId(null);
        setTicketSeleccionado(null);
        setModoEdicion(false);
        setIdUsuarios(null);
        setIdUsuarios2(null);
    };



    const handleCreateTicket = async () => {
        if (!validateForm()) return;

        try {
            setLoading(true);
            const formattedFechaReporte = fechaReporte ? new Date(fechaReporte).toISOString().slice(0, 10) : '2024-11-05';

            const ticketData = {
                idUsuarioResuelve: Number(idUsuarios2),
                idUsuarioRegistro: Number(user.idusuario),
                descripcion: descripcion.trim(),
                idNivelPrioridad: isPriorityHigh ? 4 : 5,
                idProceso: Number(idProceso),
                idSucursal: Number(idSucursal),
                idSolicitante: Number(idUsuarios),
                idOrigen: Number(id),
                idTipoProblema: Number(idTipoProblema),
                fechaReporte: fechaReporte.trim(),
                idDepartamento: Number(idDepartamento)
            };

            console.log('Datos a enviar:', ticketData);

            const response = await axios.post(
                `${process.env.REACT_APP_API_SOPORTE}Tickets/Agregar/${user.idusuario}`,
                ticketData,
                {
                    headers: {
                        'Content-Type': 'application/json',
                    }
                }
            );

            if (response.status === 200 || response.status === 201) {
                Swal.fire('Éxito', 'El ticket ha sido creado correctamente', 'success');
                closeModal();
            }
        } catch (error) {
            console.error('Error completo:', error);
            let errorMessage = 'No se pudo crear el ticket. ';
            if (error.response?.data?.message) {
                errorMessage += error.response.data.message;
            } else {
                errorMessage += 'Por favor, verifique los datos e intente nuevamente.';
            }
            Swal.fire('Error', errorMessage, 'error');
        } finally {
            setLoading(false);
        }
    };


    // Función para obtener la fecha con hora actual en el formato requerido yyyy-MM-dd HH:mm:ss
    // const obtenerFechaConHora = (fecha) => {
    //     const horaActual = new Date().toTimeString().split(' ')[0]; // HH:mm:ss
    //     return `${fecha} ${horaActual}`; // Combina la fecha y la hora
    // };
    const obtenerFechaConHora = (fecha, hora) => {
        if (!fecha || !hora) {
            console.error("Faltan valores para fecha u hora");
            return null;
        }

        // Asegura que la hora tenga el formato HH:mm:ss
        const horaConSegundos = hora.includes(':') && hora.split(':').length === 2
            ? `${hora}:00` // Si falta :ss, se añade
            : hora;

        return `${fecha} ${horaConSegundos}`;
    };

    const [fechaReporte, setFechaReporte] = useState(() => {
        return new Date().toISOString().split('T')[0]; // yyyy-MM-dd
    });


    const [Horas, setHoras] = useState()
    const extraerFecha = (fechaConHora) => {
        // Asegura que no sea nulo o indefinido
        if (!fechaConHora) return new Date().toISOString().split('T')[0];
        return fechaConHora.split(' ')[0]; // Retorna solo la parte de la fecha (antes del espacio)
    };





    const convertirFecha = (fechaConHora) => {
        if (!fechaConHora) return null;
    
        // Detecta si incluye hora (formato "dd/MM/yyyy HH:mm:ss")
        if (fechaConHora.includes(" ")) {
            const [fecha, hora] = fechaConHora.split(" ");
            const [dia, mes, anio] = fecha.split("/");
    
            return {
                fechaFormateada: `${anio}-${mes.padStart(2, '0')}-${dia.padStart(2, '0')}`, // yyyy-MM-dd
                horaFormateada: hora, // Mantiene la hora original (HH:mm:ss)
            };
        }
    
        // Formato sin hora (solo "dd/MM/yyyy")
        const [dia, mes, anio] = fechaConHora.split("/");
        return {
            fechaFormateada: `${anio}-${mes.padStart(2, '0')}-${dia.padStart(2, '0')}`, // yyyy-MM-dd
            horaFormateada: "00:00:00", // Predeterminado si no hay hora
        };
    };
    

    // Función para abrir el modal
    const openModal = (origen) => {
        setShowModal(true);

        if (origen) {
            // Modo edición
            setModoEdicion(true);
            console.log("Fecha reporte original desde servicio:", origen.fechaReporte);

            // Actualizar estados con los valores del ticket seleccionado
            handleDescripcionChange(origen.descripcion);
            handleIdDepaertamentoChange(origen.idDepartamento);
            handleIdOrigenChange(origen.idOrigen);
            handleIdSucursalChange(origen.idSucursal);
            handleIdPrioridadChange(origen.idNivelPrioridad);
            handleIdTipoProblemaChange(origen.idTipoProblema);
            handleIdProcesoChange(origen.idProceso);
            handleIdUsuariosChange2(origen.idSolicitante);
            handleIdUsuariosChange(origen.idUsuarioResuelve);
            // Convertir la fecha y la hora
        const { fechaFormateada, horaFormateada } = convertirFecha(origen.fechaReporte);

        console.log("Fecha formateada para edición:", fechaFormateada);
        console.log("Hora formateada para edición:", horaFormateada);

        // Actualizar estados
        setFechaReporte(fechaFormateada); // Actualiza la fecha
        setHoras(horaFormateada); // Actualiza la hora
        setTicketSeleccionado(origen); // Configura el ticket seleccionado
        } else {
            // Modo agregar nuevo
            setModoEdicion(false);
            // Configurar la fecha actual
            const fechaActual = new Date().toISOString().split('T')[0]; // yyyy-MM-dd
            console.log("Fecha actual para nuevo ticket:", fechaActual);
            setFechaReporte(fechaActual); // Actualiza la fecha para creación
        }
    };


    // Función para guardar o editar el ticket
    const handlerGuardarTicket = async (
        isStatusOpen
    ) => {
        setLoading(true);

        try {
            // const fechaConHora = obtenerFechaConHora(fechaReporte);
            const fechaConHora = obtenerFechaConHora(fechaReporte, Horas);
            if (!fechaConHora) {
                Swal.fire('Error', 'La fecha y la hora son obligatorias.', 'error');
                return;
            }

            if (modoEdicion && ticketSeleccionado) {
                const datosModificados = {};
                if (typeof descripcion === 'string' && descripcion.trim() !== ticketSeleccionado.descripcion) {
                    datosModificados.descripcion = descripcion.trim();
                }
                if (idUsuarios !== ticketSeleccionado.idUsuarioResuelve) {
                    datosModificados.idUsuarioResuelve = idUsuarios;
                }
                if (idPrioridad !== ticketSeleccionado.idNivelPrioridad) {
                    datosModificados.idNivelPrioridad = idPrioridad;
                }
                if (idProceso !== ticketSeleccionado.idProceso) {
                    datosModificados.idProceso = idProceso;
                }
                if (idSucursal !== ticketSeleccionado.idSucursal) {
                    datosModificados.idSucursal = idSucursal;
                }
                if (idUsuarios2 !== ticketSeleccionado.idSolicitante) {
                    datosModificados.idSolicitante = idUsuarios2;
                }
                if (id !== ticketSeleccionado.idOrigen) {
                    datosModificados.idOrigen = id;
                }
                if (idTipoProblema !== ticketSeleccionado.idTipoProblema) {
                    datosModificados.idTipoProblema = idTipoProblema;
                }
                if (idDepartamento !== ticketSeleccionado.idDepartamento) {
                    datosModificados.idDepartamento = idDepartamento;
                }
                // if (fechaConHora !== ticketSeleccionado.fechaReporte) {
                //     datosModificados.fechaReporte = fechaConHora;
                // }

                if (fechaConHora !== ticketSeleccionado.fechaReporte) {
                    datosModificados.fechaReporte = fechaConHora;
                }

                // Verificar si hay cambios
                if (Object.keys(datosModificados).length === 0) {
                    Swal.fire('Atención', 'No se detectaron cambios en los campos.', 'info');
                    return;
                }

                // Realizar la solicitud de edición
                await ModificarTicket(user.idusuario, ticketSeleccionado.id, datosModificados);
                Swal.fire('Éxito', 'El ticket ha sido modificado correctamente.', 'success');
            } else {
                // En modo creación
                const nuevoTicket = {
                    idUsuarioResuelve: Number(idUsuarios2),
                    idUsuarioRegistro: Number(user.idusuario),
                    descripcion: descripcion.trim(),
                    idNivelPrioridad: Number(idPrioridad),
                    idProceso: Number(idProceso),
                    idSucursal: Number(idSucursal),
                    idSolicitante: Number(idUsuarios),
                    idOrigen: Number(id),
                    idTipoProblema: Number(idTipoProblema),
                    idDepartamento: Number(idDepartamento),
                    estatus: isStatusOpen,
                    fechaReporte: fechaConHora,

                };
                console.log("Datos a enviar nuevo Ticket: ", nuevoTicket)

                // Realizar la solicitud de creación
                await CrearTicket(nuevoTicket, user.idusuario);
                Swal.fire('Éxito', 'El nuevo ticket ha sido creado correctamente.', 'success');
            }

            // Actualizar la lista de tickets y cerrar el modal
            ObtenerTickets();
            closeModal();
        } catch (error) {
            console.error('Error al guardar el ticket:', error);
            Swal.fire('Error', 'Ocurrió un problema al procesar la solicitud. Por favor, intente de nuevo.', 'error');
        } finally {
            setLoading(false);
        }
    };


    // Funciones para crear y modificar tickets
    const CrearTicket = async (request, usuario) => {
        try {
            let configPetition = {
                method: 'post',
                url: `${process.env.REACT_APP_API_SOPORTE}Tickets/Agregar/${usuario}`,
                data: request,
                headers: {
                    'Content-Type': 'application/json'
                }
            }

            await axios(configPetition);
        } catch (error) {
            throw new Error('Error al crear el ticket');
        }
    };

    const ModificarTicket = async (idUsuario, idticket, datosModificados) => {
        try {
            await axios.put(
                `${process.env.REACT_APP_API_SOPORTE}Tickets/Actualizar/${idUsuario}/${idticket}`,
                datosModificados,
                { headers: { 'Content-Type': 'application/json' } }
            );
        } catch (error) {
            throw new Error('Error al modificar el ticket');
        }
    };


    const EditarTicket = async () => {
        if (!nuevoTicket || nuevoTicket.trim() === '') {
            Swal.fire('Error', 'El nombre del ticket no puede estar vacío.', 'error');
            return;
        }

        setLoading(true);
        try {
            await axios.put(`${process.env.REACT_APP_API_SOPORTE}Tickets/Actualizar/${ticketSeleccionado.idUsuario}/${ticketSeleccionado.id}`, {
                nombre: nuevoTicket.trim()
            });
            Swal.fire('Éxito', 'El ticket ha sido modificado correctamente.', 'success');
            setModoEdicion(false);
            setTicketSeleccionado(null);
            await PaginadorTickets();
        } catch (error) {
            console.error('Error al modificar el ticket: ', error);
            Swal.fire('Error', 'No se pudo modificar el ticket. Por favor, intenta de nuevo.', 'error');
        } finally {
            setLoading(false);
        }
    };


    const abrirModalEdicion = (ticket) => {
        setTicketSeleccionado(ticket);
        setnuevoTicket(ticket.nombre);
        setModoEdicion(true);
    };




    const PaginadorTickets = async () => {
        setLoading(true);
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API_SOPORTE}Tickets/Paginador?buscar=${''}&pagina=${Pg}&registrosPorPagina=${RegsPg === undefined ? '10' : RegsPg} `);

            setrespPaginador(response.data.resultado);
            setTotalPage(response.data.totalPage);
            // HanlderChangueResultados(response.data.TotalRegistros);
            return response.data.resultado;

        }
        catch (error) {
            console.error('Error al obtener paginador de origenes:', error);
        } finally {
            setLoading(false);
        }
    };

    const EliminarTicket = async (idticket, idUsuario) => {
        setLoading(true);
        try {
            await axios.delete(`${process.env.REACT_APP_API_SOPORTE}Tickets/Eliminar/${idticket}/${idUsuario}`, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            ObtenerTickets();
        } catch (error) {
            console.error('Error al dar de baja el ticket: ', error);
        } finally {
            setLoading(false);
        }
    };

    const HabilitarTicket = async (idUsuario, idticket, habilitar = true) => {
        setLoading(true);

        try {

            const data = {
                estatus: habilitar ? "A" : "I"  // "A" para habilitar, "I" para inhabilitar
            };
            await axios.put(
                `${process.env.REACT_APP_API_SOPORTE}Tickets/Actualizar/${idUsuario}/${idticket}`,
                data,
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );

            await ObtenerTickets();
        } catch (error) {
            console.error('Error al modificar el estatus del origen: ', error);
            Swal.fire({
                title: 'Error',
                text: 'No se pudo modificar el estatus del origen',
                icon: 'error'
            });
        } finally {
            setLoading(false);
        }
    };



    const [mostrarProyectos, setMostrarProyectos] = useState();
    const [error, setError] = useState([]);
    const [showSelect, setShowSelect] = useState(false);
    const [Pagina, setPagina] = useState();
    const [TotalPaginas, setTotalPaginas] = useState();
    const [TotalRegistros, setTotalRegistros] = useState();
    const [FormularioProyectos, setFormularioProyectos] = useState();
    const [loading, setLoading] = useState(true);
    const [showTable, setShowTable] = useState(false);

    //formulario de registro de proyectos


    const showDatosPoryectos = async (url, filtros) => {
        // setLoading(true);
        let configpost = {
            method: 'get',
            url: url,
            headers: {
                'Content-Type': 'application/json'
            },
            data: filtros
        };

        await axios(configpost)
            .then(response => {

                setLoading(false);

                // console.log(response.data);
                let RespuestaServicio = response.data.resultado;

                const NuevaRespuesta = RespuestaServicio.map(resp => {
                    const RehacerObjeto = { "id": resp.id, "descripcion": resp.nombre }
                    return RehacerObjeto
                })
                setFormularioProyectos(NuevaRespuesta);
                setMostrarProyectos(response.data.resultado);
                setPagina(response.data.paginaActual);
                setTotalPaginas(response.data.totalPaginas);
                setTotalRegistros(response.data.registrosPorPagina);
            })
            .catch(error => {
                setError(error);
            });
    }






    const ObtenerOrigen = async () => {
        setLoading(true);

        try {
            const response = await axios.get(`${process.env.REACT_APP_API_SOPORTE}Tickets/Obtener`);

            const respuestaServicio = response.data;

            const nuevaRespuesta = respuestaServicio.map(resp => ({
                id: resp.id,
                descripcion: resp.nombre
            }));

            setRespestaGetTicket(nuevaRespuesta);

            console.log("Datos obtenidos:", nuevaRespuesta);

        } catch (error) {
            console.error('Error al obtener ticket:', error);

        } finally {
            setLoading(false);
        }
    };



    // Estados para los filtros
    const [estatusFilter, setEstatusFilter] = useState('');
    const [prioridadFilter, setPrioridadFilter] = useState('');
    const [asignadoFilter, setAsignadoFilter] = useState('');
    const [solicitanteFilter, setSolicitanteFilter] = useState('');
    const [busquedaLocal, setBusquedaLocal] = useState('');

    // Estados para las opciones de los selects
    const [usuarios, setUsuarios] = useState([]);
    const [solicitantes, setSolicitantes] = useState([]);
    const [asignados, setAsignados] = useState([]); // Estado para usuarios asignados
    const [prioridades, setPrioridades] = useState([]); // Estado para prioridades
    const [estatuses, setEstatuses] = useState([]); // Nuevo estado para estatus únicos

    // Estado para los datos
    const [datos, setDatos] = useState([]); // Datos originales sin filtrar
    const [datosFiltrados, setDatosFiltrados] = useState([]); // Datos filtrados

    // Función para obtener usuarios, solicitantes, asignados, prioridades y estatus
    const obtenerOpciones = async () => {
        try {
            const responseUsuarios = await axios.get(`${process.env.REACT_APP_API_SOPORTE}Tickets/Obtener`);
            const responsePrioridades = await axios.get(`${process.env.REACT_APP_API_SOPORTE}NivelPrioridad/Obtener?idNivelPrioridad=0`);

            const usuariosData = Array.isArray(responseUsuarios.data) ? responseUsuarios.data : [];

            // Extraer solicitantes únicos
            const solicitantesData = usuariosData
                .filter((ticket) => ticket.usuarioSolicitante)
                .map((ticket) => ({
                    id: ticket.idSolicitante,
                    nombre: ticket.usuarioSolicitante
                }));
            const solicitantesUnicos = Array.from(new Map(solicitantesData.map(item => [item.id, item])).values());

            // Extraer usuarios asignados únicos (usuarioResuelve)
            const asignadosData = usuariosData
                .filter((ticket) => ticket.usuarioResuelve)
                .map((ticket) => ({
                    id: ticket.idUsuarioResuelve,
                    nombre: ticket.usuarioResuelve
                }));
            const asignadosUnicos = Array.from(new Map(asignadosData.map(item => [item.id, item])).values());

            // Extraer todos los valores únicos de estatus
            const estatusData = usuariosData.map((ticket) => ticket.estatus);
            const estatusUnicos = Array.from(new Set(estatusData));

            // Configurar los estados
            setUsuarios(usuariosData);
            setSolicitantes(solicitantesUnicos);
            setAsignados(asignadosUnicos);
            setPrioridades(Array.isArray(responsePrioridades.data) ? responsePrioridades.data : []);
            setEstatuses(estatusUnicos);

            // Guardar los datos originales para aplicar filtros
            setDatos(usuariosData);
            setDatosFiltrados(usuariosData); // Inicialmente, los datos filtrados son iguales a los originales

        } catch (error) {
            console.error('Error al obtener opciones:', error);
            setUsuarios([]);
            setSolicitantes([]);
            setAsignados([]);
            setPrioridades([]);
            setEstatuses([]);
            setDatos([]);
            setDatosFiltrados([]);
        }
    };

    useEffect(() => {
        obtenerOpciones();
    }, []);

    // Función para filtrar los datos
    const filtrarDatos = () => {
        const datosFiltrados = datos.filter(item => {
            const cumpleEstatus = !estatusFilter || item.estatus === estatusFilter;
            const cumplePrioridad = !prioridadFilter || item.nombreNivelPrioridad === prioridadFilter;
            const cumpleAsignado = !asignadoFilter || item.idUsuarioResuelve === Number(asignadoFilter);
            const cumpleSolicitante = !solicitanteFilter || item.idSolicitante === Number(solicitanteFilter);
            const cumpleBusqueda = !busquedaLocal || item.descripcion.toLowerCase().includes(busquedaLocal.toLowerCase());

            return cumpleEstatus && cumplePrioridad && cumpleAsignado && cumpleSolicitante && cumpleBusqueda;
        });

        setDatosFiltrados(datosFiltrados);
    };

    // Ejecutar filtrarDatos cada vez que los filtros cambien
    useEffect(() => {
        filtrarDatos();
    }, [estatusFilter, prioridadFilter, asignadoFilter, solicitanteFilter, busquedaLocal]);



    const ObtenerTicketsFiltros = (filtros = []) => {
        setLoading(true);

        try {
            // Codificar los filtros solo si se proporcionan
            const jsonFiltros = filtros.length > 0 ? encodeURIComponent(JSON.stringify(filtros)) : '';

            // Construir la URL dinámica
            const url = `${process.env.REACT_APP_API_SOPORTE}Tickets/Paginador?jsonfiltros=${jsonFiltros}&pagina=${Pg === 0 ? 1 : Pg}&registrosPorPagina=${RegsPg === 0 ? 10 : RegsPg}`;

            // Hacer la solicitud al servicio
            GetPages(url);
        } catch (error) {
            console.error("Error al obtener tickets con filtros:", error);
        } finally {
            setLoading(false);
        }
    };

    const filtrarPorUsuarioAsignado = async (idUsuarioAsignado) => {
        setLoading(true);

        try {
            // Crear el JSON de filtros
            const filtros = [
                {
                    tipofiltro: "usuarioAsignado",
                    valor: idUsuarioAsignado.toString() // Convertir a string
                }
            ];

            // Codificar el JSON
            const jsonFiltrosCodificado = encodeURIComponent(JSON.stringify(filtros));

            // Hacer la solicitud al endpoint con el filtro
            const response = await axios.get(
                `${process.env.REACT_APP_API_SOPORTE}Tickets/Paginador?jsonfiltros=${jsonFiltrosCodificado}&pagina=1&registrosPorPagina=10`
            );

            // Actualizar los datos filtrados
            if (response.data && Array.isArray(response.data.value)) {
                setDatosFiltrados(response.data.value);
            } else {
                console.error("No se encontraron resultados para los filtros");
                setDatosFiltrados([]);
            }
        } catch (error) {
            console.error("Error al filtrar por usuario asignado:", error);
            setDatosFiltrados([]);
        } finally {
            setLoading(false);
        }
    };


    const filtrarPorUsuarioSolicitante = async (idUsuarioSolicitante) => {
        setLoading(true);

        try {
            // Crear el JSON de filtros
            const filtros = [
                {
                    tipofiltro: "usuarioSolicitante",
                    valor: idUsuarioSolicitante.toString() // Convertir a string
                }
            ];

            // Codificar el JSON
            const jsonFiltrosCodificado = encodeURIComponent(JSON.stringify(filtros));

            // Hacer la solicitud al endpoint con el filtro
            const response = await axios.get(
                `${process.env.REACT_APP_API_SOPORTE}Tickets/Paginador?jsonfiltros=${jsonFiltrosCodificado}&pagina=1&registrosPorPagina=10`
            );

            // Actualizar los datos filtrados
            if (response.data && Array.isArray(response.data.value)) {
                setDatosFiltrados(response.data.value);
            } else {
                console.error("No se encontraron resultados para los filtros");
                setDatosFiltrados([]);
            }
        } catch (error) {
            console.error("Error al filtrar por usuario solicitante:", error);
            setDatosFiltrados([]);
        } finally {
            setLoading(false);
        }
    };

    const filtrarPorEstatus = async (estatus) => {
        setLoading(true);

        try {
            // Crear el JSON de filtros
            const filtros = [
                {
                    tipofiltro: "estatus",
                    valor: estatus // Pasar el valor "A" o "I"
                }
            ];

            // Codificar el JSON
            const jsonFiltrosCodificado = encodeURIComponent(JSON.stringify(filtros));

            // Hacer la solicitud al servicio con el filtro
            const response = await axios.get(
                `${process.env.REACT_APP_API_SOPORTE}Tickets/Paginador?jsonfiltros=${jsonFiltrosCodificado}&pagina=1&registrosPorPagina=10`
            );

            // Actualizar los datos filtrados
            if (response.data && Array.isArray(response.data.value)) {
                setDatosFiltrados(response.data.value);
            } else {
                console.error("No se encontraron resultados para los filtros");
                setDatosFiltrados([]);
            }
        } catch (error) {
            console.error("Error al filtrar por estatus:", error);
            setDatosFiltrados([]);
        } finally {
            setLoading(false);
        }
    };
    const filtrarPorPrioridad = async (nombrePrioridad) => {
        setLoading(true);

        try {
            // Crear el JSON de filtros
            const filtros = [
                {
                    tipofiltro: "nivelPrioridad",
                    valor: nombrePrioridad // Enviar el nombre de la prioridad ("ALTO", "BAJO", etc.)
                }
            ];

            // Codificar el JSON
            const jsonFiltrosCodificado = encodeURIComponent(JSON.stringify(filtros));

            // Hacer la solicitud al endpoint con el filtro
            const response = await axios.get(
                `${process.env.REACT_APP_API_SOPORTE}Tickets/Paginador?jsonfiltros=${jsonFiltrosCodificado}&pagina=1&registrosPorPagina=10`
            );

            // Actualizar los datos filtrados
            if (response.data && Array.isArray(response.data.value)) {
                setDatosFiltrados(response.data.value);
            } else {
                console.error("No se encontraron resultados para los filtros");
                setDatosFiltrados([]);
            }
        } catch (error) {
            console.error("Error al filtrar por nivel de prioridad:", error);
            setDatosFiltrados([]);
        } finally {
            setLoading(false);
        }
    };


    const obtenerPrioridades = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_SOPORTE}NivelPrioridad/Obtener?idNivelPrioridad=0`);
            if (response.data && Array.isArray(response.data)) {
                setPrioridades(response.data); // Guardar las prioridades en el estado
            } else {
                console.error("No se encontraron niveles de prioridad");
                setPrioridades([]);
            }
        } catch (error) {
            console.error("Error al obtener niveles de prioridad:", error);
            setPrioridades([]);
        }
    };

    // Llama a `obtenerPrioridades` al montar el componente
    useEffect(() => {
        obtenerPrioridades();
    }, []);



    return {
        ChangeNombre,
        nuevoTicket,
        CrearTicket,
        CleanNombre,
        respPaginador,
        PaginadorTickets,
        EliminarTicket,
        ModificarTicket,
        ObtenerOrigen,
        handlerGuardarTicket,
        showModal,
        openModal,
        modoEdicion,
        closeModal,
        user,
        page,
        totalPage,
        TotalRegistros,
        Buscar,
        ObtenerTickets,
        handlePrimeraPg,
        handlePreviaPg,
        handleNexPg,
        handleUltimaPg,
        InfoTab,
        ChangeRegs,
        RegsPg,
        setRegsPg,
        Pg,
        MaxPg,
        RespestaGetTicket,
        HabilitarTicket,
        ObtenerTickets2,
        ObtenerTickets222,
        ObtenerTickets33,
        mostrarProyectos,
        showDatosPoryectos,
        showSelect,
        setShowSelect,
        loading,
        TotalPaginas,
        Pagina,
        setPagina,
        FormularioProyectos,
        EditarTicket,
        abrirModalEdicion,
        showTable,
        setShowTable,
        setLoading,
        handleSeleccionarTicket,
        ticketSeleccionado,
        handleIdUsuariosChange,
        ObtenerUser2,
        RespuestaUsuarios,
        ObtenerOrigenes,
        RespuestaOrigen,
        ObtenerTipoProblema,
        RespuestaTipoProblema,
        ObtenerSucursal,
        RespuestaSucursal,
        ObtenerProceso,
        RespuestaProceso,
        ObtenerUser22,
        idUsuarios2,
        RespuestaUsuarios2,
        descripcion,
        idTipoProblema,
        idUsuarios,
        id,
        isPriorityHigh,
        idSucursal,
        idProceso,
        // Manejadores
        handleDescripcionChange,
        handleIdTipoProblemaChange,
        handleIdUsuariosChange2,
        handleIdOrigenChange,
        handleIdSucursalChange,
        handleIdProcesoChange,
        setIsPriorityHigh,
        handleCreateTicket,
        estatusFilter,
        prioridadFilter,
        asignadoFilter,
        solicitanteFilter,
        busquedaLocal,
        usuarios,
        solicitantes,
        setEstatusFilter,
        setPrioridadFilter,
        setAsignadoFilter,
        setSolicitanteFilter,
        setBusquedaLocal,
        prioridades,
        asignados,
        estatuses,
        filtrarPorUsuarioAsignado,
        ObtenerTicketsFiltros,
        filtrarPorUsuarioSolicitante,
        filtrarPorEstatus,
        filtrarPorPrioridad,
        ObtenerNivelPrioridad,
        RespuestaPrioridad,
        handleIdPrioridadChange,
        idPrioridad,
        ObtenerDepartamento,
        idDepartamento,
        handleIdDepaertamentoChange,
        RespuestaDepartamento,
        fechaReporte,
        setFechaReporte,
        setIdTipoProblema,
        setidPrioridad,
        Horas,
        setHoras
    };
};

export default useTicket;