import styled from 'styled-components';
const StyledItemSubMenu = styled.div`

.StyleOption{
    width: 5px;
    height: 5px;
    
  background-color: grey;
  border-radius: 100%;
}

.StyleOption:nth-child(1){
position: absolute;
top:6px;
right: 13px;

}
.StyleOption:nth-child(2){
position: absolute;
top:13px;
right: 13px;
}
.StyleOption:nth-child(3){
position: absolute;
top:19px;
right: 13px;
}

.SubMenu{
   
    width: 30px;
    height: 30px;
    border-radius: 100%;
    position: absolute;
    right: 0px;
    bottom: 7px;

}
.containerMenu{
    display: flex;
    flex-direction: column !important;
    position: absolute !important;
    right: 25px;
    background: #DBDDED !important;
    border-radius: 8px;
    z-index: 2;
    box-shadow: -1px 7px 17px rgb(0 0 0 / 35%);    
    border: solid #D4D3D3 1px;
    color: #666770;
    font-weight: bold;
    cursor: pointer;
    width: 150px;
}


`
const MenuOption = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer; 
    padding-left: 5px;
    height: 49px;


:hover{
    background-color: #bfc1d4;
    border-radius: 8px ;
}
.p{
    text-align: left;
    max-width: 80%;
    padding-left: 5px;
}
.img{
    width: 30px;
}
`;
export { StyledItemSubMenu, MenuOption }

