import styled from 'styled-components';

const StyledBitacoraSoporte = styled.div`
.contenedor-principal{
display: flex;
flex-direction: column-reverse;

}

.tabla-catalogo{
width:100%;
margin-top: -28px;
}
.opcionSeguimiento{
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    gap: 1rem;

}

.opcionSeguimiento:hover{
    background: var(--HeaderTabla);
    color: var(--Blanco);
    border-radius: 5px;

}
.SelectOptions{
    color: rgb(51, 51, 51);
    font-size: 16px;
    padding: 4px;
    width: 100%;
    outline: none;
    border-radius: 7px;
    border: 2px solid rgb(102, 103, 112);
    border-radius: 7px;
}



.boton-tabla{
    justify-content: space-between;
    display: flex;
    
}

.seleccionarOpciones img{
    width: 2rem;
    padding: 5px;
}
.seleccionarOpciones{
    width: 2rem;
}
.boton-tabla button{
    border-radius: 100%;
    border: 0;
    background: #2F3768;
    height: 50px;
    width: 50px;
    color: #ffff;
    font-size: 30px;
    font-weight: 600;
}
/* .inputOrigen input{
   border-radius: 5px;
   color:#C0C0C0; 
   width: 50%;
   height: 20%;
    margin: 20px;
    padding: 5px;
   border-color: none;
   font-size: 20px;
   display: flex;
} */
.botonAccion{
    margin: 15px;
    margin-top: 5px;
}
.contenedorBotonAccion{
    display: flex;
    justify-content: center;
    margin-left: 40px;
}
.BotonesFecha{
    display: flex;
    flex-direction: row;
    /*padding: 10px;*/
        /* arriba | derecha | abajo | izquierda */
    padding: 9px 0px 10px 0px;
    width: 97%;
   
}


.BotonAsignado{
    display: flex;
    flex-direction: row;
    padding: 8px;
    width: 94%;
  
}
.Descripcion{
    display: flex;
    flex-direction: row;
    padding: 8px;
    width: 94%;
}
.DivSwitch{
     display: flex;
    flex-direction: row;
    justify-content: end;
    padding: 8px;
    width: 94%;

}
.botonAccion{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding-left: 0px;
   
}
.contentainer{
    position: relative;
    flex:1;
    /* height: 66px; */
}
.inputOrigen{
    width: 98%;
    padding-left:0px;
    border-radius: 5px;
   height: 20%;
    margin-left: 42px;
    padding: 12px;
   border-color: none;
   font-size: 20px;
   
}

.identificador{

    margin: 0px;
    grid-area: Texto;
    width: 80%;
    padding-left:25px;
    border-radius: 5px;
   height: 20%;
    margin-left: 20px;
    padding: 15px;
   border-color: none;
   font-size: 20px;
}
@media only screen and (min-width: 300px) {
    .contenedor-principal{
display: flex;
flex-direction: column-reverse;

}


.contenedorPaginador{
    display: flex;
    flex-direction: column;
   //background-color: #D60000;
}

.Paginacion{
    //background-color: #2F3768;
    justify-content: space-between;
    width: 100%;
   // margin-top: 4rem;
    align-items: center;
}

.tabla-catalogo{
width:100%;
margin-top: -28px;
}
.opcionSeguimiento{
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    gap: 1rem;

}

.opcionSeguimiento:hover{
    background: var(--HeaderTabla);
    color: var(--Blanco);
    border-radius: 5px;

}
.SelectOptions{
    color: rgb(51, 51, 51);
    font-size: 16px;
    padding: 4px;
    width: 100%;
    outline: none;
    border-radius: 7px;
    border: 2px solid rgb(102, 103, 112);
    border-radius: 7px;
}



.boton-tabla{
    justify-content: space-between;
    display: flex;
    
}

.seleccionarOpciones img{
    width: 2rem;
    padding: 5px;
}
.seleccionarOpciones{
    width: 2rem;
}
.boton-tabla button{
    border-radius: 100%;
    border: 0;
    background: #2F3768;
    height: 50px;
    width: 50px;
    color: #ffff;
    font-size: 30px;
    font-weight: 600;
}
/* .inputOrigen input{
   border-radius: 5px;
   color:#C0C0C0; 
   width: 50%;
   height: 20%;
    margin: 20px;
    padding: 5px;
   border-color: none;
   font-size: 20px;
   display: flex;
} */
.botonAccion{
    margin: 15px;
    margin-top: 5px;
}
.contenedorBotonAccion{
    display: flex;
    justify-content: center;
}
.BotonesFecha{
    display: flex;
    flex-direction: row;
    /*padding: 10px;*/
        /* arriba | derecha | abajo | izquierda */
    padding: 9px 0px 10px 0px;
    width: 97%;
   
}


.BotonAsignado{
    display: flex;
    flex-direction: row;
    padding: 8px;
    width: 94%;
  
}
.Descripcion{
    display: flex;
    flex-direction: row;
    padding: 8px;
    width: 94%;
}
.DivSwitch{
     display: flex;
    flex-direction: row;
    justify-content: end;
    padding: 8px;
    width: 94%;

}
.botonAccion{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding-left: 0px;
   
}
.contentainer{
    position: relative;
    flex:1;
    /* height: 66px; */
}
.inputOrigen{
    width: 98%;
    padding-left:20px;
    border-radius: 5px;
   height: 20%;
    margin-left: 42px;
    padding: 12px;
   border-color: none;
   font-size: 20px;
   
}

.identificador{

    margin: 0px;
    grid-area: Texto;
    width: 80%;
    padding-left:25px;
    border-radius: 5px;
   height: 20%;
    margin-left: 20px;
    padding: 15px;
   border-color: none;
   font-size: 20px;
}
}
// / Small devices (portrait tablets and large phones, 600px and up) /
@media only screen and (min-width: 600px) {
    .contenedor-principal{
display: flex;
flex-direction: column-reverse;

}

.tabla-catalogo{
width:100%;
margin-top: -28px;
}
.opcionSeguimiento{
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    gap: 1rem;

}

.opcionSeguimiento:hover{
    background: var(--HeaderTabla);
    color: var(--Blanco);
    border-radius: 5px;

}
.SelectOptions{
    color: rgb(51, 51, 51);
    font-size: 16px;
    padding: 4px;
    width: 100%;
    outline: none;
    border-radius: 7px;
    border: 2px solid rgb(102, 103, 112);
    border-radius: 7px;
}



.boton-tabla{
    justify-content: space-between;
    display: flex;
    
}

.seleccionarOpciones img{
    width: 2rem;
    padding: 5px;
}
.seleccionarOpciones{
    width: 2rem;
}
.boton-tabla button{
    border-radius: 100%;
    border: 0;
    background: #2F3768;
    height: 50px;
    width: 50px;
    color: #ffff;
    font-size: 30px;
    font-weight: 600;
}
/* .inputOrigen input{
   border-radius: 5px;
   color:#C0C0C0; 
   width: 50%;
   height: 20%;
    margin: 20px;
    padding: 5px;
   border-color: none;
   font-size: 20px;
   display: flex;
} */
.botonAccion{
    margin: 15px;
    margin-top: 5px;
}
.contenedorBotonAccion{
    display: flex;
    justify-content: center;
}
.BotonesFecha{
    display: flex;
    flex-direction: row;
    /*padding: 10px;*/
        /* arriba | derecha | abajo | izquierda */
    padding: 9px 0px 10px 0px;
    width: 97%;
   
}


.BotonAsignado{
    display: flex;
    flex-direction: row;
    padding: 8px;
    width: 94%;
  
}
.Descripcion{
    display: flex;
    flex-direction: row;
    padding: 8px;
    width: 94%;
}
.DivSwitch{
     display: flex;
    flex-direction: row;
    justify-content: end;
    padding: 8px;
    width: 94%;

}
.botonAccion{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding-left: 0px;
   
}
.contentainer{
    position: relative;
    flex:1;
    /* height: 66px; */
}
.inputOrigen{
    width: 89%;
    padding-left:20px;
    border-radius: 5px;
   height: 20%;
    margin-left: 28px;
    padding: 12px;
   border-color: none;
   font-size: 20px;
   
}

.identificador{

    margin: 0px;
    grid-area: Texto;
    width: 80%;
    padding-left:25px;
    border-radius: 5px;
   height: 20%;
    margin-left: 20px;
    padding: 15px;
   border-color: none;
   font-size: 20px;
}
}

// / Medium devices (landscape tablets, 768px and up) /
@media only screen and (min-width: 768px) {
}

// / Large devices (laptops/desktops, 992px and up) /
@media only screen and (min-width: 992px) {
}

// / Extra large devices (large laptops and desktops, 1366px and up) /
@media only screen and (min-width: 1366px) {
}

`
export { StyledBitacoraSoporte }