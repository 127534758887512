import styled from 'styled-components';

const StyledInputNuevo = styled.div`
/* display: flex;
flex-direction: column;
align-items: flex-start;
justify-content: center;
width: 100%; */


.InptBtn-Distr{
    display: grid; 
    grid-auto-columns: 1fr; 
    grid-template-columns: 1.8fr 0.2fr;
    grid-template-rows: 0.6fr 1.4fr;
    gap: 0px 0px; 
    grid-template-areas: 
        "Texto ."
        "Input Boton"; 
}



.OnlyInpt-Distr{
    width: 100%
}

.Titul-core{
    margin: 0;
    grid-area: Texto;
}

.Inpt-core {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.Inpt-Btn {
    grid-area: Input;
    outline: none;
    border: 2px solid #666770;
    border-radius: 4px 0px 0px 4px;
    color: #333333;
    font-size: 16px;
    padding: 4px;
    width: 100%;
    /* outline: none; */
    box-sizing: border-box;
}


.Inpt {
    border: 2px solid #666770;
    border-radius: 4px;
    color: #333333;
    font-size: 16px;
    padding: 4px;
    width: 100%;
    /* outline: none; */
    box-sizing: border-box;
}

/* .InptSlct-Distr {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
} */

.Slct{
    border: 2px solid #666770;
    border-radius: 4px;
    color: #333333;
    font-size: 16px;
    padding: 4px;
    width: 100%;
}

input.date::-webkit-calendar-picker-indicator {
    opacity: 0.6;
}

.Btn-Show{
    grid-area: Boton;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0px 4px 4px 0px;

    border: 2px solid #666770;
    border-left: none;
}


.Btn-Hide{
    display: none;
}


`
export { StyledInputNuevo }