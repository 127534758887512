
import React, { useRef, useState, useEffect } from 'react';
import { StyledBitacoraSoporte } from '../themes/StyledBitacoraSoporte';
import { StyledActividades } from '../themes/StyledActividades';
import Modal from '../Componentes/Modal';
import InputPKT1 from '../Componentes/InputPKT1';
import BotonesAcciones from '../Componentes/BotonesAcciones';
import TablaPaginador from '../Componentes/TablaPaginador';
import useProcesos from '../hooks/useProcesos';
import ItemSubMenu from '../Componentes/ItemSubMenu';
import Editar from '../assets/svg/EditarNew.svg';
import Eliminar from '../assets/svg/eliminarNew.svg'
import Loader from '../Componentes/Loader';
import TablaPkt1 from '../Componentes/TablaPKT1'
import Activar from '../assets/svg/activar.svg'
import Agregar from '../Componentes/Agregar'
import Add from '../assets/svg/add.svg'
import Buscarimg from '../assets/svg/buscar.svg'
import Swal from 'sweetalert2';

const CatalagoSoporte = (props) => {
    const Tablapaginador = useRef();
    const {
        ChangeNombre,
        nuevoProceso,
        CrearProceso,
        CleanNombre,
        respPaginador,
        PaginadorProcesos,
        EliminarProceso,
        ModificarProceso,
        ObtenerProceso,
        handlerGuardarProceso,
        showModal,
        openModal,
        modoEdicion,
        closeModal,
        user,
        page,
        totalPage,
        Buscar,
        ObtenerProcesos,
        handlePrimeraPg,
        handlePreviaPg,
        handleNexPg,
        handleUltimaPg,
        InfoTab,
        ChangeRegs,
        RegsPg,
        setRegsPg,
        Pg,
        MaxPg,
        RespuestaGetProceso,
        HabilitarProceso,
        ObtenerProcesos2,
        ObtenerProcesos222,
        ObtenerProcesos33,
        mostrarProyectos,
        showDatosPoryectos,
        showSelect,
        setShowSelect,
        loading,
        TotalPaginas,
        TotalRegistros,
        Pagina,
        setPagina,
        FormularioProyectos,
        EditarProceso,
        abrirModalEdicion,
        ObtenerUser2,
        RespuestaDepartamentos,
        handleNombreChange,
        nombreProceso,
        handleIdDepartamentoChange,
        iddepartamento,
        handleInputChange,
        setLoading,
        setShowTable,
        showTable,
        procesoSeleccionado

    } = useProcesos();


    useEffect(() => {
        ObtenerProcesos()
        ObtenerUser2()
        ObtenerProceso()

    }, []);
    const RenderID = ({ id }) => {
        return <span>{id}</span>;
    };

    const RenderNombre = ({ nombre }) => {
        return <span>{nombre}</span>;
    };


    const [buscar, setBuscar] = useState();
    const [busquedaLocal, setBusquedaLocal] = useState('');
    const handleOptionClick = (label, proceso) => {
        if (label === 'Editar') {
            openModal(proceso);
        } else if (label === 'Deshabilitar') {
            Swal.fire({
                title: '¿Estás seguro que deseas deshabilitar este proceso?',
                text: `proceso: ${proceso.id} - ${proceso.nombre}`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3BAF2A',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Sí, deshabilitar',
                cancelButtonText: 'Cancelar'
            }).then((result) => {
                if (result.isConfirmed) {
                    EliminarProceso(proceso.id, user.idusuario, false);
                    ObtenerProcesos();
                }
            });
        } else if (label === 'Habilitar') {
            HabilitarProceso(proceso.id, user.idusuario, true);
            ObtenerProcesos();
        }
    };


    const Buscador2 = (e) => {
        const valor = e.target.value;
        setBusquedaLocal(valor);
        clearTimeout(window.searchTimeout);
        window.searchTimeout = setTimeout(() => {
            if (valor.trim() === '') {
                ObtenerProcesos33();
            } else {
                ObtenerProcesos222(valor);
            }
        }, 200);
    };

    const renderItemCabeceraTablaPendientes = () => (
        <>
            <div className='ItemHeader-TablaPaginador' style={{ flex: 1 }}>
                <p>ID</p>
            </div>
            <div className='ItemHeader-TablaPaginador' style={{ flex: 1 }}>
                <p>Nombre</p>
            </div>
            <div className='ItemHeader-TablaPaginador' style={{ flex: 1 }}>
                <p>Departamento asignado</p>
            </div>
            <div className='ItemHeader-TablaPaginador' style={{ flex: 1 }}>
                <p>Estatus</p>
            </div>
        </>
    );
    const renderItemCuerpoTablaPendientes = () => (
        <>
            {InfoTab?.map(resp => {
                const submenuOptions = resp.estatus === 'A' ? [
                    { label: 'Editar', image: Editar },
                    { label: 'Deshabilitar', image: Eliminar }
                ] : [
                    { label: 'Habilitar', image: Activar }
                ];

                const departamentoAsociado = RespuestaDepartamentos.find(dep => dep.iddepartamento === resp.iddepartamento);

                return (
                    <div className="cuerpoPendientes" key={resp.id}>
                        <div className="Cuerpo-TablaSinPaginador" style={{ height: '50px' }}>
                            <div className='ItemCuerpo-Tablapaginador' style={{ flex: 1, paddingTop: '0px' }}>
                                <p style={{ margin: '0px' }}>{resp.id}</p>
                            </div>
                            <div className='ItemCuerpo-Tablapaginador' style={{ flex: 1, padding: '0px' }}>
                                <p>{resp.nombre}</p>
                            </div>
                            <div className='ItemCuerpo-Tablapaginador' style={{ flex: 1, padding: '0px' }}>
                                <p>{departamentoAsociado ? departamentoAsociado.nombre : 'Departamento no asignado'}</p>
                            </div>
                            <div className='ItemHeader-TablaPaginador' style={{ flex: 1, padding: '0px', textAlign: 'center' }}>
                                <p style={resp.estatus === 'A'
                                    ? {
                                        color: "#ffff",
                                        backgroundColor: '#3baf2a',
                                        borderRadius: '20px',
                                        width: '45%',
                                        margin: 'auto',
                                        padding: '8px 0px'
                                    }
                                    : {
                                        color: "white",
                                        backgroundColor: '#ec1c1c',
                                        borderRadius: '20px',
                                        width: '45%',
                                        margin: 'auto',
                                        padding: '8px 0px'
                                    }
                                }>
                                    {resp.estatus === "A" ? "Activo" : "Inactivo"}
                                </p>
                                <div className='contentainer'>
                                    <ItemSubMenu
                                        options={submenuOptions}
                                        onOptionClick={(label) => handleOptionClick(label, resp)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                );
            })}
        </>
    );




    return (
        <StyledBitacoraSoporte>
            <div className='contenedor-principal'>
                <div className='tabla-catalogo'>
                {loading ? (
                        <div className='PositionLoader'>
                            <Loader />
                        </div>
                    ) :
                    <TablaPaginador
                        Cabezera={renderItemCabeceraTablaPendientes}
                        Cuerpo={renderItemCuerpoTablaPendientes}
                        identificador={Tablapaginador}
                        ProPrevPg={handlePrimeraPg}
                        PrevPg={handlePreviaPg}
                        NextPg={handleNexPg}
                        ProNextPg={handleUltimaPg}
                        Pg={Pg}
                        MaxPg={MaxPg}
                        RegsPg={RegsPg}
                        setRegsPg={setRegsPg}
                        ChangeRegs={ChangeRegs}
                        InfoTab={InfoTab}
                    />
                
               }
                </div>
                { }
                <StyledActividades>
                    <div className='acomodo'>
                        <div className='ContenedorFiltros'>
                            <InputPKT1
                                label={"Buscar"}
                                placeholder={"proceso"}
                                type={"search"}
                                mostrar={true}
                                background={"rgb(58, 147, 56)"}
                                BtnImg={Buscarimg}
                                Accion={Buscador2}
                                value={busquedaLocal}
                            />
                        </div>
                        <div className='ContenedorAgregar'>
                            <Agregar IconoAgregar={Add} Accion={() => openModal()} />
                        </div>
                    </div>
                </StyledActividades>

            </div>



            <Modal
                estado={showModal}
                titulo={modoEdicion ? "EDITAR PROCESO" : "NUEVO PROCESO"}
                cambiarEstado={closeModal}
            >
                <div>
                <div className='identificador'>
                        {procesoSeleccionado && (
                            <div style={{marginLeft:'20px'}}>
                                <p>Editando el proceso: <RenderID id={procesoSeleccionado.id} /> <RenderNombre nombre={procesoSeleccionado.nombre}/>   </p>
                            </div>
                        )}
                    </div>
                    <div className='inputOrigen'>
                        <br></br>
                        <InputPKT1
                            label={"Nombre del proceso"}
                            type={"text"}
                            mostrar={"none"}
                            desactivar={false}
                            Accion={(e) => handleNombreChange(e.target.value)}
                            value={nombreProceso}
                            size={30}
                        />

                        
<br></br>
                        Departamento Seleccionado
                        <select
                            className='SelectOptions'
                            value={iddepartamento}
                            onChange={(e) => handleIdDepartamentoChange(e.target.value)}
                            style={{ width: '100%', padding: '10px', marginBottom: '10px' }}
                        >
                            <option value="">Seleccione un deparatamento</option>
                            {RespuestaDepartamentos && RespuestaDepartamentos.map((departamento) => (
                                <option key={departamento.iddepartamento} value={departamento.iddepartamento}>
                                    {departamento.nombre}
                                </option>
                            ))}
                        </select>

                    </div>

                    <div className='botonAccion'>
                        <BotonesAcciones
                            accion={modoEdicion ? "Guardar cambios" : "Guardar y enviar"}
                            colorFondo={"#2D8520"}
                            colorLetra={"#FFF"}
                            clickBoton={handlerGuardarProceso}
                        />
                    </div>
                </div>
            </Modal>

        </StyledBitacoraSoporte>
    );
};
export default CatalagoSoporte;

