import React, { useEffect, useRef, useState } from 'react'
import { StyletablaPaginador } from '../themes/StyleTablaPaginador'
import primero from '../assets/svg/primero.svg'
import segundo from '../assets/svg/segundo.svg';
import penultimo from '../assets/svg/penultimo.svg';
import ultimo from '../assets/svg/ultimo.svg';
import InputNuevo from './InputNuevo';
// import InputComponent from '../../Inputs/InputBuscador';


const TablaPaginador = (props) => {

  //   const { Darkmode } = useMode()
  const FiltroRegistros = [
    {
        "id" : 10,
        "descripcion" : 10
    },
    {
        "id" : 25,
        "descripcion" : 25
    },
    {
        "id" : 100,
        "descripcion" : 100
    },
];

  return (
    <div>
      <StyletablaPaginador>
        <div className='ContainerTablaBodyP'
          ref={props.identificador}
        >
          <div style={props.modal == true ? { display: 'none' } : { background: 'transparent', boxShadow: '-1px 9px 1px rgba(0, 0, 0, 0.35)' }}>
            <h3>{props.Titulo}</h3>
          </div>
          <div className='Cabezera-TablaPaginador'>{props?.Cabezera()}</div>
            {
            props.InfoTab === undefined
            ? 
              <>
              <div style={{ textAlign: 'center', padding: '20px', color: '#888', backgroundColor:'white' }}>
               No hay datos que mostrar
              </div>

              </> 
            : 
              <>
                <div className='Cuerpoprincipal' >{props?.Cuerpo()}</div>
              </>
            }
          
        </div>
     
        <div className="contenedorPaginador">
          <div>
            {/* <InputComponent
              nombre={"Estatus:"}
              tipo={"Select"}
              flex={1}
            ></InputComponent> 
            //estilos en el páginador de abajo llamado paginador select 
            style={{ display: 'flex', flexDirection: 'row', height: '45px' }}
            
            */}
            <div className="PaginadorSelect" >
              <InputNuevo
                type={'select'}
                value={props.RegsPg}
                setValue={props.ChangeRegs}
                // metodo={props.ChangeRegs}
                tittle={'Registros:'}
                opciones={[
                  { "key": 10, "description": 10 },
                  { "key": 15, "description": 15 },
                  { "key": 20, "description": 20 }
                ]}
              />
           </div>
          </div>
          <div className="paginador2">

            <div className="Paginacion">

              <div className="prev-plus"
                onClick={() => props.ProPrevPg()}
                style={
                  props.Pg <= 1
                    ? { background: "#9597a73d", cursor: "unset" }
                    : {}
                }
              >
                <img draggable={false} src={segundo} alt="" />
                <img draggable={false} src={segundo} alt="" />
              </div>

              <div className="prev"
                onClick={() => props.PrevPg(props.Pg)}
                style={
                  props.Pg <= 1
                    ? { background: "#9597a73d", cursor: "unset" }
                    : {}
                }
              >
                <img draggable={false} src={segundo} alt="" />
              </div>

              <div className="pg">{`${props.Pg} / ${props.MaxPg}`}</div>

              <div className="next"
                onClick={() => props.NextPg(props.Pg)}
                style={
                  props.Pg === props.MaxPg
                    ? { background: "#9597a73d", cursor: "unset" }
                    : {}
                }
              >
                <img draggable={false} src={penultimo} alt="" />
              </div>

              <div className="next-plus"
                onClick={() => props.ProNextPg()}
                style={
                  props.Pg === props.MaxPg
                    ? { background: "#9597a73d", cursor: "unset" }
                    : {}
                }
              >
                <img draggable={false} src={penultimo} alt="" />
                <img draggable={false} src={penultimo} alt="" />
              </div>

            </div>
          </div>
        </div>


      </StyletablaPaginador>

    </div>
  )
}

export default TablaPaginador