import React, { useRef, useState, useEffect } from 'react';
import { StyledBitacoraSoporte } from '../themes/StyledBitacoraSoporte';
import { StyledActividades } from '../themes/StyledActividades';
import Modal from '../Componentes/Modal';
import InputPKT1 from '../Componentes/InputPKT1';
import SelectPKT1 from '../Componentes/SelectPKT1';
import SwitchtBotones from '../Componentes/SwitchBotones'
import InputText from '../Componentes/InputText'
import StatusSwitches from '../hooks/StatusSwitches';
import BotonesAcciones from '../Componentes/BotonesAcciones';
import TablaPaginador from '../Componentes/TablaPaginador';
import ItemSubMenu from '../Componentes/ItemSubMenu';
import Editar from '../assets/svg/EditarNew.svg';
import Eliminar from '../assets/svg/eliminarNew.svg'
import lista from '../assets/svg/lista.svg'
import { useSeguimiento } from '../hooks/useSeguimiento'
import Loader from '../Componentes/Loader';
import TablaPkt1 from '../Componentes/TablaPKT1'
import Activar from '../assets/svg/activar.svg'
import Agregar from '../Componentes/Agregar'
import Add from '../assets/svg/add.svg'
import Buscarimg from '../assets/svg/buscar.svg'
import Swal from 'sweetalert2';
import useTicket from '../hooks/useTicket';
import seguimiento from '../assets/svg/tiempo-rapido.svg'
import { useLocalStorage } from '../hooks/useLocalStorage'
import { useNavigate } from 'react-router-dom'


const Soportes = (props) => {
    const Tablapaginador = useRef();
    const { SeguimientoporTicket } = useSeguimiento();
    const {
        ChangeNombre,
        nuevoTicket,
        CrearTicket,
        CleanNombre,
        respPaginador,
        PaginadorTickets,
        EliminarTicket,
        ModificarTicket,
        ObtenerOrigen,
        handlerGuardarTicket,
        showModal,
        openModal,
        modoEdicion,
        closeModal,
        user,
        loading,
        page,
        totalPage,
        TotalRegistros,
        Buscar,
        ObtenerTickets,
        handlePrimeraPg,
        handlePreviaPg,
        handleNexPg,
        handleUltimaPg,
        InfoTab,
        ChangeRegs,
        RegsPg,
        setRegsPg,
        Pg,
        MaxPg,
        RespestaGetTicket,
        HabilitarTicket,
        ObtenerTickets2,
        ObtenerTickets222,
        ObtenerTickets33,
        mostrarProyectos,
        showDatosPoryectos,
        showSelect,
        setShowSelect,
        ObtenerUser2,
        TotalPaginas,
        Pagina,
        setPagina,
        FormularioProyectos,
        EditarTicket,
        abrirModalEdicion,
        showTable,
        setShowTable,
        setLoading,
        handleSeleccionarTicket,
        ticketSeleccionado,
        handleIdUsuariosChange,
        idUsuarios,
        RespuestaUsuarios,


        handleIdOrigenChange,
        ObtenerOrigenes,
        id,
        RespuestaOrigen,


        ObtenerTipoProblema,
        handleIdTipoProblemaChange,
        RespuestaTipoProblema,
        idTipoProblema,


        handleDescripcionChange,
        descripcion,

        ObtenerSucursal,
        handleIdSucursalChange,
        RespuestaSucursal,
        idSucursal,

        ObtenerProceso,
        handleIdProcesoChange,
        RespuestaProceso,
        idProceso,


        handleIdUsuariosChange2,
        ObtenerUser22,
        idUsuarios2,
        RespuestaUsuarios2,

        // Handlers

        handleCreateTicket,

        estatusFilter,
        prioridadFilter,
        asignadoFilter,
        solicitanteFilter,

        usuarios,
        solicitantes,
        setEstatusFilter,
        setPrioridadFilter,
        setAsignadoFilter,
        setSolicitanteFilter,

        filtrarDatos,
        prioridades,
        asignados,
        setFechaReporte,
        fechaReporte,

        //filtros 
        filtrarPorUsuarioAsignado,
        ObtenerTicketsFiltros,
        filtrarPorUsuarioSolicitante,
        filtrarPorEstatus,
        filtrarPorPrioridad,
        ObtenerNivelPrioridad,
        RespuestaPrioridad,
        handleIdPrioridadChange,
        idPrioridad,

        ObtenerDepartamento,
        idDepartamento,
        handleIdDepaertamentoChange,
        RespuestaDepartamento,
        setIdTipoProblema,
        setidPrioridad,
        Horas,
        setHoras
    } = useTicket();



    const RenderID = ({ id }) => {
        return <span>{id}</span>;
    };

    const RenderNombre = ({ nombre }) => {
        return <span>{nombre}</span>;
    };

    // Nuevos estados para los switches
    const [isPriorityHigh, setIsPriorityHigh] = useState(true);
    const [isStatusOpen, setIsStatusOpen] = useState(true);

    // Estado para los datos
    // const [datos, setDatos] = useState([]);
    const [datosFiltrados, setDatosFiltrados] = useState([]);
    //const [buscar, setBuscar] = useState();
    const [openModalEdicion, setOpenModalEdicion] = useState(false);
    const openEdicion = () => {
        if (openModalEdicion = true) {
            setOpenModalEdicion(false);
        }
        else {
            setOpenModalEdicion(true)
        }
    }


    const [busquedaLocal, setBusquedaLocal] = useState('');
    useEffect(() => {
        ObtenerTickets()
        ObtenerUser2()
        ObtenerOrigenes()
        ObtenerTipoProblema()
        ObtenerSucursal()
        ObtenerProceso()
        ObtenerUser22()
        ObtenerNivelPrioridad()
        ObtenerDepartamento()
    }, []);

    const { cambioSeccion } = useLocalStorage();
    const formatearFecha = (fecha) => {
        if (!fecha) return ''; // Si la fecha es nula o no está definida
        return fecha.split('T')[0]; // Toma solo la parte de la fecha antes de la 'T'
    };

    const navigate = useNavigate();

    const SeccionSeguimiento = (id, proyecto, fechaReporte, Actividad, usuario, fechaInicio, estatus) => {
        console.log("Datos enviados al seguimiento:", {
            id,
            proyecto,
            fechaReporte,
            nombre: Actividad,
            usuario,
            fechaActividad: fechaInicio,
            estatusActividad: estatus
        });

        cambioSeccion(Actividad);
        navigate(`/Seguimiento/${id}`, {
            state: {
                id: id,
                proyecto: proyecto,
                fechaReporte: fechaReporte,
                nombre: Actividad,
                usuario: usuario,
                fechaActividad: fechaInicio,
                estatusActividad: estatus,
            },
        })

    };

    const handleOptionClick = (label, origen) => {
        if (label === 'Editar') {
            openModal(origen);
        } else if (label === 'Deshabilitar') {
            Swal.fire({
                title: '¿Estás seguro que deseas deshabilitar este origen?',
                text: `Origen: ${origen.id} - ${origen.nombre}`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3BAF2A',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Sí, deshabilitar',
                cancelButtonText: 'Cancelar'
            }).then((result) => {
                if (result.isConfirmed) {
                    HabilitarTicket(user.idusuario, origen.id, false);
                    ObtenerTickets();
                }
            });
        } else if (label === 'Habilitar') {
            HabilitarTicket(user.idusuario, origen.id, true);
            ObtenerTickets();
        }
    };


    const Buscador2 = (e) => {
        const valor = e.target.value;
        setBusquedaLocal(valor);
        clearTimeout(window.searchTimeout);
        window.searchTimeout = setTimeout(() => {
            if (valor.trim() === '') {
                ObtenerTickets33();
            } else {
                ObtenerTickets222(valor);
            }
        }, 200);
    };




    const renderItemCabeceraTablaPendientes = () => (
        <>
            <div className='ItemHeader-TablaPaginador Ocultar' style={{ flex: 1 }}>
                <p>ID</p>
            </div >

            <div className='ItemHeader-TablaPaginador Ocultar' style={{ flex: 2 }}>
                <p>Fecha de Registro</p>
            </div >
            <div className='ItemHeader-TablaPaginador Ocultar' style={{ flex: 2 }}>
                <p>Registró</p>
            </div>
            <div className='ItemHeader-TablaPaginador Ocultar' style={{ flex: 2 }}>
                <p>Solicitante</p>
            </div>
            <div className='ItemHeader-TablaPaginador' style={{ flex: 2 }}>
                <p>Tipo</p>
            </div>

            <div className='ItemHeader-TablaPaginador' style={{ flex: 4 }}>
                <p>Descripción</p>
            </div>

            <div className='ItemHeader-TablaPaginador Ocultar' style={{ flex: 2 }}>
                <p>Asignado</p>
            </div>
            <div className='ItemHeader-TablaPaginador Ocultar' style={{ flex: 2 }}>
                <p>Origen</p>
            </div>
            <div className='ItemHeader-TablaPaginador' style={{ flex: 1 }}>
                <p>Prioridad</p>
            </div>
            <div className='ItemHeader-TablaPaginador' style={{ flex: 2 }}>
                <p>Estatus</p>
            </div>
        </>
    );

    const renderItemCuerpoTablaPendientes = () => {
        const sortedInfoTab = InfoTab?.sort((a, b) => {
            // Ordenar primero por estatus: "A" primero
            if (a.estatus === 'A' && b.estatus !== 'A') return -1;
            if (a.estatus !== 'A' && b.estatus === 'A') return 1;

            // Si los estatus son iguales, ordenar por fecha de registro (más reciente primero)
            const dateA = new Date(a.fechaReporte);
            const dateB = new Date(b.fechaReporte);
            return dateB - dateA;
        });

        return (
            <>

                {sortedInfoTab?.map(resp => {
                    console.log(resp.fechaReporte, "fechaaa")
                    const submenuOptions = resp.estatus === 'A' ? [
                        { label: 'Editar', image: Editar },
                        { label: 'Cerrar', image: Eliminar },
                        { label: 'Seguimiento', image: seguimiento }
                    ] : [
                        { label: 'Abrir', image: Activar },
                        { label: 'Seguimiento', image: seguimiento }
                    ];

                    return (
                        <div className="cuerpoPendientes" key={resp.id}>
                            <div className="Cuerpo-TablaSinPaginador" style={{ height: '50px' }}>

                                <div className='ItemCuerpo-Tablapaginador Ocultar' style={{ flex: 1, paddingTop: '0px' }}>
                                    <p style={{ margin: '0px' }}>{resp.id}</p>
                                </div>

                                <div className='ItemCuerpo-Tablapaginador Ocultar' style={{ flex: 2, paddingTop: '0px' }}>
                                    <p style={{ margin: '0px' }}>{resp.fechaRegistro}</p>
                                </div>

                                <div className='ItemCuerpo-Tablapaginador Ocultar' style={{ flex: 2, paddingTop: '0px' }}>
                                    <p style={{ margin: '0px' }}>{resp.usuarioRegistro}</p>
                                </div>
                                <div className='ItemCuerpo-Tablapaginador Ocultar' style={{ flex: 2, paddingTop: '0px' }}>
                                    <p style={{ margin: '0px' }}>{resp.usuarioSolicitante}</p>
                                </div>
                                <div className='ItemCuerpo-Tablapaginador' style={{ flex: 2, paddingTop: '0px' }}>
                                    <p style={{ margin: '0px' }}>{resp.nombreProblema}</p>
                                </div>
                                <div className='ItemCuerpo-Tablapaginador' style={{ flex: 4, paddingTop: '0px' }}>
                                    <p style={{ margin: '0px' }}>{resp.descripcion}</p>
                                </div>
                                <div className='ItemCuerpo-Tablapaginador Ocultar' style={{ flex: 2, paddingTop: '0px' }}>
                                    <p style={{ margin: '0px' }}>{resp.usuarioResuelve}</p>
                                </div>
                                <div className='ItemCuerpo-Tablapaginador Ocultar' style={{ flex: 2, paddingTop: '0px' }}>
                                    <p style={{ margin: '0px' }}>{resp.nombreOrigen}</p>
                                </div>

                                <div className='ItemCuerpo-Tablapaginador' style={{ flex: 1, paddingTop: '0px' }}>
                                    <p style={{ margin: '0px' }}>{resp.nombreNivelPrioridad}</p>
                                </div>
                                <div className='ItemHeader-TablaPaginador' style={{ flex: 2, padding: '0px', textAlign: 'center' }}>
                                    <p style={resp.estatus === 'A'
                                        ? {
                                            color: "#ffff",
                                            backgroundColor: '#3baf2a',
                                            borderRadius: '20px',
                                            width: '45%',
                                            margin: 'auto',
                                            padding: '8px 0px'
                                        }
                                        : {
                                            color: "white",
                                            backgroundColor: '#ec1c1c',
                                            borderRadius: '20px',
                                            width: '45%',
                                            margin: 'auto',
                                            padding: '8px 0px'
                                        }
                                    }>
                                        {resp.estatus === "A" ? "ABIERTO" : "CERRADO"}
                                    </p>
                                    <div className="contentainer" style={{ flex: 2, padding: '0px', textAlign: 'center' }}>
                                        <ItemSubMenu
                                            options={submenuOptions}
                                            style={{
                                                color: "#ffff",
                                                backgroundColor: 'aqua',
                                                borderRadius: '20px',
                                                width: '45%',
                                                margin: 'auto',
                                                padding: '8px 0px'
                                            }}
                                            onOptionClick={(label) => {
                                                if (label === 'Seguimiento') {
                                                    SeccionSeguimiento(
                                                        resp.id,
                                                        resp.nombreProblema,
                                                        resp.fechaReporte,
                                                        resp.descripcion,
                                                        resp.usuarioResuelve,
                                                        resp.usuarioSolicitante,
                                                        resp.estatus,
                                                    );
                                                } else {
                                                    handleOptionClick(label, resp);
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </>
        );
    };





    const switchStyles = {
        wrapper: {
            display: 'flex',
            gap: '12px',
            alignItems: 'center',
            padding: '8px',
        },
        switchContainer: {
            display: 'flex',
            alignItems: 'center',
            gap: '8px'
        },
        switchTrack: {
            width: '64px',
            height: '28px',
            backgroundColor: '#ddd',
            borderRadius: '14px',
            padding: '2px',
            cursor: 'pointer',
            position: 'relative',
            transition: 'background-color 0.3s ease',
            border: '1px solid rgba(0,0,0,0.1)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
        },
        switchThumb: {
            width: '24px',
            height: '24px',
            borderRadius: '50%',
            backgroundColor: 'white',
            position: 'absolute',
            transition: 'transform 0.3s ease',
            boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
        },
        text: {
            color: 'white',
            fontSize: '11px',
            fontWeight: 'bold',
            userSelect: 'none',
            position: 'absolute',
            width: '100%',
            textAlign: 'center',
        }
    };



    return (
        <StyledBitacoraSoporte>
            <div className='contenedor-principal'>
                <div className='tabla-catalogo'>
                    {loading ? (
                        <div className='PositionLoader'>
                            <Loader />
                        </div>
                    ) :
                        <TablaPaginador
                            Cabezera={renderItemCabeceraTablaPendientes}
                            Cuerpo={renderItemCuerpoTablaPendientes}
                            identificador={Tablapaginador}
                            ProPrevPg={handlePrimeraPg}
                            PrevPg={handlePreviaPg}
                            NextPg={handleNexPg}
                            ProNextPg={handleUltimaPg}
                            Pg={Pg}
                            MaxPg={MaxPg}
                            RegsPg={RegsPg}
                            setRegsPg={setRegsPg}
                            ChangeRegs={ChangeRegs}
                            InfoTab={datosFiltrados}
                        />

                    }
                </div>
                <StyledActividades>
                    <div className='acomodo2'>
                        <div className='ContenedorFiltros2' style={{
                            display: 'flex',
                            gap: '16px',
                            alignItems: 'center',
                            flexWrap: 'wrap'
                        }}>
                            {/* Búsqueda por ticket */}
                            <div className='buscando'>
                                <InputPKT1
                                    label={"Buscar"}
                                    placeholder={"Tickets"}
                                    type={"search"}
                                    mostrar={true}
                                    background={"rgb(58, 147, 56)"}
                                    BtnImg={Buscarimg}
                                    Accion={Buscador2}
                                    value={busquedaLocal}
                                />
                            </div>


                            <div className='PrimerosFiltros'>

                                {/* Select de Estatus */}
                                <div className='filtroEstatus'>
                                    <div style={{ minWidth: '150px', marginBottom: '0px', marginTop: '-18px', paddingBottom: '4px', paddingTop: '20px' }}>
                                        Estatus
                                    </div>
                                    <select
                                        className="SelectOptions"
                                        value={estatusFilter}
                                        onChange={(e) => {
                                            const estatus = e.target.value;
                                            setEstatusFilter(estatus); // Actualiza el estado del filtro
                                            if (estatus) {
                                                // Crear el filtro
                                                const filtros = [
                                                    { tipofiltro: "estatus", valor: estatus.toString() }
                                                ];
                                                ObtenerTicketsFiltros(filtros); // Llamar al servicio con el filtro
                                            } else {
                                                ObtenerTickets(); // Cargar sin filtros
                                            }
                                        }}
                                        style={{
                                            width: '100%',
                                            padding: '8px',
                                            borderRadius: '4px',
                                            border: '1px solid #ddd',
                                        }}
                                    >
                                        <option value="">Todos</option>
                                        <option value="A">ABIERTO</option>
                                        <option value="I">CERRADO</option>
                                    </select>
                                </div>




                                {/* Select de Prioridad */}
                                <div className='filtroEstatus'>
                                    <div style={{ minWidth: '150px', marginBottom: '0px', marginTop: '-18px', paddingBottom: '4px', paddingTop: '20px' }}>
                                        Prioridad
                                    </div>
                                    <select
                                        className="SelectOptions"
                                        value={prioridadFilter}
                                        onChange={(e) => {
                                            const nombrePrioridad = e.target.value;
                                            setPrioridadFilter(nombrePrioridad); // Actualiza el estado del filtro
                                            if (nombrePrioridad) {
                                                // Crear el filtro
                                                const filtros = [
                                                    { tipofiltro: "nivelPrioridad", valor: nombrePrioridad.toString() }
                                                ];
                                                ObtenerTicketsFiltros(filtros); // Llamar al servicio con el filtro
                                            } else {
                                                ObtenerTickets(); // Cargar sin filtros
                                            }
                                        }}
                                        style={{
                                            width: '100%',
                                            padding: '8px',
                                            borderRadius: '4px',
                                            border: '1px solid #ddd',
                                        }}
                                    >
                                        <option value="">Todos</option>
                                        {Array.isArray(prioridades) && prioridades.map((prioridad) => (
                                            <option key={prioridad.id} value={prioridad.nombre}>
                                                {prioridad.nombre}
                                            </option>
                                        ))}
                                    </select>
                                </div>


                            </div>




                            {/* Select de Asignados */}
                            <div style={{ minWidth: '150px' }}>
                                <div style={{ minWidth: '150px', marginBottom: '0px', marginTop: '-18px', paddingBottom: '4px', paddingTop: '20px' }}>
                                    Asignado
                                </div>
                                <select
                                    className="SelectOptions"
                                    value={asignadoFilter}
                                    onChange={(e) => {
                                        const idUsuario = e.target.value;
                                        setAsignadoFilter(idUsuario); // Actualiza el estado del filtro

                                        if (idUsuario) {
                                            // Crear el filtro
                                            const filtros = [
                                                { tipofiltro: "usuarioAsignado", valor: idUsuario.toString() }
                                            ];
                                            ObtenerTicketsFiltros(filtros); // Llamar al servicio con el filtro
                                        } else {
                                            ObtenerTickets(); // Cargar sin filtros
                                        }
                                    }}
                                    style={{
                                        width: '100%',
                                        padding: '8px',
                                        borderRadius: '4px',
                                        border: '1px solid #ddd'
                                    }}
                                >
                                    <option value="">Todos</option>
                                    {Array.isArray(asignados) &&
                                        asignados.map((usuario) => (
                                            <option key={usuario.id} value={usuario.id}>
                                                {usuario.nombre}
                                            </option>
                                        ))}
                                </select>
                            </div>



                            {/* Select de Solicitantes */}
                            <div style={{ minWidth: '150px' }}>
                                <div style={{ minWidth: '150px', marginBottom: '0px', marginTop: '-18px', paddingBottom: '4px', paddingTop: '20px' }}>
                                    Solicitante
                                </div>
                                <select
                                    className="SelectOptions"
                                    value={solicitanteFilter}
                                    onChange={(e) => {
                                        const idUsuario = e.target.value;
                                        setSolicitanteFilter(idUsuario); // Actualiza el estado del filtro

                                        if (idUsuario) {
                                            // Crear el filtro
                                            const filtros = [
                                                { tipofiltro: "usuarioSolicitante", valor: idUsuario.toString() }
                                            ];
                                            ObtenerTicketsFiltros(filtros); // Llamar al servicio con el filtro
                                        } else {
                                            ObtenerTickets(); // Cargar sin filtros
                                        }
                                    }}
                                    style={{
                                        width: '100%',
                                        padding: '8px',
                                        borderRadius: '4px',
                                        border: '1px solid #ddd',
                                    }}
                                >
                                    <option value="">Todos</option>
                                    {Array.isArray(solicitantes) &&
                                        solicitantes.map((solicitante) => (
                                            <option key={solicitante.id} value={solicitante.id}>
                                                {solicitante.nombre}
                                            </option>
                                        ))}
                                </select>
                            </div>


                        </div>

                        <div className='ContenedorAgregar2'>
                            <Agregar IconoAgregar={Add} Accion={() => openModal()} />
                        </div>
                    </div>
                </StyledActividades>
            </div>

            <Modal
                estado={showModal}
                titulo={modoEdicion ? "EDITAR TICKET" : "NUEVO TICKET"}
                cambiarEstado={closeModal}
            >
                <div className='inputOrigen'>
                    <div className='BotonesFecha'>
                        <InputPKT1
                            label={"Fecha reporte"}
                            type={"date"}
                            mostrar={"none"}
                            placeholder={"yyyy-MM-dd"}
                            OverrideStyles={{ flex: '2', padding: '0px', paddingLeft: '0px' }}
                            value={fechaReporte || ""} // Garantiza que siempre haya un valor
                            Accion={(e) => {
                                const nuevaFecha = e.target.value;
                                console.log("Nueva fecha seleccionada:", nuevaFecha);
                                setFechaReporte(nuevaFecha); // Actualiza el estado
                            }}
                        />
                        <div style={{ marginLeft: '10px' }}>
                            <InputPKT1
                                label={"Hora"}
                                type={"time"}
                                mostrar={"none"}
                                placeholder={"HH:mm"}
                                desactivar={false}
                                OverrideStyles={{ flex: '2', padding: '0px', paddingLeft: '0px' }}
                                Accion={(e) => {
                                    const nuevaHora = e.target.value;
                                    console.log("Nueva hora seleccionada:", nuevaHora);
                                    setHoras(nuevaHora); // Actualiza el estado
                                }}
                                value={Horas || ""} // Garantiza que siempre haya un valor
                            />


                        </div>


                        <div style={{ display: 'flex', flexDirection: 'column', gap: '2px', marginLeft: '70px' }}>
                            <div className='text'>
                                Problema relacionado
                            </div>
                            <select
                                className='SelectOptions'
                                value={idTipoProblema}
                                onChange={(e) => handleIdTipoProblemaChange(e.target.value)}
                                style={{ width: '108%', padding: '5px', marginBottom: '10px' }}
                            >
                                <option value="">Seleccione un tipo de problema</option>
                                {RespuestaTipoProblema && RespuestaTipoProblema.map((problema) => (
                                    <option key={problema.id} value={problema.id}>
                                        {problema.nombre}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>

                    <div className='BotonesFecha2' style={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '16px',
                        alignItems: 'center',
                        padding: '5px 0px 10px 0px'
                    }}>
                        <div style={{ display: 'flex', flexDirection: 'column', gap: '4px', flex: 6 }}>

                            <div className='text'>
                                Proceso asignado
                            </div>
                            <select
                                className='SelectOptions'
                                value={idProceso}
                                onChange={(e) => handleIdProcesoChange(e.target.value)}
                                style={{ width: '100%', padding: '10px', marginBottom: '10px' }}
                            >
                                <option value="">Seleccione un proceso</option>
                                {RespuestaProceso && RespuestaProceso.map((Proceso) => (
                                    <option key={Proceso.idProceso} value={Proceso.id}>
                                        {Proceso.nombre}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>


                    <InputText
                        label={"Descripcion"}
                        type={"textarea"}
                        placeholder={"Descripcion del Ticket"}
                        size={300}
                        mostrar={"none"}
                        desactivar={false}
                        Accion={(e) => handleDescripcionChange(e.target.value)}
                        value={descripcion}
                    />

                    <div className='BotonesFecha2' style={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '16px',
                        alignItems: 'center',
                        /* arriba | derecha | abajo | izquierda */
                        padding: '9px 0px 10px 0px'
                    }}>
                        <div style={{ display: 'flex', flexDirection: 'column', gap: '4px', flex: 4 }}>

                            <div className='text'>
                                Solicitado
                            </div>
                            <select
                                className='SelectOptions'
                                value={idUsuarios}
                                onChange={(e) => handleIdUsuariosChange(e.target.value)}
                                style={{ width: '100%', padding: '10px', marginBottom: '10px' }}
                            >
                                <option value="">Seleccione un usuario</option>
                                {RespuestaUsuarios && RespuestaUsuarios.map((usuario) => (
                                    <option key={usuario.idUsuario} value={usuario.idUsuario}>
                                        {usuario.encargado}
                                    </option>
                                ))}
                            </select>
                        </div>

                        <div style={{ display: 'flex', flexDirection: 'column', gap: '4px', flex: 2 }}>

                            <div className='text'>
                                Origenes
                            </div>
                            <select
                                className='SelectOptions'
                                value={id}
                                onChange={(e) => handleIdOrigenChange(e.target.value)}
                                style={{ width: '100%', padding: '10px', marginBottom: '10px' }}
                            >
                                <option value="">Seleccione un origen</option>
                                {RespuestaOrigen && RespuestaOrigen.map((origen) => (
                                    <option key={origen.id} value={origen.id}>
                                        {origen.nombre}
                                    </option>
                                ))}
                            </select>
                        </div>



                    </div>

                    <div className='BotonesFecha2' style={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '16px',
                        alignItems: 'center',
                        padding: '9px 0px 10px 0px'
                    }}>

                        <div style={{ display: 'flex', flexDirection: 'column', gap: '4px', flex: 2 }}>
                            <div className='text'>
                                Asignado
                            </div>
                            <select
                                className='SelectOptions'
                                value={idUsuarios2}
                                onChange={(e) => handleIdUsuariosChange2(e.target.value)}
                                style={{ width: '100%', padding: '10px', marginBottom: '10px' }}
                            >
                                <option value="">Seleccione un usuario</option>
                                {RespuestaUsuarios2 && RespuestaUsuarios2.map((usuario) => (
                                    <option key={usuario.idUsuario} value={usuario.idUsuario}>
                                        {usuario.encargado}
                                    </option>
                                ))}
                            </select>
                        </div>


                        <div style={{ display: 'flex', flexDirection: 'column', gap: '4px', flex: 2 }}>
                            <div className='text'>
                                Departamentos
                            </div>
                            <select
                                className='SelectOptions'
                                value={idDepartamento}
                                onChange={(e) => handleIdDepaertamentoChange(e.target.value)}
                                style={{ width: '100%', padding: '10px', marginBottom: '10px' }}
                            >
                                <option value="">Seleccione un departamento</option>
                                {RespuestaDepartamento && RespuestaDepartamento.map((departamento) => (
                                    <option key={departamento.idDepartamento} value={departamento.id}>
                                        {departamento.nombre}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>


                    <div style={switchStyles.wrapper}>
                        <div style={modoEdicion ? { display: 'flex' } : { display: 'none' }}>
                            {/* <div style={switchStyles.switchContainer}>
                                <span style={switchStyles.label}>Estatus:</span>
                                <div
                                    onClick={() => setIsStatusOpen(!isStatusOpen)}
                                    style={{
                                        ...switchStyles.switchTrack,
                                        backgroundColor: isStatusOpen ? '#4CAF50' : '#ff4444'
                                    }}
                                >
                                    <div
                                        style={{
                                            ...switchStyles.switchThumb,
                                            transform: isStatusOpen ? 'translateX(36px)' : 'translateX(2px)'
                                        }}
                                    />
                                    <span
                                        style={{
                                            ...switchStyles.text,
                                            left: '6px',
                                            opacity: isStatusOpen ? 0 : 1
                                        }}
                                    >
                                        CERRADO
                                    </span>
                                    <span
                                        style={{
                                            ...switchStyles.text,
                                            right: '6px',
                                            opacity: isStatusOpen ? 1 : 0
                                        }}
                                    >
                                        ABIERTO
                                    </span>
                                </div>
                            </div> */}

                        </div>




                        <div style={{ display: 'flex', flexDirection: 'column', gap: '4px', flex: 2 }}>
                            <div className='text'>
                                Prioridad
                            </div>
                            <select
                                className='SelectOptions'
                                value={idPrioridad}
                                onChange={(e) => handleIdPrioridadChange(e.target.value)}
                                style={{ width: '100%', padding: '10px', marginBottom: '10px' }}
                            >
                                <option value="">Seleccione la prioridad</option>
                                {RespuestaPrioridad && RespuestaPrioridad.map((prioridad) => (
                                    <option key={prioridad.idPrioridad} value={prioridad.id}>
                                        {prioridad.nombre}
                                    </option>
                                ))}
                            </select>
                        </div>

                        <div style={{ display: 'flex', flexDirection: 'column', gap: '4px', flex: 2 }}>

                            <div className='text'>
                                Sucursales
                            </div>
                            <select
                                className='SelectOptions'
                                value={idSucursal}
                                onChange={(e) => handleIdSucursalChange(e.target.value)}
                                style={{ width: '100%', padding: '10px', marginBottom: '10px' }}
                            >
                                <option value="">Seleccione una sucursal</option>
                                {RespuestaSucursal && RespuestaSucursal.map((sucursal) => (
                                    <option key={sucursal.idSucursal} value={sucursal.id}>
                                        {sucursal.nombre}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className='botonAccion' style={{ marginLeft: '-28px' }}>
                        <BotonesAcciones
                            accion={modoEdicion ? "Guardar cambios" : "Guardar y enviar"}
                            colorFondo={"#2D8520"}
                            colorLetra={"#FFF"}
                            clickBoton={() => handlerGuardarTicket()}
                        />
                    </div>
                </div>
            </Modal>
        </StyledBitacoraSoporte>
    );
};
export default Soportes;