import React, { useRef, useState, useEffect } from 'react';
import { StyledBitacoraSoporte } from '../themes/StyledBitacoraSoporte';
import { StyledActividades } from '../themes/StyledActividades';
import Modal from '../Componentes/Modal';
import InputPKT1 from '../Componentes/InputPKT1';
import InputPrueba from '../Componentes/InputPrueba';
import BotonesAcciones from '../Componentes/BotonesAcciones';
import TablaPaginador from '../Componentes/TablaPaginador';
import usePrueba from '../hooks/usePrueba';
import ItemSubMenu from '../Componentes/ItemSubMenu';
import Editar from '../assets/svg/EditarNew.svg';
import Eliminar from '../assets/svg/eliminarNew.svg'
import Activar from '../assets/svg/activar.svg'
import Agregar from '../Componentes/Agregar'
import Add from '../assets/svg/add.svg'
import Buscarimg from '../assets/svg/buscar.svg'
import Swal from 'sweetalert2';
import InputText from '../Componentes/InputText'
import Loader from '../Componentes/Loader';


const Prueba = (props) => {
    const Tablapaginador = useRef();
    const { ChangeNombre,
        nuevoDepartamento,
        CrearDepartamento,
        CleanNombre,
        respPaginador,
        PaginadorDepartamentos,
        EliminarDepartamento,
        ModificarDepartamento,
        ObtenerDepartamento,
        ObtenerUsuarios,
        handlerGuardarDepartamento,
        showModal,
        openModal,
        modoEdicion,
        closeModal,
        user,
        page,
        totalPage,
        Buscar,
        ObtenerDepartamentos,
        handlePrimeraPg,
        handlePreviaPg,
        handleNexPg,
        handleUltimaPg,
        InfoTab,
        ChangeRegs,
        RegsPg,
        setRegsPg,
        Pg,
        MaxPg,
        RespestaGetDepartamento,
        RespuestaUsuarios,
        ObtenerUser2,
        HabilitarDepartamentos,
        ObtenerDepartamentos2,
        ObtenerDepartamento222,
        ObtenerDepartamentos33,
        mostrarProyectos,
        showDatosPoryectos,
        showSelect,
        setShowSelect,
        loading,
        TotalPaginas,
        TotalRegistros,
        Pagina,
        setPagina,
        FormularioProyectos,
        EditarDepartamento,
        handleInputChange,
        nombreDepartamento,
        observacionDepartamento,
        idUsuarios,
        setNombreDepartamento,
        setObservacionDepartamento,
        setIdUsuarios,
        handleNombreChange,
        handleIdUsuariosChange,
        handleObservacionesChange,
        showTable,
        setShowTable,
        setLoading,
        departamentoSeleccionado
    } = usePrueba();




    useEffect(() => {
        ObtenerDepartamentos()
        ObtenerUser2()

    }, []);


    const RenderID = ({ id }) => {
        return <span>{id}</span>;
    };

    const RenderNombre = ({ nombre }) => {
        return <span>{nombre}</span>;
    };



    const [buscar, setBuscar] = useState();
    const [busquedaLocal, setBusquedaLocal] = useState('');
    const handleOptionClick = (label, departamento) => {
        if (label === 'Editar') {
            openModal(departamento);
        } else if (label === 'Deshabilitar') {
            Swal.fire({
                title: '¿Estás seguro que deseas deshabilitar este deparatamento?',
                text: `departamento: ${departamento.id} - ${departamento.nombre}`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3BAF2A',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Sí, deshabilitar',
                cancelButtonText: 'Cancelar'
            }).then((result) => {
                if (result.isConfirmed) {
                    EliminarDepartamento(user.idusuario, departamento.id);
                    ObtenerDepartamentos();
                }
            });

        } else if (label === 'Habilitar') {
            HabilitarDepartamentos(departamento.id, user.idusuario, true);
            ObtenerDepartamentos();
        }
    };



    const Buscador2 = (e) => {
        const valor = e.target.value;
        setBusquedaLocal(valor);
        clearTimeout(window.searchTimeout);
        window.searchTimeout = setTimeout(() => {
            if (valor.trim() === '') {
                ObtenerDepartamentos33();
            } else {
                ObtenerDepartamento222(valor);
            }
        }, 200);
    };

    const renderItemCabeceraTablaPendientes = () => (
        <>
            <div className='ItemHeader-TablaPaginador' style={{ flex: 1 }}>
                <p>ID</p>
            </div>
            <div className='ItemHeader-TablaPaginador' style={{ flex: 1 }}>
                <p>Nombre</p>
            </div>
            <div className='ItemHeader-TablaPaginador' style={{ flex: 1 }}>
                <p>Usuario</p>
            </div>
            <div className='ItemHeader-TablaPaginador' style={{ flex: 1 }}>
                <p>Estatus</p>
            </div>
        </>
    );

    const renderItemCuerpoTablaPendientes = () => (
        <>
            {InfoTab?.map(resp => {
                const submenuOptions = resp.estatus === 'A' ? [
                    { label: 'Editar', image: Editar },
                    { label: 'Deshabilitar', image: Eliminar }
                ] : [
                    { label: 'Habilitar', image: Activar }
                ];

                const usuarioAsociado = RespuestaUsuarios.find(user => user.idUsuario === resp.idUsuarios);

                return (
                    <div className="cuerpoPendientes" key={resp.id}>
                        <div className="Cuerpo-TablaSinPaginador" style={{ height: '50px' }}>
                            <div className='ItemCuerpo-Tablapaginador' style={{ flex: 1, paddingTop: '0px' }}>
                                <p style={{ margin: '0px' }}>{resp.id}</p>
                            </div>
                            <div className='ItemCuerpo-Tablapaginador' style={{ flex: 1, padding: '0px' }}>
                                <p>{resp.nombre}</p>
                            </div>
                            <div className='ItemCuerpo-Tablapaginador' style={{ flex: 1, padding: '0px' }}>
                                <p>{usuarioAsociado ? usuarioAsociado.encargado : 'Usuario no encontrado'}</p>
                            </div>
                            <div className='ItemHeader-TablaPaginador' style={{ flex: 1, padding: '0px', textAlign: 'center' }}>
                                <p style={resp.estatus === 'A'
                                    ? {
                                        color: "#ffff",
                                        backgroundColor: '#3baf2a',
                                        borderRadius: '20px',
                                        width: '45%',
                                        margin: 'auto',
                                        padding: '8px 0px'
                                    }
                                    : {
                                        color: "white",
                                        backgroundColor: '#ec1c1c',
                                        borderRadius: '20px',
                                        width: '45%',
                                        margin: 'auto',
                                        padding: '8px 0px'
                                    }
                                }>     {resp.estatus === "A" ? "Activo" : "Inactivo"}
                                </p>
                                <div className='contentainer'>
                                    <ItemSubMenu
                                        options={submenuOptions}
                                        onOptionClick={(label) => handleOptionClick(label, resp)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                );
            })}
        </>
    );

    return (
        <StyledBitacoraSoporte>
            
            <div className='contenedor-principal'>
                    <div className='tabla-catalogo'>
                {loading ? (
                        <div className='PositionLoader'>
                            <Loader />
                        </div>
                    ) :
                        <TablaPaginador

                            Cabezera={renderItemCabeceraTablaPendientes}
                            Cuerpo={renderItemCuerpoTablaPendientes}
                            identificador={Tablapaginador}
                            ProPrevPg={handlePrimeraPg}
                            PrevPg={handlePreviaPg}
                            NextPg={handleNexPg}
                            ProNextPg={handleUltimaPg}
                            Pg={Pg}
                            MaxPg={MaxPg}
                            RegsPg={RegsPg}
                            setRegsPg={setRegsPg}
                            ChangeRegs={ChangeRegs}
                            InfoTab={InfoTab}
                        />
                }
                    </div>



                <StyledActividades>
                    <div className='acomodo'>
                        
                        <div className='ContenedorFiltros'>
                            <InputPKT1
                                label={"Buscar"}
                                placeholder={"Departamento"}
                                type={"search"}
                                mostrar={true}
                                background={"rgb(58, 147, 56)"}
                                BtnImg={Buscarimg}
                                Accion={Buscador2}
                                value={busquedaLocal}
                            />
                        </div>
                        <div className='ContenedorAgregar'>
                            <Agregar IconoAgregar={Add} Accion={() => openModal()} />
                        </div>
                    </div>
                </StyledActividades>
            </div>

            <Modal
                estado={showModal}
                titulo={modoEdicion ? "EDITAR DEPARATAMENTO" : "NUEVO DEPARATAMENTO"}
                cambiarEstado={closeModal}
            >
                <div>
                <div className='identificador'>
                        {departamentoSeleccionado && (
                            <div style={{marginLeft:'20px'}}>
                                <p>Editando el departamento: <RenderID id={departamentoSeleccionado.id} /> <RenderNombre nombre={departamentoSeleccionado.nombre}/>   </p>
                            </div>
                        )}
                    </div>

                    <div className='inputOrigen'>
                        <br></br>
                        <InputPKT1
                            label={"Nombre"}
                            type={"text"}
                            mostrar={"none"}
                            desactivar={false}
                            Accion={(e) => handleNombreChange(e.target.value)}
                            value={nombreDepartamento}
                            size={30}
                        />
                        <br></br>

                        <InputText
                            label={"Observaciones"}
                            type={"textarea"}
                            placeholder={"Observaciones del departamento"}
                            size={40}
                            mostrar={"none"}
                            desactivar={false}
                            Accion={(e) => handleObservacionesChange(e.target.value)}
                            value={observacionDepartamento}
                        />
                        <br></br>
                        Usuario Seleccionado
                        <select
                            className='SelectOptions'
                            value={idUsuarios}
                            onChange={(e) => handleIdUsuariosChange(e.target.value)}
                            style={{ width: '100%', padding: '10px', marginBottom: '10px' }}
                        >
                            <option value="">Seleccione un usuario</option>
                            {RespuestaUsuarios && RespuestaUsuarios.map((usuario) => (
                                <option key={usuario.idUsuario} value={usuario.idUsuario}>
                                    {usuario.encargado}
                                </option>
                            ))}
                        </select>

                    </div>

                    <div className='botonAccion'>
                        <BotonesAcciones
                            accion={modoEdicion ? "Guardar cambios" : "Guardar y enviar"}
                            colorFondo={"#2D8520"}
                            colorLetra={"#FFF"}
                            clickBoton={handlerGuardarDepartamento}
                        />
                    </div>
                </div>
            </Modal>



        </StyledBitacoraSoporte>
    );
};


export default Prueba;