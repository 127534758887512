// useDepartamentoes.js
import React, { useState } from 'react';
import axios from 'axios';
import { useAuth } from '../Context/Context';
import { usePaginadorNuevo } from './usePaginadorNuevo';

const useDepartamentos = () => {
    const { user } = useAuth();
    const [nuevoDepartamento, setnuevoDepartamento] = useState();
    const [respPaginador, setrespPaginador] = useState();
    const [showModal, setShowModal] = useState(false);
    const [modoEdicion, setModoEdicion] = useState(false);
    const [DepartamentoSeleccionado, setDepartamentoSeleccionado] = useState(null);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [totalPage, setTotalPage] = useState(1);
    const [TotalRegistros, setTotalRegistros] = useState();
    const [Buscar, setBuscar] = useState();
    const [RegistrosPorPagina, setRegistrosPorPagina] = useState(10)
    const [RespestaGetDepartamento, setRespestaGetDepartamento] = useState();
    const { Pg, MaxPg, RegsPg, Loading, InfoTab, GetPages, ProPrevPg, PrevPg, NextPg, ProNextPg, setRegsPg } = usePaginadorNuevo();

    const ObtenerOrigenes222 = (busqueda) => {
        const busquedaParam = busqueda.trim() !== '' ? busqueda : '';
        GetPages(`${process.env.REACT_APP_API_SOPORTE}Departamentos/Paginador?buscar=${busquedaParam}&pagina=${Pg}&registrosPorPagina=${RegsPg}`);
    };
    const ObtenerOrigenes33 = () => {
        GetPages(`${process.env.REACT_APP_API_SOPORTE}Departamentos/Paginador?buscar=&pagina=${Pg}&registrosPorPagina=${RegsPg}`);
    };


    const ObtenerDepartamento = () => {
        GetPages(`${process.env.REACT_APP_API_SOPORTE}Departamentos/Paginador?buscar=${Buscar === undefined ? '' : Buscar}&pagina=${Pg === 0 ? 1 : Pg}&registrosPorPagina=${RegsPg === 0 ? 10 : RegsPg} `);
    }
    const ChangeRegs = (regis) => {
        GetPages(`${process.env.REACT_APP_API_SOPORTE}Departamentos/Paginador?buscar=${Buscar === undefined ? '' : Buscar}&pagina=${1}&registrosPorPagina=${regis} `);
    }
    const handlePrimeraPg = () => {
        ProPrevPg(`${process.env.REACT_APP_API_SOPORTE}Departamentos/Paginador?buscar=${Buscar === undefined ? '' : Buscar}&pagina=${1}&registrosPorPagina=${RegsPg === 0 ? 10 : RegsPg} `);
    }

    const handlePreviaPg = () => {
        PrevPg(`${process.env.REACT_APP_API_SOPORTE}Departamentos/Paginador?buscar=${Buscar === undefined ? '' : Buscar}&pagina=${Pg - 1}&registrosPorPagina=${RegsPg === 0 ? 10 : RegsPg} `)
    }
    const handleNexPg = () => {
        NextPg(`${process.env.REACT_APP_API_SOPORTE}Departamentos/Paginador?buscar=${Buscar === undefined ? '' : Buscar}&pagina=${Pg + 1}&registrosPorPagina=${RegsPg === 0 ? 10 : RegsPg} `)
    }
    const handleUltimaPg = () => {
        ProNextPg(`${process.env.REACT_APP_API_SOPORTE}Departamentos/Paginador?buscar=${Buscar === undefined ? '' : Buscar}&pagina=${MaxPg}&registrosPorPagina=${RegsPg === 0 ? 10 : RegsPg} `)
    }
    const ChangeNombre = (data) => {
        setnuevoDepartamento(data);
    };

    const CleanNombre = () => {
        setnuevoDepartamento();
    };

    const closeModal = () => {
        setShowModal(false);
        CleanNombre();
        setDepartamentoSeleccionado(null);
        setModoEdicion(false);
    };

    const openModal = (Departamento) => {
        setShowModal(true);
        if (Departamento) {
            setModoEdicion(true);
            setDepartamentoSeleccionado(Departamento);
            ChangeNombre(Departamento.nombre);
         
        } else {
            setModoEdicion(false);
            CleanNombre();
        }
    };

    const handlerGuardarDepartamento = () => {
        if (modoEdicion && DepartamentoSeleccionado) {
            const datosModificados = { nombre: nuevoDepartamento };
            ModificarDepartamento(DepartamentoSeleccionado.id, user.idusuario, datosModificados);
        } else {
            const requestDepartamento = { nombre: nuevoDepartamento };
            CrearDepartamento(requestDepartamento, user.idusuario);
        }
    };

    const CrearDepartamento = async (request, usuario) => {
        setLoading(true);
        try {
            await axios.post(
                `${process.env.REACT_APP_API_SOPORTE}Departamentos/Agregar/${usuario}`,
                request,
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );
             ObtenerDepartamento();
            closeModal();
        } catch (error) {
            console.log('Error al agregar nuevo Departamento:', error);
        } finally {
            setLoading(false);
        }
    };

    const PaginadorDepartamento = async () => {
        setLoading(true);
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API_SOPORTE}Departamentos/Paginador?buscar=${''}&pagina=${Pg}&registrosPorPagina=${RegsPg === undefined ? '10' : RegsPg} `);

            setrespPaginador(response.data.resultado);
            setTotalPage(response.data.totalPage);
            // HanlderChangueResultados(response.data.TotalRegistros);
            return response.data.resultado;

        }
        catch (error) {
            console.error('Error al obtener paginador de Departamentos:', error);
        } finally {
            setLoading(false);
        }
    };

    const EliminarDepartamento = async (iddepartamento, idUsuario) => {
        setLoading(true);
        try {
            await axios.delete(`${process.env.REACT_APP_API_SOPORTE}Departamentos/Eliminar/${iddepartamento}/${idUsuario}`, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            ObtenerDepartamento();
        } catch (error) {
            console.error('Error al dar de baja el Departamentos: ', error);
        } finally {
            setLoading(false);
        }
    };

    const ModificarDepartamento = async (iddepartamento, idUsuario, datosModificados) => {
        setLoading(true);
        try {
            await axios.put(
                `${process.env.REACT_APP_API_SOPORTE}Departamentos/Actualizar/${iddepartamento}/${idUsuario}`,
                datosModificados,
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );
            ObtenerDepartamento();
            closeModal();
            // alert(`Departamento con ID ${idDepartamento} modificado.`);
        } catch (error) {
            alert('Error al modificar Departamento:', error);
        } finally {
            setLoading(false);
        }
    };

    const ObtenerDepartamentos = async () => {
        setLoading(true);
    
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_SOPORTE}Departamentos/Obtener`);
    
            const respuestaServicio = response.data;
    
            const nuevaRespuesta = respuestaServicio.map(resp => ({
                id: resp.id,
                descripcion: resp.nombre,
                observaciones : resp.observaciones,
                idusuarios : resp.idusuarios
            }));
    
            setRespestaGetDepartamento(nuevaRespuesta);
    
            console.log("Datos obtenidos:", nuevaRespuesta);
    
        } catch (error) {
            console.error('Error al obtener Departamento:', error);
            
        } finally {
            setLoading(false);
        }
    };
    

    return {
        ChangeNombre,
        nuevoDepartamento,
        CrearDepartamento,
        CleanNombre,
        respPaginador,
        PaginadorDepartamento,
        EliminarDepartamento,
        ModificarDepartamento,
        ObtenerDepartamento,
        handlerGuardarDepartamento,
        showModal,
        openModal,
        modoEdicion,
        closeModal,
        user,
        loading,
        page,
        totalPage,
        TotalRegistros,
        Buscar,
        ObtenerDepartamentos,
        handlePrimeraPg,
        handlePreviaPg,
        handleNexPg,
        handleUltimaPg,
        InfoTab,
        ChangeRegs,
        RegsPg,
        setRegsPg,
        Pg,
        MaxPg,
        RespestaGetDepartamento,
        ObtenerOrigenes222,
        ObtenerOrigenes33

    };
};

export default useDepartamentos;