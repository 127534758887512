import axios from 'axios';
import React, { useState } from 'react'
import useSelect from './useSelect';

const useUsuarios = () => {

  const { ConvertirAObjeto, objetoSelect } = useSelect();

    const [dataUser, setDataUser] = useState([]);
    const [dataSelect, setDataSelect] = useState([]);
    const [nameUser, setNameUser] = useState("");
    const [idUser, setIdUser] = useState();

    const showUsuarios = async (url) => {
        // let configpost = {
        //   method: 'post',
        //   url: url,
        //   headers: {
        //     'Content-Type': 'application/json'
        //   }
        // };
    
        await axios.get(url)
        .then(response => {
          let RespuestaServicio = response.data.value;

          const NuevaRespuesta = RespuestaServicio.map(resp => {
            const RehacerObjeto = { "id": resp.idUsuario, "descripcion":resp.nombre }
            return RehacerObjeto
          })

          let NuevoArray =  [{ "id": 0, "descripcion": "Todos" }, ...NuevaRespuesta]

          console.log(NuevoArray);
         setDataSelect(NuevoArray);

          // setNameUser(ObjetoNombreUser);
          // setIdUser(ObjetoIdUser);
          setDataUser(response.data.value);
          // console.log(response.data)
          // setDataUser(response.data.value);
          // ConvertirAObjeto(response.data.value);  
          // console.log(objetoSelect)
          })
          .catch(error => {
            console.log(error);
          });
      }

  return {
    showUsuarios,
    nameUser,
    dataUser,
    idUser,
    dataSelect
  }
}

export default useUsuarios