import styled from 'styled-components';
const StyledDetallesActividades = styled.div`

.scroll{
  margin: 3rem;
}

.bodyRegistroActividades{
    display: flex;
    flex-direction: column;
}

.headerTable{
    background-color: #444971;
    color: #fff;
    font-weight: bold;
    font-size: 14px;
    border-radius: 5px;
    overflow: auto;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}






.headerTable h3{
    margin-right: 15px;
}






.bodyTable {
    max-height: 60vh;
    overflow-y: auto;
    background: #fff;
    border-radius: 5px;
    text-align: center;
}

.bodyTable table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 90px; /* Ajusta este valor según el espaciado que desees entre filas */
}





.observation{
    text-align: left;
    width: 100%;           /* Ancho fijo para truncar el texto */
    white-space: nowrap;    /* Evita que el texto se divida en varias líneas */
    overflow: hidden;       /* Oculta el desbordamiento del texto */
    text-overflow: ellipsis; /* Añade puntos suspensivos al final */
    overflow: hidden;
    text-overflow: ellipsis;
}


.acomodo{
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-bottom: 1rem;
    align-items: center;

}

.buscar input{
    padding: 10px;
    border: #CCCCCC 2px solid;
    border-radius: 5px;
    outline: none;
    width: 30rem;
    box-shadow: 10px 10px 16px 0px rgba(0,0,0,0.25);
}

.buscar button{
    border: none;
    border-radius: 5px;
    background-color: var(--HeaderTabla);
    padding: 10px;
    margin-left: 1rem;
    cursor: pointer;
}

.buscar button img{
    width: 15px;
}

.filtroBuscar button{
    background: #3BAF29;
}

.divSelect{
    margin-top: 14px;
}

.select{
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: #CCCCCC 2px solid;
    height: 2.5rem;
    border-radius: 6px;
    width: 12rem;
    background: #FFF;
    position: relative;
    box-shadow: 10px 10px 16px 0px rgba(0,0,0,0.25);

}

.contenidoSelect{
    margin-left:1rem;
}

.descripcionSelect{
    font-size: small;
}

.btnSelect{
    background-color: var(--HeaderTabla);
    height: 2.5rem;
    border-radius: 4px;
    width: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: #CCCCCC 2px solid;
    border-right: none;
}

.btnSelect img{
    width: 1rem;
}

.backOpciones{
}

.ListadoOpciones{
    background-color: #DBDDED;
    border-radius: 5px;
    box-shadow: 10px 10px 16px 0px rgba(0,0,0,0.25);
    line-height: 1rem;
    z-index: 1;
    position: absolute;
    right: 0;
}

.bodyTable .col:last-of-type:last-child .MoverArriba{
    top: -3rem;
}



.MoverArriba{
    background-color: #DBDDED;
    border-radius: 5px;
    box-shadow: 10px 10px 16px 0px rgba(0,0,0,0.25);
    line-height: 1rem;
    z-index: 1;
    position: absolute;
    right: 0;
}




.estatusOpciones{
    top: 3rem;
}

.SelectOpciones p{
    margin:0;
}

.SelectOpciones{
    padding: 10px;
}

.SelectOpciones:hover{
    background-color: var(--HeaderTabla);
    color: #FFF;
    cursor: pointer;
    border-radius: 4px;
    padding: 10px;

}

.col{
    border-bottom: #666770 2px solid;
    display: flex;
    align-items: center;
    width: 100%;
}



.col:last-child{
    border: none;
}

.seleccionarOpciones{
    position: relative;
    margin: 0 auto;
}

.seleccionarOpciones .fondoOpciones:hover{
    background: #D9D9D9;
    border-radius: 5rem;
    cursor: pointer;
}

.seleccionarOpcionesRegistro .fondoOpciones{
    
    background: #D9D9D9;
    border-radius: 5rem;
    cursor: pointer;
}

.criculoArgegar{
    background-color: #151B4E;
    padding: 1rem;
    border: none;
    border-radius: 5rem;
}

.agregar img{
    cursor: pointer;
}



.opciones{
    border-radius: 10px;
    cursor: pointer;
    margin: 0 auto;
}

.fondoOpciones{
    width: 1.5rem;
    padding: 5px;
    border-radius: 20px;
}

.fondoOpciones:hover{
    background-color: #D9D9D9;
    
}

.opciones p{
    margin-top: -1%;
    margin: 0 auto;
}

.opciones img{
    width: 1.5rem;
}

.iconoSeguimiento{
    background-color: #FFF;
    display: flex;
    justify-content: center;
    border-radius: 5px;
    border: var(--HeaderTabla) 2px solid;
}

.opcionSeguimiento{
    display: flex;
    align-items: center;
    justify-content: space-between;

}

.opcionSeguimiento:hover{
    background: var(--HeaderTabla);
    color: #FFF;
    border-radius: 5px;

}

.cambioRegistros{

    display: flex;
    justify-content: space-between;
    width: 70%;
    margin-top: 1rem;
    align-items: center;
    padding-bottom:8rem;
    background-color: #DDBD14;
}

.nullData{
    text-align: center;
    padding: 10px;
}

.iconoEditar{
    background-color: #DDBD14;
    display: flex;
    justify-content: center;
    border-radius: 5px;
    border: var(--HeaderTabla) 2px solid;
    margin-right: 1rem;
}

.col p{
    margin: 0;
}

@media only screen and (min-width: 300px){

    .MFechaRegistro{
        display: none;
    }

    .DFechaRegistro{
        display: none;
    }

    .MDescripcion{
        display: none;
    }

    .DDescripcion{
        display: none;
    }
    
    .MEstatusActividad{
        display: none;
    }

    .DEstatusActividad{
        display: none;
    }

    .MId{
        flex: 1;
    }

    .DId{
        flex: 1;
    }



    .MFechaSeguimiento{
        flex: 2;
    }

    .DFechaSeguimiento{
        flex: 2;
    }

    .headerTable :nth-child(7){
        flex: 1;
    } 

    .col :nth-child(7){
        flex: 1;
    }

    .scroll{
        margin: 1rem;
    }

}

@media only screen and (min-width: 600px){

    .MFechaSeguimiento{
        display: block;
    }

    .DFechaSeguimiento{
        display: block;
    }

}

@media only screen and (min-width: 768px){

    .MFechaRegistro{
        display: block;
        flex: 1;
    }

    .DFechaRegistro{
        display: block;
    }

    .MEstatusActividad{
        display: block;
    }

    .DEstatusActividad{
        display: block;
    }

}

@media only screen and (min-width: 992px){

}

@media only screen and (min-width: 1200px){

    .MDescripcion{
        display: block;
    }

    .DDescripcion{
        display: block;
    }

    
    .headerTable :nth-child(1){
        flex: 1;
    }

    .col :nth-child(1){
        flex: 1;
    }

    .headerTable :nth-child(2){
        flex: 2;
    }

    .col :nth-child(2){
        flex: 2;
    }

    .headerTable :nth-child(3){
        flex: 2;
    }

    .col :nth-child(3){
        flex: 2;
    }

    .headerTable :nth-child(4){
        flex: 2;
    }

    .col :nth-child(4){
        flex: 2;
    }

    .headerTable :nth-child(5){
        flex: 9;
    }

    .col :nth-child(5){
        flex: 9;
    }

    .headerTable :nth-child(6){
        flex: 2;
    } 

    .col :nth-child(6){
        flex: 2;
    }

}

`

export { StyledDetallesActividades }