import styled from 'styled-components';
 const StyledInputPrueba = styled.div`

.InputBtn{
    display: grid;
    grid-auto-columns: 1fr;
    grid-template:
        "Texto ." 0.6fr
        "Input Boton" 1.4fr / 1.8fr 0.2fr;
    gap: 0px;
}

.inputSearch{
    grid-area: Input;
    outline: none;
    border: 1px solid rgb(102 103 112 / 45%);
    border-radius: 8px 0px 0px 8px;
    color: rgb(51, 51, 51);
    font-size: 16px;
    padding: 5px;
    width: 100%;
    box-sizing: border-box;
}

.input{
    grid-area: Input;
    border: 2px solid rgb(102, 103, 112);
    border-radius: 4px;
    color: rgb(51, 51, 51);
    font-size: 16px;
    padding: 4px;
    width: 10%;
    box-sizing: border-box;
    border-radius: 7px;
    outline: none;
    
}

.InputBtn .btn{
    grid-area: Boton;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0px 8px 8px 0px;
    border: 1px solid rgb(102 103 112 / 45%);
    border-image: initial;
    border-left: none;
}

.btn img{
    width: 20px;
}

.InputLabel{
    margin: 0px;
    grid-area: Texto;
}

.FaltaCampo{
    color: var(--Rojo);
    font-weight: var(--Negritas);
}

.inputCamposVacios{
    grid-area: Input;
    border: 2px solid var(--Rojo);
    border-radius: 4px;
    color: rgb(51, 51, 51);
    font-size: 16px;
    padding: 4px;
    width: 100%;
    box-sizing: border-box;
    border-radius: 7px;
    outline: none;
}

.Input{
    display: flex;
    flex-direction: column;
}

.ContenedorInput{
    display: flex;
    flex-direction: column;
       // background-color:#32C1ED;
    gap: 5px;
}

.InputLabel{
    color: var(--Rojo);
}

.ContenedorCaracteres{
    margin: 0;
}
`

export {StyledInputPrueba}