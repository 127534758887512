import styled from 'styled-components';
export const StyledProyectos = styled.div`

.scroll{
  height: 82vh;
}

.bodyRegistroActividades{
    display: flex;
    flex-direction: column;
    align-items: center;
        border-bottom: 7px solid #eff0f2;
        /* position: relative; */
        font-size: 12px;
        margin: auto;
        color: #333333;
        font-weight: 500;
        border-radius: 5px;

}

.headerTable{
    background-color: #444971;
    color: #fff;
    font-weight: bold;
    font-size: 14px;
    border-radius: 5px;
    overflow: hidden;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

}

.table{
    width: 100%;
    border-collapse: collapse;
    border-color: none;

}

.headerTable h3{
    margin-right: 15px;
}

.bodyTable{
    max-height: 45vh;
    overflow-y: auto;
    background: #fff;
    margin: auto;
        color: #333333;
        font-weight: 500;
        border-radius: 5px;
    border-bottom: 7px solid #eff0f2;

}

.bodyTable table{
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 90px; /* Ajusta este valor según el espaciado que desees entre filas */
}



.acomodo{
    background-color: #ffff; //este es el mero color bueno 
    padding: 10px;
    border-radius: 5px;
    width: auto;
    margin-top: 8px;
    margin-bottom: 40px; //hay que cambiar este valor , es provicional en lo que queda el boton de agregar actividades 
    box-shadow: -1px 1px 5px gray;
}


.divSelect{
    margin-top: 14px;
}

.select{
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: #CCCCCC 2px solid;
    height: 2.5rem;
    border-radius: 6px;
    width: 12rem;
    background: #FFF;
    position: relative;

}

.contenidoSelect{
    margin-left:1rem;
}

.descripcionSelect{
    font-size: small;
}

.btnSelect{
    background-color: #9597A7;
    height: 2.5rem;
    border-radius: 4px;
    width: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: #CCCCCC 2px solid;
    border-right: none;
}

.btnSelect img{
    width: 1rem;
}

.ListadoOpciones{
    position: absolute;
    background-color: #DBDDED;
    padding: 5px;
    width: 11.5rem;
    border-radius: 5px;
    font-size: small;
    box-shadow: 10px 10px 16px 0px rgba(0,0,0,0.25);
    line-height: 1rem;
    z-index: 1;
}

.estatusOpciones{
    top: 2.5rem;
}

.SelectOpciones p{
    margin:0;
}

.SelectOpciones{
    padding: 10px;
}

.SelectOpciones:hover{
    background-color: #9597A7;
    color: #FFF;
    cursor: pointer;
    border-radius: 4px;
    padding: 10px;

}

.bodyTable .col{
    position: relative;
}

.col{
    border-bottom: 7px solid #eff0f2;

    display: flex;
    align-items: center;
    text-align: center;
}

.col:last-child{
    border: none;
}

.seleccionarOpciones img{
    width: 2rem;
    padding: 5px;
}

.seleccionarOpciones img:hover{
    background: #D9D9D9;
    border-radius: 5rem;
    cursor: pointer;
    width: 2rem;
}

.seleccionarOpcionesRegistro{
    position: relative;
    background: #D9D9D9;
    border-radius: 5rem;
    cursor: pointer;
    width: 3rem;
}

.agregar img{
    cursor: pointer;
}

.opciones{
    position: absolute;
    background-color: #DBDDED;
    border-radius: 10px;
    color: #9597A7;
    width: 9rem;
    z-index: 1;
}

.opciones p{
    margin-top: -1%;
    margin: 0 auto;
}

.opciones img{
    width: 100%;
}


.iconoSeguimiento{
    background-color: #FFF;
    display: flex;
    justify-content: center;
    border-radius: 5px;
    border: #9597A7 2px solid;
}

.iconoEditar{
    background-color: #DDBD14;
    display: flex;
    justify-content: center;
    border-radius: 5px;
    border: #9597A7 2px solid;
}

.iconoEliminar{
    background-color: #D60000;
    display: flex;
    justify-content: center;
    border-radius: 5px;
    border: #9597A7 2px solid;
}

.opcionSeguimiento{
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    width: 9rem;

}

.opcionSeguimiento:hover{
    background: #9597A7;
    color: #FFF;
    border-radius: 5px;

}

.cambioRegistros{
    display: flex;
            gap: 1rem;
        flex-wrap: wrap;
        padding: 100px 0px 0px 0px;
       bottom: 0;
       justify-content: space-between;
}

.nullData{
    padding: 10px;
    text-align: center;
}

.ocultarAccones{
    display: none;
}

.FiltroAgregar{
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
}

.FiltrosTabla{
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-wrap: wrap;
    gap: 1rem;
}

.SeccionFiltro{
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
}

.col p{
    margin: 0;
}
    

@media only screen and (min-width: 300px) {

    .MFechaRegistro{
        display: none;
    }

    .DFechaRegistro{
        display: none;
    }

    .MFechaInicio{
        display: none;
    }
    
    .DFechaInicio{
        display: none;
    }

    .MFechaFin{
        display: none;
    }

    .DFechaFin{
        display: none;
    }

    .MEstatus{
        display: none;
    }

    .DEstatus{
        display: none;
    }

    .bodyTable{
        height: 27vh;
    }

    .MID{
        flex: 1;
    }

    .DID{
        flex: 1;
    }

    .MProyecto{
        flex: 4;
    }

    .DProyecto{
        flex: 4;
        text-align: start;
    }

    .MAccion{
        flex: 1;
    }

    .seleccionarOpciones{
        flex: 1;
        position: relative;
    }

    .cambioRegistros{
        width: 100%;
        flex-wrap: wrap-reverse;
        gap: 1rem;
        justify-content: center;
       // background-color: aqua;
      
    }

    .ContenedorAgregar{
        float: right;
        justify-content: flex-end;
        flex-grow: 11;
        padding: 5px;
        margin-top: -70px;
        margin-left: 95%;
    }

    .ListadoOpciones{
        position: absolute;
        right: 0;
        top: 45px;
    }

    .bodyTable .col:last-of-type:last-child .ListadoOpciones{
        top: -50px;
    }

    .bodyTable .col:nth-last-child(2):not(:last-child) .ListadoOpciones{
        top: -50px;
    }

    .ContenedorFiltroEstatus{
        width: 100%;
    }

    .ContenderFiltroBuscar{
        width: 100%;
    }


}
/* / Small devices (portrait tablets and large phones, 600px and up) / */
@media only screen and (min-width: 600px) {

    
    .MEstatus{
        display: block;
    }

    .DEstatus{
        display: block;
    }

    .Registro{
        display: flex;
       // width: 40px;
      
        
    }

    .MEstatus{
        flex: 1;
    }

    .DEstatus{
        flex: 1;
    }

    .SeccionFiltro{
        flex-wrap: nowrap;
    }

    

}
/* / Medium devices (landscape tablets, 768px and up) / */
@media only screen and (min-width: 768px) {

    .MFechaInicio{
        display: block;
    }
    
    .DFechaInicio{
        display: block;
    }

    .MProyecto{
        flex: 5;
    }

    .DProyecto{
        flex: 5;
    }

    .MFechaInicio{
        flex: 3;
    }

    .DFechaInicio{
        flex: 3;
    }

    .MEstatus{
        flex: 2;
    }

    .DEstatus{
        flex: 2;
    }

    .cambioRegistros{
        justify-content: space-between;
    }

    .ContenedorAgregar{
        width: 0;
    }

}
/* / Large devices (laptops/desktops, 992px and up) / */
@media only screen and (min-width: 992px) {

    .MFechaRegistro{
        display: block;
    }

    .DFechaRegistro{
        display: block;
    }

    .MFechaFin{
        display: block;
    }

    .DFechaFin{
        display: block;
    }
    
    .MEstatus{
        display: block;
    }

    .DEstatus{
        display: block;
    }

    .MFechaFin{
        flex: 3;
    }

    .DFechaFin{
        flex: 3;
    }

    .MFechaRegistro{
        flex: 4;
    }

    .DFechaRegistro{
        flex: 4;
    }

}
/* / Extra large devices (large laptops and desktops, 1200px and up) / */
@media only screen and (min-width: 1200px) {

    .MFechaRegistro{
        display: block;
    }

    .DFechaRegistro{
        display: block;
    }

    .MFechaInicio{
        display: block;
    }
    
    .DFechaInicio{
        display: block;
    }

    .MFechaFin{
        display: block;
    }

    .DFechaFin{
        display: block;
    }

    .MEstatus{
        display: block;
    }

    .DEstatus{
        display: block;
    }

    .MProyecto{
        flex: 15;
    }

    .DProyecto{
        flex: 15;
    }

    .bodyTable{
        height: 50vh;
    }

    

}
`