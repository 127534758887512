import { useState } from 'react'
import axios from 'axios'
import { useAlert } from './useAlert';
import useDetailsDepartamentos from './useDetailsDepartamentos';
import { useShowDataTable } from './useShowDataTable';

export const useGuardarDepartamentos = () => {

    const [data, setData] = useState(null);

    const { alertSave, alertError } = useAlert();

    const [showModalAgregar, setShowModalAgregar] = useState(false);

    const {  showDetailsProyect,
        nombreDepartamento,
        setNombreDepartamento,
        descripcionDepartamento,
        setDescripcionDepartamento,
        IdUsuaruos,
        setIdUsuaruos } = useDetailsDepartamentos();

    const guardarDepartamento = async (url,data) => {

      try {
        let RespuestaServicio = await axios.post(url, data)
        setData(RespuestaServicio.data);
        alertSave("Departamento  agregado", RespuestaServicio.data);
        setNombreDepartamento("");
        setDescripcionDepartamento("");
        setIdUsuaruos("");
        setShowModalAgregar(false);
      } catch (error) {
        console.log(error.request);
        alertError(error.request.response);
      }
    }



    const guardarCambiosDepartamento = async (url,data) => {

      try {
        let RespuestaServicio = await axios.put(url, data);
        setData(RespuestaServicio.data);
        alertSave("Departamento actualizado");
        setNombreDepartamento("");
        setDescripcionDepartamento("");
        setIdUsuaruos("");
        setShowModalAgregar(false);
        
      } catch (error) {
        console.log(error.request.response);
        alertError(error.request.response);
      }
    }

    
  return {
    guardarDepartamento,
    guardarCambiosDepartamento,
    showModalAgregar,
    setShowModalAgregar
  };
}