
// useProcesos.js
import React, { useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import { useAuth } from '../Context/Context';
import { usePaginadorNuevo } from './usePaginadorNuevo';
const useProcesos = () => {
    const { user } = useAuth();
    const [nuevoProceso, setnuevoProceso] = useState();
    const [nombreProceso, setnombreProceso] = useState("");
const [iddepartamento, setIdDepartamento] = useState('');
    const [respPaginador, setrespPaginador] = useState();
    const [showModal, setShowModal] = useState(false);
    const [modoEdicion, setModoEdicion] = useState(false);
    const [procesoSeleccionado, setprocesoSeleccionado] = useState(null);
    const [page, setPage] = useState(1);
    const [totalPage, setTotalPage] = useState(1);
    const [Buscar, setBuscar] = useState();
    const [showTable, setShowTable] = useState(false);
    const [RegistrosPorPagina, setRegistrosPorPagina] = useState(10)
    const [RespuestaGetProceso, setRespuestaGetProceso] = useState();
    const [RespuestaDepartamentos, setRespuestaDepartamentos] = useState();
    const { Pg, MaxPg, RegsPg, Loading, InfoTab, GetPages, ProPrevPg, PrevPg, NextPg, ProNextPg, setRegsPg } = usePaginadorNuevo();
    
    const ObtenerProcesos222 = (busqueda) => {
        const busquedaParam = busqueda.trim() !== '' ? busqueda : '';
        GetPages(`${process.env.REACT_APP_API_SOPORTE}Procesos/Paginador?buscar=${busquedaParam}&pagina=${Pg}&registrosPorPagina=${RegsPg}`);
    };
    const ObtenerProcesos33 = () => {
        GetPages(`${process.env.REACT_APP_API_SOPORTE}Procesos/Paginador?buscar=&pagina=${Pg}&registrosPorPagina=${RegsPg}`);
    };

    
    const ObtenerProcesos2 = (busqueda) => {
        GetPages(`${process.env.REACT_APP_API_SOPORTE}Procesos/Paginador?buscar=${busqueda  }&pagina=${Pg === 0 ? 1 : Pg}&registrosPorPagina=${RegsPg === 0 ? 10 : RegsPg}`);
      };



    const ObtenerProcesos = () => {
        GetPages(`${process.env.REACT_APP_API_SOPORTE}Procesos/Paginador?buscar=${Buscar === undefined ? '' : Buscar}&pagina=${Pg === 0 ? 1 : Pg}&registrosPorPagina=${RegsPg === 0 ? 10 : RegsPg} `);
    }



    const ObtenerUser2 = async () => {
        setLoading(true);
    
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_SOPORTE}Departamentos/Obtener`);
            const respuestaServicio2 = response.data;
    
            if (Array.isArray(respuestaServicio2)) {
                const nuevaRespuesta2 = respuestaServicio2.map(departamento => ({
                    iddepartamento: departamento.id,
                    nombre: departamento.nombre
                }));
    
                setRespuestaDepartamentos(nuevaRespuesta2);
                console.log("Datos procesados de departamento:", nuevaRespuesta2);
            } else {
                console.error('No se encontró un array de departamentos en la respuesta:', respuestaServicio2);
                setRespuestaDepartamentos([]);
            }
        } catch (error) {
            console.error('Error al obtener departamentos:', error);
            setRespuestaDepartamentos([]);
        } finally {
            setLoading(false);
        }
    }



    const ObtenerProceso = async () => {
        setLoading(true);
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_SOPORTE}Procesos/Obtener`);
    
            const respuestaServicio = response.data;
    
            const nuevaRespuesta = respuestaServicio.map(resp => ({
                id: resp.id,
                descripcion: resp.nombre,
                iddepartamento: resp.iddepartamento
            }));
    
            setRespuestaGetProceso(nuevaRespuesta);
    
            console.log("Datos obtenidos:", nuevaRespuesta);
    
        } catch (error) {
            console.error('Error al obtener proceso:', error);
            
        } finally {
            setLoading(false);
        }
    };


    const ChangeRegs = (regis) => {
        GetPages(`${process.env.REACT_APP_API_SOPORTE}Procesos/Paginador?buscar=${Buscar === undefined ? '' : Buscar}&pagina=${1}&registrosPorPagina=${regis} `);
    }
    const handlePrimeraPg = () => {
        ProPrevPg(`${process.env.REACT_APP_API_SOPORTE}Procesos/Paginador?buscar=${Buscar === undefined ? '' : Buscar}&pagina=${1}&registrosPorPagina=${RegsPg === 0 ? 10 : RegsPg} `);
    }

    const handlePreviaPg = () => {
        PrevPg(`${process.env.REACT_APP_API_SOPORTE}Procesos/Paginador?buscar=${Buscar === undefined ? '' : Buscar}&pagina=${Pg - 1}&registrosPorPagina=${RegsPg === 0 ? 10 : RegsPg} `)
    }
    const handleNexPg = () => {
        NextPg(`${process.env.REACT_APP_API_SOPORTE}Procesos/Paginador?buscar=${Buscar === undefined ? '' : Buscar}&pagina=${Pg + 1}&registrosPorPagina=${RegsPg === 0 ? 10 : RegsPg} `)
    }
    const handleUltimaPg = () => {
        ProNextPg(`${process.env.REACT_APP_API_SOPORTE}Procesos/Paginador?buscar=${Buscar === undefined ? '' : Buscar}&pagina=${MaxPg}&registrosPorPagina=${RegsPg === 0 ? 10 : RegsPg} `)
    }
    const ChangeNombre = (data) => {
        setnuevoProceso(data);
        setIdDepartamento(data);

    };


    const handleNombreChange = (data) => {
        setnombreProceso(data);
      };
      

     const handleIdDepartamentoChange = (data) => {
        setIdDepartamento(data);
      };




      const handleInputChange = (fieldName, data) => {
        switch (fieldName) {
          case 'nombre':
            setnombreProceso(data);
            break;
          case 'iddepartamento':
            setIdDepartamento(data);
            break;
          default:
            break;
        }
      };



    const CleanNombre = () => {
        setnuevoProceso();
    };

    
    const limpiarModal = async () => {
        setnombreProceso("");
        setIdDepartamento("");
    }


    const closeModal = () => {
        setShowModal(false);
        limpiarModal();
        setprocesoSeleccionado(null);
        setModoEdicion(false);

    };


const openModal = (proceso) => {
  setShowModal(true);
  if (proceso) {
    
    setModoEdicion(true);
    setprocesoSeleccionado(proceso);
    handleNombreChange(proceso.nombre);
    handleIdDepartamentoChange(proceso.iddepartamento);
  } else {
    console.log('second')
    setModoEdicion(false);
    handleNombreChange("");
    handleIdDepartamentoChange('');
  }
};








const handlerGuardarProceso = () => {
    if (!nombreProceso || nombreProceso.trim() === '') {
        Swal.fire({
            title: 'Campo vacío',
            text: 'El nombre del proceso no puede estar vacío.',
            icon: 'error',
            confirmButtonText: 'Entendido'
        });
        return;
    }

    if (!iddepartamento) {
        Swal.fire({
            title: 'Departamento no seleccionado, el espacio no puede quedar vacio',
            text: 'Por favor, seleccione un departamento  para el proceso.',
            icon: 'error',
            confirmButtonText: 'Entendido'
        });
        return;
    }

    if (modoEdicion && procesoSeleccionado) {
        const datosModificados = {
            nombre: nombreProceso.trim(),
            iddepartamento: iddepartamento
        };
        ModificarProceso(   user.idusuario, procesoSeleccionado.id,  datosModificados)
            .then(() => {
                Swal.fire('Éxito', 'El proceso ha sido modificado correctamente.', 'success');
                closeModal();
                ObtenerProcesos();
            })
            .catch(error => {
                Swal.fire('Error', 'No se pudo modificar el proceso. Por favor, intenta de nuevo.', 'error');
                console.error('Error al modificar proceso:', error);
            });
    } else {
        const requestDepartamento = {
            nombre: nombreProceso.trim(),
            iddepartamento: iddepartamento
        };
        CrearProceso(requestDepartamento, user.idusuario)
            .then(() => {
                Swal.fire('Éxito', 'El nuevo proceso ha sido creado correctamente.', 'success');
                closeModal();
                ObtenerProcesos();
            })
            .catch(error => {
                Swal.fire('Error', 'No se pudo crear el nuevo proceso. Por favor, intenta de nuevo.', 'error');
                console.error('Error al crear proceso:', error);
            });
    }
};







      
    const EditarProceso = async () => {
        if (!nuevoProceso || nuevoProceso.trim() === '') {
            Swal.fire('Error', 'El nombre del proceso no puede estar vacío.', 'error');
            return;
        }

        setLoading(true);
        try {
            await axios.put(`${process.env.REACT_APP_API_SOPORTE}Procesos/Actualizar/${procesoSeleccionado.idUsuario}/${procesoSeleccionado.id}`, {
                nombre: nuevoProceso.trim()
            });
            Swal.fire('Éxito', 'El proceso ha sido modificado correctamente.', 'success');
            setModoEdicion(false);
            setprocesoSeleccionado(null);
            await PaginadorProcesos();
        } catch (error) {
            console.error('Error al modificar el proceso: ', error);
            Swal.fire('Error', 'No se pudo modificar el proceso. Por favor, intenta de nuevo.', 'error');
        } finally {
            setLoading(false);
        }
    };




    const CrearProceso = async (request, usuario) => {
        setLoading(true);
        try {
            await axios.post(
                `${process.env.REACT_APP_API_SOPORTE}Procesos/Agregar/${usuario}`,
                request,
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );
             ObtenerProcesos();
            closeModal();
        } catch (error) {
            console.log('Error al agregar nuevo proceso:', error);
        } finally {
            setLoading(false);
        }
    };

    const PaginadorProcesos = async () => {
        setLoading(true);
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API_SOPORTE}Procesos/Paginador?buscar=${''}&pagina=${Pg}&registrosPorPagina=${RegsPg === undefined ? '10' : RegsPg} `);

            setrespPaginador(response.data.resultado);
            setTotalPage(response.data.totalPage);
            // HanlderChangueResultados(response.data.TotalRegistros);
            return response.data.resultado;

        }
        catch (error) {
            console.error('Error al obtener paginador de proceso:', error);
        } finally {
            setLoading(false);
        }
    };

    const EliminarProceso = async (idUsuario , idproceso) => {
        setLoading(true);
        try {
            await axios.delete(`${process.env.REACT_APP_API_SOPORTE}Procesos/Eliminar/${idUsuario }/${idproceso}`, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            ObtenerProcesos();
        } catch (error) {
            console.error('Error al dar de baja el proceso: ', error);
        } finally {
            setLoading(false);
        }
    };

    

    const HabilitarProceso = async (idproceso , idUsuario) => {
        setLoading(true);
        try {
            await axios.put(`${process.env.REACT_APP_API_SOPORTE}Procesos/Habilitar/${idproceso }/${idUsuario}`, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            ObtenerProcesos();
        } catch (error) {
            console.error('Error al habiliatr el proceso: ', error);
        } finally {
            setLoading(false);
        }
    };

    

    const [mostrarProyectos, setMostrarProyectos] = useState();
    const [error, setError] = useState([]);
    
    const [showSelect, setShowSelect] = useState(false);
    const [Pagina, setPagina] = useState();
    const [TotalPaginas, setTotalPaginas] = useState();
    const [TotalRegistros, setTotalRegistros] = useState();
    const [FormularioProyectos, setFormularioProyectos] = useState();
    const [loading, setLoading] = useState(true);
  

  
    const showDatosPoryectos = async (url, filtros) => {
      // setLoading(true);
      let configpost = {
        method: 'get',
        url: url,
        headers: {
          'Content-Type': 'application/json'
        },
        data: filtros
      };
  
      await axios(configpost)
      .then(response => {
  
        setLoading(false);
        
        // console.log(response.data);
        let RespuestaServicio = response.data.resultado;
    
        const NuevaRespuesta = RespuestaServicio.map(resp => {
          const RehacerObjeto = { "id": resp.id, "descripcion":resp.nombre }
          return RehacerObjeto
        })
            setFormularioProyectos(NuevaRespuesta);
            setMostrarProyectos(response.data.resultado);
            setPagina(response.data.paginaActual);
            setTotalPaginas(response.data.totalPaginas);
            setTotalRegistros(response.data.registrosPorPagina);
      })
        .catch(error => {
          setError(error);
        });
    }
  




    const ModificarProceso = async (idUsuario ,idproceso , datosModificados) => {
        setLoading(true);
        try {
            await axios.put(
                `${process.env.REACT_APP_API_SOPORTE}Procesos/Actualizar/${idUsuario }/${idproceso}`,
                datosModificados,
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );
             ObtenerProcesos();
            closeModal();
            // alert(`proceso con ID ${idproceso } modificado.`);
        } catch (error) {
            alert('Error al modificar proceso:', error);
        } finally {
            setLoading(false);
        }
    };


    return {
        ChangeNombre,
        nuevoProceso,
        CrearProceso,
        CleanNombre,
        respPaginador,
        PaginadorProcesos,
        EliminarProceso,
        ModificarProceso,
        ObtenerProceso,
        handlerGuardarProceso,
        showModal,
        openModal,
        modoEdicion,
        closeModal,
        user,
        loading,
        page,
        totalPage,
        TotalRegistros,
        Buscar,
        ObtenerProcesos,
        handlePrimeraPg,
        handlePreviaPg,
        handleNexPg,
        handleUltimaPg,
        InfoTab,
        ChangeRegs,
        RegsPg,
        setRegsPg,
        Pg,
        MaxPg,
        RespuestaGetProceso, 
        HabilitarProceso,
        ObtenerProcesos2,
        ObtenerProcesos222,
        ObtenerProcesos33,
        mostrarProyectos,
        showDatosPoryectos,
        showSelect,
        setShowSelect,
        loading,
        TotalPaginas,
        TotalRegistros,
        Pagina,
        setPagina,
        FormularioProyectos,
        EditarProceso,
        nombreProceso,
        ObtenerUser2,
        RespuestaDepartamentos,
        handleIdDepartamentoChange,
        handleInputChange,
        handleNombreChange,
        showTable, setShowTable,
setLoading,
iddepartamento,
procesoSeleccionado

    };
};

export default useProcesos;
