import styled from 'styled-components';

export const StyledHeader = styled.div`
//Header
.posicionHeader{
    width: 100%;
    height: 100%;
}

.componentHeader{
    position: relative;
    background-color: var(--Fondo);
    display: flex;
    justify-content: center;
}

.componentHeader .cuenta{
    background-color: var(--AzulHeader);
    border-radius: 5px;
    padding: 0 1rem;
    width: 94%;
}

.cuenta{
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
}

.fotoUsuario{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    margin: 0 1%;
}

.ImgUsuario img{
    width: 40px;
}

.SubmenuHeader img{
    width: 55px;
}

.ImgNotificaciones img{
    width: 30px;
}

.fotoUsuario p {
    font-size: small;
    color: #FFF;
    margin-left: 10px;
}

.componentHeader .submenu{
    position: absolute;
    background-color: #F0F1F3;
    box-shadow: -1px 2px 9px -2px rgba(0,0,0,0.75);;
    border-radius: 15px;
    cursor: pointer;
    right: 5rem;
    top: 3rem;
}



.submenu ul{
        display: flex;
        flex-direction: row;
        align-items: center;
        color: rgb(51, 51, 51);
        font-weight: bold;
        padding: 5px;
        margin: 5px;
        list-style: none;
        gap: 5px;
}

.ImgCerrarSesion{
    background: #B4DBB0;
    border-radius: 20px;
    padding: 5px;
}

.submenu ul:hover{
    background: #B4DBB0;
    border-radius: 5rem;
}

.submenu ul img{
    width: 15px;
}

.rotate{
    transform: rotate(180deg);
}

.SeccionActual{
    color: #FFF;
    margin: 0 2%;
    text-transform: uppercase;
}

h3{
    margin: 0;
}

`