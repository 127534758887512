import styled from 'styled-components';
export const StyledActividades = styled.div`

.bodyRegistroActividades{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.headerTable{
    background-color: #444971;
    color: #fff;
    font-weight: bold;
    font-size: 14px;
    border-radius: 5px;
    overflow: hidden;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}


    .SelectOptions {
        &:hover {
            border-color: #888;
        }
        
        &:focus {
            outline: none;
            border-color: rgb(58, 147, 56);
            box-shadow: 0 0 0 2px rgba(58, 147, 56, 0.2);
        }
    }
.seleccionarOpciones img{
    width: 2rem;
    padding: 5px;
}
.table{
    width: 100%;
}

.headerTable h3{
    margin-right: 15px;
}

.bodyTable{
    max-height: 45vh;
    overflow-y: auto;
    //background: #fff;
    border-radius: 5px;
    justify-content: center;
    align-items: center;
    text-align: center;


    
}

.bodyTable .col{
    position: relative;
    display: flex;
    align-items: center;
}


.buscar input{
    padding: 10px;
    border: #CCCCCC 2px solid;
    border-radius: 5px;
    outline: none;
    width: 30rem;
}

.buscar button{
    border: none;
    border-radius: 5px;
    background-color: var(--HeaderTabla);
    padding: 10px;
    margin-left: 1rem;
    cursor: pointer;
}

/* .buscar button img{
    width: 15px;
} */

.ContenedorAgregar{
    float: right;
    justify-content: flex-end;
    flex-grow: 11;
    padding: 5px;
    /* margin-top: -55px; */
    
  }
 

.acomodo{
    background-color: #ffff; //este es el mero color bueno 
    padding: 10px;
    border-radius: 5px;
    width: auto;
    margin-top: 8px;
    margin-bottom: 40px; //hay que cambiar este valor , es provicional en lo que queda el boton de agregar actividades 
    box-shadow: -1px 1px 5px gray;
    //nuevos cambios para el responsivo 
    display: flex;
    flex-direction: row;
}




.acomodo2{
    background-color: #ffff; //este es el mero color bueno 
    padding: 10px;
    border-radius: 5px;
    width: auto;
    margin-top: 8px;
    margin-bottom: 40px; //hay que cambiar este valor , es provicional en lo que queda el boton de agregar actividades 
    box-shadow: -1px 1px 5px gray;
}





.filtroBuscar button{
    background: #3BAF29;
}

.col{
    border-bottom: 7px solid #eff0f2;
    background-color: #FFF;
}

.col:last-child{
    border: none;
}

.seleccionarOpciones{
    width: 2rem;
}

/* .agregar img{
    cursor: pointer;
} */

.opciones{
    position: absolute;
    background-color: #DBDDED;
    border-radius: 5px;
    color: var(--HeaderTabla);
    z-index: 1;
    right: 0;
    top: 2rem;
}

.DesactivarOpciones{
    display: none;
}

.bodyTable .col:last-of-type:last-child .MoverArriba{
    top: -7.5rem;
}

.bodyTable .col:nth-last-child(2):not(:last-child) .MoverArriba{
    top: -7.5rem;
}

.bodyTable .col:nth-last-child(3):not(:last-child) .MoverArriba{
    top: -7.5rem;
}

.bodyTable p{
    margin: 0;
    font-size: 14px;
}

.opciones p{
    margin-top: -1%;
    margin: 0 auto;
}

.opciones img{
    width: 100%
}



.ContendorOpciones{
    width: 1.5rem;
    padding: 5px;
    border-radius: 20px;
}

.ContendorOpciones:hover{
    background: #D9D9D9;
    border-radius: 5rem;
    cursor: pointer;
}

.seleccionarOpcionesRegistro{
    position: relative;
    background: #D9D9D9;
    border-radius: 5rem;
    cursor: pointer;
}


.iconoSeguimiento{
    background-color: #FFF;
    border-radius: 5px;
    border: var(--HeaderTabla) 2px solid;
    padding: 5px;
    width: 1.5rem;
}

.iconoEditar{
    background-color: #DDBD14;
    justify-content: center;
    border-radius: 5px;
    border: var(--HeaderTabla) 2px solid;
    padding: 5px;
    width: 1.5rem;
}

.iconoEliminar{
    background-color: #D60000;
    justify-content: center;
    border-radius: 5px;
    border: var(--HeaderTabla) 2px solid;
    padding: 5px;
    width: 1.5rem;
}

.opcionSeguimiento{
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    gap: 1rem;

}

.opcionSeguimiento:hover{
    background: var(--HeaderTabla);
    color: var(--Blanco);
    border-radius: 5px;

}

.cambioRegistros{
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 4rem;
    align-items: center;
}

.nullData{
    padding: 10px;
    text-align: center;
}

.ContenedorFiltros{
    display: flex;
    gap: 1rem;
}



.ContenedorFiltros2{
    display: flex;
    gap: 1rem;
}
.actividadesCanceladas{
    background-color: lightgray;
    display: flex;
    align-items: center;
    pointer-events: none;
}


@media only screen and (min-width: 300px){

    .MProyecto{
        display: none;
    }

    .DProyecto{
        display: none;
    }

    .MFechaActividad{
        display: none;
    }

    .DFechaActividad{
        display: none;
    }

    .MUsuario{
        display: none;
    }

    .DUsuario{
        display: none;
    }

    .MRegistro{
        display: none;
    }

    .DRegistro{
        display: none;
    }

    .MEstatus{
        display: none;
    }

    .DEstatus{
        display: none;
    }

    .headerTable{
        display: flex;
    }

    .acomodo{
        flex-wrap: wrap;
        gap: 1rem;
    }

    .ContenedorAgregar{
    float: right;
    justify-content: flex-end;
    flex-grow: 11;
    padding: 5px;
    /* margin-top: -55px; */
    flex: 0;
    
  }

  .ContenedorFiltros{
    gap: 1rem;
    /* flex: 1; */
    flex: 10;
}

.Ocultar{
    display: none;
}
    .acomodo2{
        flex-wrap: wrap;
        gap: 1rem;
        /* display: flex;
        flex-direction: row; */
    }

.PrimerosFiltros{
    display: flex;
    flex-direction: row;

  
}

.filtroEstatus{
    max-width: 40%;
            /* arriba | derecha | abajo | izquierda */
padding: 0 10px 0 0;

}

    .ContenedorAgregar2{
    float: right;
    justify-content: flex-end;
    flex-grow: 11;
    padding: 5px;
    margin-top: -278px;
    
  }


  .buscando{
    max-width:70%;
  }
  .ContenedorFiltros2{
    display: flex;
    gap: 1rem;
    /* flex: auto; */
}

    .headerTable .MId{
        flex: 1;
    }

    .col .DId{
        flex: 1;
    }

    .headerTable .MAcciones{
        flex: 1;
    } 

    .ContenedorOpciones{
        flex: 1;
    }

    .headerTable .MActividad{
        flex: 3;
    }

    .col .DActividad{
        flex:  3;
        text-align: center;
    }

    .cambioRegistros{
        /*
        display: flex;
        justify-content: center;
        gap: 1rem;
        flex-wrap: wrap;
       bottom: 0;
        
        */
       display: flex;
       gap: 1rem;
    width: 100%;
    flex-wrap: wrap;
    justify-content: center;
      //position: absolute;
      //margin-bottom: 40px;
      //bottom: 0;
      padding: 20px 0px 0px 0px;

        //background-color: aqua;
    }

    .bodyTable{
        height: 32vh;
    }
    .paginador{
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-end;
    margin-top: 10px;
    //background-color: #D60000;
}
    


}

@media only screen and (min-width: 450px){

    .MEstatus{
        display: flex;
    }

    .DEstatus{
        display: flex;
    }

    .headerTable .MEstatus{
        flex: 2;
    }

    .col .DEstatus{
        flex: 2;
    }

    .headerTable .MActividad{
        flex: 10;
    }

    .col .DActividad{
        flex:  10;
    }

    .cambioRegistros{
        display: flex;
        justify-content: space-between;
    }
}



@media only screen and (min-width: 600px){

    .MEstatus{
        display: flex;
    }

    .DEstatus{
        display: flex;
    }

    .headerTable .MEstatus{
        flex: 1;
    }

    .col .DEstatus{
        flex: 1;
    }

    .headerTable .MActividad{
        flex: 6;
    }

    .col .DActividad{
        flex:  6;
    }

    .filtroEstatus{
        min-width: 150px;
                /* arriba | derecha | abajo | izquierda */
padding: 0 20px 0 0;
    }
    .ContenedorAgregar2{
    float: right;
    justify-content: flex-end;
    flex-grow: 11;
    padding: 5px;
    margin-top: -55px;

  }


}

@media only screen and (min-width: 700px){

    .cambioRegistros{
        display: flex;
        justify-content: space-between;
    }

    .MProyecto{
        display: block;
    }

    .DProyecto{
        display: block;
    }

    .headerTable .MProyecto{
        flex: 6;
    }

    .col .DProyecto{
        flex:  6;
    }

}

@media only screen and (min-width: 900px){

    .MFechaActividad{
        display: block;
    }
    
    .DFechaActividad{
        display: block;
        justify-content: center;
    }

    .headerTable .MFechaActividad{
        flex: 4;
    } 

    .col .DFechaActividad{
        flex: 4;
    }

    .headerTable .MEstatus{
        flex: 2;
    }

    .col .DEstatus{
        flex: 2;
    }

    .bodyTable{
        height: 47vh;
    }



}

@media only screen and (min-width: 1200px) {

    .MProyecto{
        display: block;
    }
    
    .DProyecto{
        display: block;
    }

    .MFechaActividad{
        display: block;
    }

    .DFechaActividad{
        display: block;
    }

    .MUsuario{
        display: block;
    }

    .DUsuario{
        display: block;
    }

    .MRegistro{
        display: block;
    }

    .DRegistro{
        display: block;
    }

    .headerTable .MProyecto{
        flex: 5;
    }

    .col .DProyecto{
        flex: 5;
    }

    .headerTable .MRegistro{
        flex: 4;
    }

    .col .DRegistro{
        flex: 4;
    }

    .headerTable .MActividad{
        flex: 12;
    }

    .col .DActividad{
        flex:  12;
    }

    .headerTable .MUsuario{
        flex: 3;
    }

    .col .DUsuario{
        flex: 3;
    }

    .headerTable .MFechaActividad{
        flex: 4;
    } 

    .col .DFechaActividad{
        flex: 4;
    }

    .headerTable .MEstatus{
        flex: 2;
    } 

    .col .DEstatus{
        flex: 2;
    }

    .headerTable .MAcciones{
        flex: 1;
    } 

    .ContenedorOpciones{
        flex: 1;
    }



}

@media only screen and (min-width: 1920px) {

    
    

    
}
`